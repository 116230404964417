<div class="card shadow-sm {{ customClass }}">

  <div class="card-header {{ headerStyle }}" *ngIf="title">
    <div class="row">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <div class="min-space">
          <ng-content select="[header-left]"></ng-content>
        </div>
        <h4 class="d-inline">{{ (title | translate) | titlecase }}</h4>
        <div class="min-space text-right">
          <ng-content select="[header-right]"></ng-content>
        </div>
        
      </div>
      <ng-content select="[header-subtitle]"></ng-content>
    </div>
  </div>

  <div class="card-body">
    <ng-content></ng-content>
  </div>

</div>