<s1-header [title]="'menuAzioni.title'">
  <ng-container right>
    <s1-button [type]="s1ButtonType.New" (onClick)="new()" *ngIf="!ricercaAzioniPreferiti"></s1-button>
  </ng-container>
</s1-header>

<s1-card>
  <form [formGroup]="searchForm">
    <div class="row">
      <s1-input-text class="col-4" [control]="searchForm.controls.codice" [label]="'menuAzioni.fields.codice'"></s1-input-text>
      <s1-input-text class="col-4" [control]="searchForm.controls.descrizione" [label]="'menuAzioni.fields.descrizione'"></s1-input-text>
    </div>
    <div class="row justify-content-center">
      <s1-button class="col-auto" [type]="s1ButtonType.Search" (onClick)="search()"></s1-button>
    </div>

  </form>
</s1-card>

<s1-card class="mt-2">
  <s1-table #table [onlinePagination]="true" (pageChanged)="search()">
    <thead header>
      <tr>
        <th> # </th>               
        <th>{{ 'menuAzioni.table.columns.codice' | translate }}</th>
        <th>{{ 'menuAzioni.table.columns.descrizione' | translate }}</th>
        <th>{{ 'menuAzioni.table.columns.icon' | translate }}</th>
        <th>{{ 'menuAzioni.table.columns.url' | translate }}</th>                 
        <th></th>                                     
      </tr>
    </thead>
    <tbody body>
      <tr *ngFor="let azione of table.config.rows; let i = index">
        <td>{{ i | s1TableGeneralIndex: table }}</td>
        <td> {{ azione.codice }} </td>
        <td> {{ azione.descrizione }} </td>
        <td> <i class="fa fa-2x {{ azione.icona }}"></i> </td>
        <td> {{ azione.url }} </td>
        <td class="text-right">
          <s1-button [type]="s1ButtonType.Edit" (onClick)="edit(azione)" class="mr-2"  *ngIf="!ricercaAzioniPreferiti"></s1-button>
          <s1-button [type]="s1ButtonType.Delete" (onClick)="askDelete(azione)"  *ngIf="!ricercaAzioniPreferiti" ></s1-button>
          <s1-button [type]="s1ButtonType.Edit"  [label]="'menuAzioni.buttons.preferito'" (onClick)="askImpostaPreferito(azione)"  *ngIf="ricercaAzioniPreferiti" ></s1-button>
        </td>
      </tr>
    </tbody>
  </s1-table>
</s1-card>

<azione-menu-modal #azioneModal (elementSaved)="search()"></azione-menu-modal>