import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { S1Modal, S1ModalSizes } from '@app/s1';

@Component({
  selector: 'azioni-preferiti-modal',
  templateUrl: './azioni-preferiti-modal.component.html',
  styleUrls: ['./azioni-preferiti-modal.component.scss']
})
export class AzioniPreferitiModal implements OnInit {

  @Output() needsUpdate = new EventEmitter<boolean>();

  @ViewChild('modal') modal: S1Modal;

  loaded: boolean = false;

  constructor() { }

  ngOnInit(): void { }

  open() {

    this.modal.open(S1ModalSizes.XL);

  }

  close() {
    this.needsUpdate.emit(true);
    this.modal.close();
  }

  refresh() {
    this.needsUpdate.emit(true);
    this.loaded = false;
  }

}
