<div class="w-100 text-left" *ngIf="!inline && label"><label>{{ label | translate }}</label></div> 
<div class="input-group mb-2" >
  <div class="input-group-prepend" *ngIf="inline && label">
      <span class="input-group-text">{{ label | translate }}</span>
  </div>
  <ng-content></ng-content>
  <div class="input-group-append" *ngIf="append">
    <span class="input-group-text">{{ append }}</span>
  </div>
</div>
