<div class="text-center">


    <li class="nav-item dropdown">
        <a href="#" class="dropdown-toggle" data-toggle="dropdown"  aria-expanded="false" style=" text-decoration: none;"><img id="imgNavSel" src="assets/img/lang/{{translator.getLanguageInUse()}}.png" class="flagIcon"><!--<span id="lanNav{{lang.code}}"></span> <span class="caret"></span>--></a>
        <ul class="dropdown-menu" role="menu" style="min-width:auto !important;list-style-type: none;">
            <li *ngFor="let lang of getLangs()"><a href="javascript:" id="nav{{lang.code}}"  style=" text-decoration: none;" class="language" (click)="setLang(lang.code)"> <img id="imgNav{{lang.code}}" src="assets/img/lang/{{lang.code}}.png" class="flagIcon">  <!--<span id="lanNav{{lang.code}}"></span>--></a></li>
        </ul>
    </li>


</div>