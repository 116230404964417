import { Injectable } from '@angular/core';
import { IFasciaOraria } from '../models/fascia-oraria';
import { IS1SearchParams, S1ApiCrudService, S1HttpClientService } from '@app/s1';

export interface IRicercaFasciaOrariaParams extends IS1SearchParams { 
  nome?: string
}

@Injectable({
  providedIn: 'root'
})
export class FasciaOrariaService extends S1ApiCrudService<IFasciaOraria, IRicercaFasciaOrariaParams> {

  constructor(s1HttpClient: S1HttpClientService) { 
    super(s1HttpClient, "/fasciaOraria");
  }
  
}
