import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormControl, NgModel } from '@angular/forms';

@Component({
  selector: 'input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit {

  @Input() numericValue: number;
  @Output() numericValueChange = new EventEmitter<number>();
  @Input() min: number = 0;
  @Input() max: number;
  @Input() step: number = 1;
  @Input() readonly: boolean = false;

  @ViewChild('numericValueModel') numericValueModel: NgModel;

  numericControl: UntypedFormControl;

  constructor() { }

  ngOnInit(): void { }

  valueUpdated(newVal: number) {

    if (newVal % this.step != 0) {
      this.numericValueModel.control.setErrors({'stepWrong': true})
    } if (this.min != null && newVal < this.min) {
      this.numericValueModel.control.setErrors({'minValueWrong': true})
    } if (this.max != null && newVal > this.max) {
      this.numericValueModel.control.setErrors({'maxValueWrong': true})
    } else {
      this.numericValueChange.emit(newVal)
    }
    
  }

}
