import { Injectable } from '@angular/core';

//const themeCustom = require('../../shared/styles/themes/theme-custom.scss');

@Injectable()
export class ThemesService {

    styleTag: any;

    constructor() {
        this.createStyle();
        //this.injectStylesheet(themeCustom);
    }

    private createStyle() {
        const head = document.head || document.getElementsByTagName('head')[0];
        this.styleTag = document.createElement('style');
        this.styleTag.type = 'text/css';
        this.styleTag.id = 'appthemes';
        head.appendChild(this.styleTag);
    }

    setTheme(name) {
        switch (name) {
            default:
                //this.injectStylesheet(themeCustom);
                break;
        }
    }

    // since v9, content is available in 'default'
    injectStylesheet(css) {
        this.styleTag.innerHTML = css.default;
    }

}
