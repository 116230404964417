import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { S1ButtonType, S1Modal, S1ModalSizes, S1UIService } from '@app/s1';
import { ITipoAllegatoEntita } from '@app/shared/models/allegato';
import { AllegatiService } from '@app/shared/services/allegati.service';

@Component({
  selector: 'all-allegato-modal',
  templateUrl: './allegato-modal.component.html',
  styleUrls: ['./allegato-modal.component.scss']
})
export class AllegatoModalComponent implements OnInit {

  @ViewChild('modal') modal: S1Modal;

  @Output() uploaded = new EventEmitter<boolean>();

  formData: FormData = new FormData();

  allegatoForm: UntypedFormGroup;

  tipo: ITipoAllegatoEntita;
  idOggetto: string;

  s1ButtonType = S1ButtonType;

  progress: number = 0;

  constructor(private formBuilder: UntypedFormBuilder, private allegatiService: AllegatiService, private ui: S1UIService) { 

    this.allegatoForm = this.formBuilder.group({
      'tipoAllegato': ['', Validators.required],
      'nota': ['', null]
    })

  }

  ngOnInit(): void { }

  open(tipo: ITipoAllegatoEntita, idOggetto: string) {

    this.formData = null; // Necessario per resettate la upload section
    this.allegatoForm.reset();
    
    this.tipo = tipo;
    this.idOggetto = idOggetto;
    this.allegatoForm.controls.tipoAllegato.patchValue(tipo?.idTipoAllegato);
    this.modal.open(S1ModalSizes.LG);
    this.formData = new FormData();

  }

  reset() {
    this.formData = null; // Necessario per resettate la upload section
    this.allegatoForm.reset();
  }

  close() {
    this.reset();
    this.modal.close();
  }

  upload() {

    const params = {
      "id": this.idOggetto,
      "idTipoAllegato": this.allegatoForm.controls.tipoAllegato.value,
      "note": this.allegatoForm.controls.nota.value
    }

    this.formData.set("Key", JSON.stringify(params));

    this.allegatiService.upload(this.formData, this.tipo.entita).subscribe(uploaded => {

      this.progress = Number(uploaded);

      if (uploaded?.outcome?.success) {
        this.ui.showSuccessToast("allegati.nuovo.popup.uploaded");
        this.uploaded.emit(true);
        this.modal.close();
      }

    })
    
  }

}