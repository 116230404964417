<s1-modal #modal [title]="'menuAzioni.title'">
  <form [formGroup]="menuAzioniForm" >
    <div class="row">
      <!--<s1-input-text class="col-12" [control]="menuAzioniForm.controls.id" [label]="'menuAzioni.fields.id'" [readonly]="true"></s1-input-text>-->
      <s1-input-text class="col-6" [control]="menuAzioniForm.controls.codice" [label]="'menuAzioni.fields.codice'"></s1-input-text>
      <s1-input-text class="col-6" [control]="menuAzioniForm.controls.descrizione" [label]="'menuAzioni.fields.descrizione'"></s1-input-text>
      <s1-input-text class="col-6" [control]="menuAzioniForm.controls.url" [label]="'menuAzioni.fields.url'"></s1-input-text>
      <s1-input-label class="col-6 cursor-pointer" (click)="setIcon()" [label]="'menuAzioni.fields.icon'">
        <ng-container *ngIf="!this.menuAzioni.icona || this.menuAzioni.icona == '-'">
          {{ 'menuAzioni.fields.selectIcon' | translate }}
        </ng-container>
        <i *ngIf="this.menuAzioni.icona" class="fa fa-2x mt-1 ml-4 {{this.menuAzioni.icona}}"></i>
      </s1-input-label>
    </div>
    <div class="row justify-content-center">
      <s1-button [type]="s1ButtonType.Save" (onClick)="save()"></s1-button>
    </div>
  </form>
  <funzioni-azione-menu-section [azione]="this.menuAzioni"></funzioni-azione-menu-section>
</s1-modal>

<!-- Modal Icon Menu -->
<s1-icon-modal #iconModal (selected)="iconSelected($event)"></s1-icon-modal>