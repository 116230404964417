import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './pages/login/login.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { ForgotComponent } from './pages/forgot/forgot.component';

export const routes: Routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
            { path: 'utenti', loadChildren: () => import('./utenti/utenti.module').then(m => m.UtentiModule) },
            { path: 'menu', loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule) },
            { path: 'allegato', loadChildren: () => import('./allegato/allegato.module').then(m => m.AllegatoModule) },
            { path: 'negozio', loadChildren: () => import('./negozio/negozio.module').then(m => m.NegozioModule) },
            { path: 'operatore', loadChildren: () => import('./operatore/operatore.module').then(m => m.OperatoreModule) },
            { path: 'turno', loadChildren: () => import('./turno/turno.module').then(m => m.TurnoModule) },
            { path: 'rendiconto', loadChildren: () => import('./rendiconto/rendiconto.module').then(m => m.RendicontoModule) },
            { path: 'budget', loadChildren: () => import('./budget/budget.module').then(m => m.BudgetModule) },
            { path: 'fascia-oraria', loadChildren: () => import('./fascia-oraria/fascia-oraria.module').then(m => m.FasciaOrariaModule) },
            { path: 'categoria', loadChildren: () => import('./categoria/categoria.module').then(m => m.CategoriaModule) },
            { path: 'task', loadChildren: () => import('./task/task.module').then(m => m.TaskModule) },
            { path: 'campo-extra-rendiconto', loadChildren: () => import('./campo-extra-rendiconto/campo-extra-rendiconto.module').then(m => m.CampoExtraRendicontoModule) }
        ]
    },

    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent },
    { path: 'login/:id', component: LoginComponent }, 
    { path: 'recover/:token', component: RecoverComponent },
    { path: 'forgot', component: ForgotComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },

    // Not found
    { path: '**', redirectTo: 'home' }

];
