import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem, S1ButtonType } from '@app/s1';
import { IRicercaNegozioParams, NegozioService } from '@app/shared/services/negozio.service';

@Component({
  selector: 'iwr-negozi-select',
  templateUrl: './negozi-select.component.html',
  styleUrls: ['./negozi-select.component.scss']
})
export class NegoziSelectComponent {

  @Input() control: UntypedFormControl;
  @Input() label: string = "negozi.fields.negozio";
  @Input() readonly: boolean = false;
  @Input() multiple: boolean = false;

  @Output() preselected = new EventEmitter<boolean>();

  negozi: IS1InputSelectItem[];

  s1ButtonType = S1ButtonType;

  constructor(private negozioService: NegozioService) {

    const params: IRicercaNegozioParams = {
      page: 1,
      pageSize: 999
    }
  
    this.negozioService.ricerca(params).subscribe(negoziPaginati => {

      this.negozi = negoziPaginati?.elements?.map(negozio => { 
        return { code: negozio.id, label: negozio.nome?.trim(), group: negozio.tipo }  
      }) ?? [];
  
      if (this.negozi?.length == 1) {
        this.control?.patchValue(this.negozi[0].code);
        this.preselected.emit(true);
      }
  
    })

  }

  selectAll() {
    this.control?.patchValue(this.negozi.map(negozio => negozio.code));
  }

}
