import { Component, Input } from '@angular/core';
import { ICampoExtraRendicontoValorizzato } from '@app/shared/models/campo-extra-rendiconto';
import { IFasciaOraria } from '@app/shared/models/fascia-oraria';
import { INegozio } from '@app/shared/models/negozio';
import { IRendiconto } from '@app/shared/models/rendiconto';

@Component({
  selector: 'iwr-report-rendiconti-cell-campo-extra-total',
  templateUrl: './report-rendiconti-cell-campo-extra-total.component.html',
  styleUrls: ['./report-rendiconti-cell-campo-extra-total.component.scss']
})
export class ReportRendicontiCellCampoExtraTotalComponent {

  @Input() set rendiconti(newValue: IRendiconto[]) {
    this._rendiconti = newValue;
    this.mapValue();
  };

  @Input() set negozi(newValue: INegozio[]) {
    this._negozi = newValue;
    this.mapValue();
  }

  @Input() set fascia(newValue: IFasciaOraria) {
    this._fascia = newValue;
    this.mapValue();
  }

  @Input() set campoExtra(newValue: ICampoExtraRendicontoValorizzato) {
    this._campoExtra = newValue;
    this.mapValue();
  }

  valore: any = "-";

  _rendiconti: IRendiconto[] = [];
  _negozi: INegozio[] = [];
  _fascia: IFasciaOraria;
  _campoExtra: ICampoExtraRendicontoValorizzato;

  rendiconto: IRendiconto;

  mapValue() {

    if (!(this._rendiconti && this._fascia && this._campoExtra)) {
      return;
    }

    const rendicontiFiltered = this._rendiconti.filter(rendiconto => rendiconto.fasciaOraria?.id == this._fascia?.id && this._negozi.map(negozio => negozio.id).includes(rendiconto.negozio?.id));

    if (rendicontiFiltered.length) {

      const campiOpRend = rendicontiFiltered.map(rendiconto => rendiconto.campiOperatore)
      const campiOp = campiOpRend.reduce((accumulator, value) => accumulator.concat(value), []);
      const campiExOp = campiOp.map(campoOp => campoOp.campiExtra)
      const campiEx = campiExOp.reduce((accumulator, value) => accumulator.concat(value), []);
      const campExFil = campiEx.filter(campo => campo.codice == this._campoExtra.codice && campo.nome == this._campoExtra.nome)

      if (campExFil?.length) {
        this.valore = campExFil.map(campoEx => Number(campoEx.valore)).reduce((sum, val) => sum + val)
      }

    }
  }

}
