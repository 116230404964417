<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center">
                <a href="#">
                    <img class="block-center rounded" src="assets/img/logo.png" alt="Image" />
                </a>
            </div>
            <div class="card-body">
                <p class="text-center py-2">{{'forgot.title' | translate}}</p>
                <form [formGroup]="valForm" class="form-validate" role="form" name="recoverForm" novalidate="" (submit)="submitForm($event, valForm.value)">
                    <p class="text-center">{{'forgot.info' | translate}}</p>
                    <div class="form-group">
                        <label class="text-muted">{{'base.mail_address' | translate}}</label>
                        <div class="input-group with-focus">
                            <input class="form-control border-right-0" type="email" name="email" placeholder="{{'base.mail_address' | translate}}" autocomplete="off" formControlName="email" />
                            <div class="input-group-append">
                                <span class="input-group-text text-muted bg-transparent border-left-0">
                                    <em class="fa fa-envelope"></em>
                                </span>
                            </div>
                        </div>
                        <div class="text-danger" *ngIf="valForm.controls['email'].hasError('required') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">{{'base.field_required' | translate}}</div>
                        <div class="text-danger" *ngIf="valForm.controls['email'].hasError('email') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">{{'base.field_mail_required' | translate}}</div>


                        <label class="text-muted">{{'base.enter_login' | translate}}</label>
                        <div class="input-group with-focus">
                            <input class="form-control border-right-0" type="text" name="login" placeholder="{{'base.enter_login' | translate}}" autocomplete="off" formControlName="login" />
                            <div class="input-group-append">
                                <span class="input-group-text text-muted bg-transparent border-left-0">
                                    <em class="fa fa-envelope"></em>
                                </span>
                            </div>
                        </div>
                        <div class="text-danger" *ngIf="valForm.controls['login'].hasError('required') && (valForm.controls['login'].dirty || valForm.controls['email'].touched)">{{'base.field_required' | translate}}</div>                        


                        
                    </div>
                    <button class="btn btn-danger btn-block" type="submit">{{'forgot.reset' | translate}}</button>
                </form>
            </div>
            <s1-language></s1-language>
        </div>
        <!-- END card-->
        <div class="p-3 text-center">
            <span>&copy;</span>
            <span>{{ settings.getAppSetting('year') }}</span>
            <span class="mx-2">-</span>
            <span>{{ settings.getAppSetting('name') }}</span>
            <br/>
            <span>{{ settings.getAppSetting('description') }}</span>
        </div>
    </div>
</div>
