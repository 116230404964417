import { Component, OnDestroy, OnInit } from '@angular/core';
import { S1UIService } from '@app/s1';
import { IOperatore } from '@app/shared/models/operatore';
import { IUtente } from '@app/shared/models/utente';

import { UtenteService } from '@app/shared/services/utente.service';
import { error } from 'console';
import { Subscription } from 'rxjs';

@Component({
  selector: 'info-utente',
  templateUrl: './info-utente.component.html',
  styleUrls: ['./info-utente.component.scss']
})
export class InfoUtenteComponent implements OnInit, OnDestroy {

  utente: IUtente;
  operatore: IOperatore;

  operatoreSub: Subscription;

  constructor(private utenteService: UtenteService, private ui : S1UIService) { }

  ngOnInit(): void {

    this.utenteService.getCurrentUser().subscribe(utente => { 
      this.utente = utente;
    })

    this.operatoreSub = this.utenteService.operatore$.subscribe(operatore => {
      this.operatore = operatore;
    });

  }

  ngOnDestroy(): void {
    this.operatoreSub.unsubscribe();
  }

}
