import { Component, EventEmitter, Input, Output } from '@angular/core';
import { S1ButtonType } from '../s1-button/s1-button.component';
import { UntypedFormGroup } from '@angular/forms';
import { S1Table } from '../s1-table/s1-table.component';

@Component({
  selector: 's1-form',
  templateUrl: './s1-form.component.html',
  styleUrls: ['./s1-form.component.scss']
})
export class S1FormComponent {

  @Input() form: UntypedFormGroup;
  @Input() table: S1Table;

  @Output() onClickSearch = new EventEmitter<any>();
  @Output() onClickReset = new EventEmitter<any>();

  s1ButtonType = S1ButtonType;

  reset() {
    this.form.reset();
    this.table?.setDefault();
    this.onClickReset.emit();
  }

  search() {
    this.onClickSearch.emit();
  }

}
