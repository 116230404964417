
const Home = {
    text: 'Home',
    link: '/home',
    icon: 'fa fa-home'
};

const Utenti = {
    text: 'Utenti',
    link: '/utenti',
    icon: 'fa fa-user'
};

const Users = {
    text: 'User',
    link: '/user',
    icon: 'icon-user'
};

const headingMain = {
    text: '',
    heading: true
};

export const menu = [
    headingMain,
    Home,
    Utenti
];
