<div class="w-100 d-flex justify-content-center">
  <s1-input-datepicker class="mr-4" [control]="dataDaControl" [maxDate]="dataAControl.value"></s1-input-datepicker>
  <s1-input-datepicker class="mr-4" [control]="dataAControl" [minDate]="dataDaControl.value"></s1-input-datepicker>
  <s1-input-switch [control]="weekendControl" [label]="'Weekend'"></s1-input-switch>
</div>
<div class="table-container w-100">
  <table class="table table-striped td-vertical-center">
    <thead>
      <tr>
        <th class="py-0 sticky-left-first header bg-sticky"></th>
        <ng-container *ngFor="let turno of turni">
          <th [attr.colspan]="3" class="text-center bg-secondary py-0 header">{{ turno.nome }}</th>
          <th class="separatore-turno header"></th>
        </ng-container>
        <th [attr.colspan]="3" class="text-center bg-secondary py-0 header">Tot operatore</th>
      </tr>
      <tr class="header-second-line bg-white">
        <th class="py-0 sticky-left-first bg-sticky"></th>
        <ng-container *ngFor="let turno of turni">
          <ng-container *ngFor="let tipo of tipiDato">
            <th class="py-0">{{ tipo | titlecase }}</th>
          </ng-container>
          <th class="separatore-turno"></th>
        </ng-container>
        <ng-container *ngFor="let tipo of tipiDato">
          <th class="py-0">{{ tipo | titlecase }}</th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let operatore of operatori">
        <tr>
          <th class="text-left py-0 sticky-left-first text-nowrap bg-sticky">{{ operatore.codice }} - {{ operatore.nomeCompleto }}</th>
          <ng-container *ngFor="let turno of turni">  
            <ng-container *ngFor="let tipo of tipiDato">
              <td class="py-0">     
                <iwr-report-tasso-conversione-cell
                [rendiconti]="rendiconti"
                [operatore]="operatore"
                [turno]="turno"
                [tipoDato]="tipo">
                </iwr-report-tasso-conversione-cell>         
              </td>
            </ng-container>
            <td class="separatore-turno bg-dark"></td>
          </ng-container>
          <ng-container *ngFor="let tipo of tipiDato">
            <td class="bg-info py-0">
              <iwr-report-tasso-conversione-cell-total-operatore
            [rendiconti]="rendiconti"
            [operatore]="operatore"
            [turni]="turni"
            [tipoDato]="tipo">
            </iwr-report-tasso-conversione-cell-total-operatore>
            </td> 
          </ng-container>
        </tr>
      </ng-container>
      <tr class="bg-info">
        <th class="bg-secondary">Totale</th>
        <ng-container *ngFor="let turno of turni">  
          <ng-container *ngFor="let tipo of tipiDato">
            <td>              
              <iwr-report-tasso-conversione-cell-total-turno
                [rendiconti]="rendiconti"
                [operatori]="operatori"
                [turno]="turno"
                [tipoDato]="tipo">
              </iwr-report-tasso-conversione-cell-total-turno>  
            </td>
          </ng-container>
          <td class="separatore-turno"></td>
        </ng-container>
        <ng-container *ngFor="let tipo of tipiDato">
          <td class="bg-warning">              
            <iwr-report-tasso-conversione-cell-total
              [rendiconti]="rendiconti"
              [operatori]="operatori"
              [turni]="turni"
              [tipoDato]="tipo">
            </iwr-report-tasso-conversione-cell-total>  
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>