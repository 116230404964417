import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1SearchParams } from '@app/s1';
import { IBudget } from '@app/shared/models/budget';
import { IFasciaOraria } from '@app/shared/models/fascia-oraria';
import { TipoNegozio, INegoziRaggruppati, INegozio } from '@app/shared/models/negozio';
import { IRendicontiRaggruppatiGiorno, IRendiconto } from '@app/shared/models/rendiconto';
import { BudgetService, IRicercaBudgetParams } from '@app/shared/services/budget.service';
import { FasciaOrariaService } from '@app/shared/services/fascia-oraria.service';
import { NegozioService } from '@app/shared/services/negozio.service';
import { RendicontoService, IRicercaRendicontoParams } from '@app/shared/services/rendiconto.service';
import { Subscription, forkJoin } from 'rxjs';
import { TipoDatoCellaReportRendiconto } from '../report-rendiconti-cell/report-rendiconti-cell.component';
import { UtilityService } from '@app/shared/services/utility.service';

@Component({
  selector: 'iwr-report-rendiconti-negozio-section',
  templateUrl: './report-rendiconti-negozio-section.component.html',
  styleUrls: ['./report-rendiconti-negozio-section.component.scss']
})
export class ReportRendicontiNegozioSectionComponent {

  @Input() dataDaControl: UntypedFormControl;
  @Input() dataAControl: UntypedFormControl;
  @Input() negozioControl: UntypedFormControl;

  date: Date[] = [];
  rendicontiReggruppatiGiorno: IRendicontiRaggruppatiGiorno[] = [];
  fasce: IFasciaOraria[] = [];
  budgets: IBudget[] = [];
  tipiDato: TipoDatoCellaReportRendiconto[] = [];
  rendiconti: IRendiconto[] = [];
  negozio: Partial<INegozio>;
  storici: Partial<IRendiconto>[] = [];

  dataDaSub: Subscription;
  dataASub: Subscription;
  negozioSub: Subscription;

  constructor(private negozioService: NegozioService, private fasciaOrariaService: FasciaOrariaService, private rendicontoService: RendicontoService, private budgetService: BudgetService,  private utilityService: UtilityService) {
    this.dataDaControl = new UntypedFormControl(new Date());
    this.dataAControl = new UntypedFormControl(new Date());
    this.negozioControl = new UntypedFormControl();
    this.tipiDato =  Object.keys(TipoDatoCellaReportRendiconto).map(tipo => TipoDatoCellaReportRendiconto[tipo]);
    this.load();

    this.dataDaSub = this.dataDaControl.valueChanges.subscribe(changed => {
      this.load();
    })

    this.dataASub = this.dataAControl.valueChanges.subscribe(changed => {
      this.load();
    })

    this.negozioSub = this.negozioControl.valueChanges.subscribe(changed => {
      this.negozio = { id: changed }
      this.load();
    })

  }

  load() {

    const dataDa: Date = this.dataDaControl.value ? this.utilityService.UTCDateFromLocal(this.dataDaControl.value) : null;
    const dataA: Date = this.dataAControl.value ? this.utilityService.UTCDateFromLocal(this.dataAControl.value) : null;

    if (dataDa == null || dataA == null || this.negozio?.id == null) {
      return;
    }

    const searchParams: IS1SearchParams = {
      page: 1,
      pageSize: 999,
      paginable: false
    }

    const searchParamsWithDate: IRicercaBudgetParams = {
      page: 1,
      pageSize: 999,
      paginable: false,
      daGiorno: dataDa,
      aGiorno: dataA,
      negozio: this.negozio
    }

    const searchRendicontoParams: IRicercaRendicontoParams = {
      page: 1,
      pageSize: 999,
      paginable: false,
      daGiorno: dataDa,
      aGiorno: dataA,
      negozio: this.negozio,
      mostraStoricoFasceSenzaRendiconto: true
    }

    const searchRendicontoStoricoParams: IRicercaRendicontoParams = {
      page: 1,
      pageSize: 999,
      paginable: false,
      daGiorno: dataDa,
      aGiorno: dataA,
      negozio: this.negozio,
      mostraStoricoFasceSenzaRendiconto: false
    }

    forkJoin([
      this.fasciaOrariaService.ricerca(searchParams),
      this.budgetService.ricerca(searchParamsWithDate as IRicercaBudgetParams),
      this.rendicontoService.ricerca(searchRendicontoParams),
      this.rendicontoService.ricercaStorico(searchRendicontoStoricoParams)
    ]).subscribe(([turni, budgets, rendiconti, storici]) => {

      this.fasce = turni?.elements ?? [];
      this.budgets = budgets?.elements ?? [];
      this.rendiconti = rendiconti?.elements ?? [];
      this.storici = storici?.elements ?? [];
      this.mapDate();
      
    },
      (err) => { console.log("ERR fork: ", err)}
    );
  }

  private mapDate() {

    const dataDa: Date = this.dataDaControl.value;
    const dataA: Date = this.dataAControl.value;

    this.rendicontiReggruppatiGiorno = [];

    while (dataDa <= dataA) {

      const singleDate = new Date();
      singleDate.setFullYear(dataDa.getFullYear());
      singleDate.setMonth(dataDa.getMonth());
      singleDate.setDate(dataDa.getDate());
      this.rendicontiReggruppatiGiorno.push({
        giorno: singleDate,
        rendiconti: this.rendiconti.filter(rendiconto => new Date(rendiconto.giorno).toLocaleDateString() == singleDate.toLocaleDateString())
      });
      dataDa.setDate(dataDa.getDate() + 1);

    }

  }

}
