
<form #fakeForm>
  <input 
  class="form-control" 
  type="number" 
  name="numericValue"
  #numericValueModel="ngModel"
  [step]="step"
  [ngModel]="numericValue"
  (ngModelChange)="valueUpdated($event)"
  pattern="^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$"
  [ngClass]="{ 'is-invalid': numericValueModel.dirty && numericValueModel.invalid }"
  [readonly]="readonly">
</form>