import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1AutocompleteResult } from '@app/s1';
import { ApiService } from '@app/shared/services/api.service';

@Component({
  selector: 'all-entita-autocomplete',
  templateUrl: './entita-autocomplete.component.html',
  styleUrls: ['./entita-autocomplete.component.scss']
})
export class EntitaAutocompleteComponent implements OnInit {

  @Input() control: UntypedFormControl;

  description: string;

  constructor(public api: ApiService) { }

  ngOnInit(): void { }

  itemSelected(item: IS1AutocompleteResult) {
    this.description = item?.descrizione;
  }

}
