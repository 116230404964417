import { Component, EventEmitter, Output } from '@angular/core';
import { S1ButtonType } from '../s1-button/s1-button.component';
import { UtilityService } from '@app/shared/services/utility.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 's1-audio-recorder',
  templateUrl: './s1-audio-recorder.component.html',
  styleUrls: ['./s1-audio-recorder.component.scss']
})
export class S1AudioRecorderComponent {

  @Output() onAddFileAudio = new EventEmitter<File>();

  s1ButtonType = S1ButtonType;

  mediaRecorder: MediaRecorder;
  audioBlob: Blob;
  audio: HTMLAudioElement;
  audioChunks = [];
  recording: boolean = false;
  playing: boolean = false;

  constructor(private datepipe: DatePipe, private utility: UtilityService) {

  }

  record() {

    this.recording = true;

    navigator.mediaDevices.getUserMedia({ audio: true })
    .then(stream => {
      
      this.mediaRecorder = new MediaRecorder(stream);
      this.mediaRecorder.start();

      this.audioChunks = [];

      this.mediaRecorder.addEventListener("dataavailable", event => {
        this.audioChunks.push(event.data);
        
      });

      this.mediaRecorder.addEventListener("stop", () => {
        this.audioBlob = new Blob(this.audioChunks);
        this.recording = false;
      });

    });

  }

  stopRecord() {
    this.mediaRecorder.stop();
  }

  play() {
    const audioUrl = URL.createObjectURL(this.audioBlob);
    this.audio = new Audio(audioUrl);
    this.audio.onended = ()=> {
      this.stopPlay();
    }
    this.audio.play();
    this.playing = true;
  }

  stopPlay() {
    this.audio?.pause();
    this.playing = false;
  }

  add() {

    const timeStamp = this.datepipe.transform(new Date(), "yyyy-MM-dd_HHmmss");

    const audioFile: File = this.utility.blobToFile(this.audioBlob, "audio_" + timeStamp + ".mp3");

    this.onAddFileAudio.emit(audioFile);
    this.delete();

  }

  delete() {
    this.stopRecord();
    this.stopPlay();
    this.audio = null;
    this.audioBlob = null;
  }

}
