<s1-card [title]="_tipo.denominazione">
  <s1-button header-right [type]="s1ButtonType.New" (onClick)="nuovo()"></s1-button>
  <s1-table #table [onlinePagination]="true" (pageChanged)="searchAllegati(this._tipo)">
    <thead header>
      <tr>
        <th> # </th>               
        <th>{{ 'allegati.ricerca.table.columns.nome' | translate }}</th>
        <th>{{ 'allegati.ricerca.table.columns.tipo' | translate }}</th>
        <th>{{ 'allegati.ricerca.table.columns.note' | translate }}</th>
        <th></th>                                      
      </tr>
    </thead>
    <tbody body>
      <tr *ngFor="let allegato of table.config.rows; let i = index">
        <td>{{ i | s1TableGeneralIndex: table }}</td>
        <td>{{ allegato.nomeFile }}</td>
        <td>{{ allegato.contentType }}</td>
        <td>{{ allegato.note }}</td>
        <td class="text-right">
          <s1-button (onClick)="download(allegato, false)" [type]="s1ButtonType.Download" class="mr-2"></s1-button>
          <s1-button (onClick)="download(allegato, true)" [type]="s1ButtonType.View" class="mr-2"></s1-button>
          <s1-button (onClick)="askDelete(allegato)" [type]="s1ButtonType.Delete" class=""></s1-button> 
        </td>
      </tr>
    </tbody>
  </s1-table>
</s1-card>

<all-allegato-modal #allegatoModal (uploaded)="searchAllegati(_tipo)"></all-allegato-modal>

