import { Injectable } from '@angular/core';
import { IS1PaginatedResult, IS1SearchParams, S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IAzioneMenu {
  id?: string,
  codice: string,
  descrizione: string,
  url: string,
  icona?: string,
  funzioni?: IFunzioneAzioneMenu[]
}

export interface IFunzioneAzioneMenu {
  id?: string,
  codice: string,
  descrizione: string,
  idAzione?: string
}

export interface IAzioneMenuRicercaParams extends IS1SearchParams {
  id?: string;
  codice?: string;
  descrizione?: string;
  filtraAzionePreferiti?:boolean
}

export interface IAzioniMenuPaginate extends IS1PaginatedResult {
  azioni: IAzioneMenu[];
}

export enum AzioneMenuAPI {
  ricerca = "/AzioneMenu/ricerca",
}

export enum FunzioneAzioneAPI {
  ricerca = "/Funzioni/Ricerca",
  funzione = "/Funzione"
}

export interface IFunzioniAzioneMenuPaginate extends IS1PaginatedResult {
  funzioni: IFunzioneAzioneMenu[];
}

export interface IFunzioneAzioneMenuRicercaParams extends IAzioneMenuRicercaParams { }

@Injectable({
  providedIn: 'root'
})
export class AzioneMenuService {

  private path = "/AzioneMenu"

  constructor(private s1HttpClient: S1HttpClientService) { }

  ricerca(params: IAzioneMenuRicercaParams): Observable<IAzioniMenuPaginate> {

    return this.s1HttpClient.post(AzioneMenuAPI.ricerca, params).pipe(
      map(response => {
        return  { 
          azioni: response.results,
          paginationInfo: response.paginationInfo
        } as IAzioniMenuPaginate
      })
    )

  }

  create(azione: IAzioneMenu): Observable<boolean> {

    return this.s1HttpClient.post(this.path, azione).pipe(
      map(response => {
        return response.outcome.success
      })
    )

  }

  update(azione: IAzioneMenu): Observable<boolean> {

    return this.s1HttpClient.put(`${this.path}/${azione?.id}`, azione).pipe(
      map(response => {
        return response.outcome.success
      })
    )

  }

  delete(azione: IAzioneMenu): Observable<boolean> {

    return this.s1HttpClient.delete(`${this.path}/${azione?.id}`).pipe(
      map(response => {
        return response.outcome.success
      })
    )

  }

  ricercaFunzioni(idAzione: string, params: IFunzioneAzioneMenuRicercaParams): Observable<IFunzioniAzioneMenuPaginate> {

    return this.s1HttpClient.post(`${this.path}/${idAzione}${FunzioneAzioneAPI.ricerca}`, params).pipe(
      map(response => {
        return  { 
          funzioni: response.results,
          paginationInfo: response.paginationInfo
        } as IFunzioniAzioneMenuPaginate
      })
    )

  }

  createFunzione(idAzione: string, funzione: IFunzioneAzioneMenu): Observable<boolean> {

    delete funzione.id;

    return this.s1HttpClient.post(`${this.path}/${idAzione}${FunzioneAzioneAPI.funzione}`, funzione).pipe(
      map(response => {
        return response.outcome.success
      })
    )

  }

  updateFunzione(idAzione: string, funzione: IFunzioneAzioneMenu): Observable<boolean> {

    return this.s1HttpClient.put(`${this.path}/${idAzione}${FunzioneAzioneAPI.funzione}/${funzione?.id}`, funzione).pipe(
      map(response => {
        return response.outcome.success
      })
    )

  }

  deleteFunzione(idAzione: string, funzione: IFunzioneAzioneMenu): Observable<boolean> {

    return this.s1HttpClient.delete(`${this.path}/${idAzione}${FunzioneAzioneAPI.funzione}/${funzione?.id}`).pipe(
      map(response => {
        return response.outcome.success
      })
    )

  }

}
