import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { RecoverComponent } from './recover/recover.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { Error404Component } from './error404/error404.component';
import { ForgotComponent } from './forgot/forgot.component';


@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        LoginComponent,
        RecoverComponent,
        ForgotComponent,
        MaintenanceComponent,
        Error404Component
    ],
    exports: [
        LoginComponent,
        RecoverComponent,
        MaintenanceComponent,
        Error404Component
    ]
})
export class PagesModule { }
