import { Component, Input } from '@angular/core';
import { INegozio } from '@app/shared/models/negozio';
import { IRendiconto } from '@app/shared/models/rendiconto';

@Component({
  selector: 'iwr-report-rendiconti-cell-storico',
  templateUrl: './report-rendiconti-cell-storico.component.html',
  styleUrls: ['./report-rendiconti-cell-storico.component.scss']
})
export class ReportRendicontiCellStoricoComponent {

  @Input() set storici(newValue: Partial<IRendiconto>[]) {
    this._storici = newValue;
    this.mapValue();
  };

  @Input() set negozi(newValue: INegozio[]) {
    this._negozi = newValue;
    this.mapValue();
  }

  @Input() set data(newValue: Date) {
    this._data = newValue;
    this.mapValue();
  }

  _storici: Partial<IRendiconto>[] = [];
  _negozi: INegozio[] = [];
  _data: Date;

  valore: any = 0;

  mapValue() {

    if (!(this._storici?.length && this._negozi?.length)) {
      return;
    }

    let storiciFiltered = this._storici.filter(rendiconto => this._negozi.map(negozio => negozio.id).includes(rendiconto.negozio?.id));

    if (this._data) {
      storiciFiltered = storiciFiltered.filter(rendiconto => new Date(rendiconto.giornoCorrente).toLocaleDateString() == this._data.toLocaleDateString())
    } 

    if (storiciFiltered) {

      this.valore = storiciFiltered.map(rendiconto => rendiconto.incasso).reduce((sum, val) => sum + Number(val), 0);

    }

  }

}
