import { Injectable } from '@angular/core';
import { IS1SearchParams, S1ApiCrudService, S1HttpClientService } from '@app/s1';
import { ITask } from '../models/task';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StatoTaskNegozio } from '../models/task-negozio';

export interface IRicercaTaskParams extends IS1SearchParams, Partial<ITask> { 
  dataDa: string,
  dataA: string
}

export interface IAllegatoTaskParams {
  idTask: string,
  idAttachment: string
}

enum TaskAPI {
  attachment = "/attachment",
  upload = "/upload"
}

@Injectable({
  providedIn: 'root'
})
export class TaskService extends S1ApiCrudService<ITask, IRicercaTaskParams> {

  constructor(s1HttpClient: S1HttpClientService) { 
    super(s1HttpClient, "/task");
  }

  nuovo(formData: FormData): Observable<any> {
    return this.s1HttpClient.upload(`${this.path}`, formData).pipe(
      map(response => response)
    )
  }

  uploadAllegati(idTask: string, formData: FormData): Observable<any> {
    return this.s1HttpClient.upload(`${this.path}/${idTask}${TaskAPI.upload}`, formData).pipe(
      map(response => response)
    )
  }

  pubblica(idTask: string): Observable<boolean> {
    return this.cambiaStato(idTask, StatoTaskNegozio.pubblicato);
  }

  private cambiaStato(idTask: string, stato: StatoTaskNegozio): Observable<boolean> {
    return this.s1HttpClient.put(`${this.path}/${idTask}/${stato}`, {}).pipe(
      map(response => response.outcome.success)
    )
  }

  getAllegato(params: IAllegatoTaskParams): Observable<any> {
    return this.s1HttpClient.download(`${this.path}/${params.idTask}${TaskAPI.attachment}/${params.idAttachment}`).pipe(
      map(response => response)
    )
  }

  rimuoviAllegato(params: IAllegatoTaskParams): Observable<any> {
    return this.s1HttpClient.delete(`${this.path}/${params.idTask}${TaskAPI.attachment}/${params.idAttachment}`).pipe(
      map(response => response)
    )
  }

}
