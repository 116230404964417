import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

export interface IS1InputSelectItem {
  code: string | number | boolean;
  label: string;
  group?: string;
}

@Component({
  selector: 's1-input-select',
  templateUrl: './s1-input-select.component.html',
  styleUrls: ['./s1-input-select.component.scss']
})
export class S1InputSelect implements OnInit, AfterViewInit {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() readonly: boolean = false;
  @Input() itemsList: IS1InputSelectItem[];
  @Input() itemDescpription: string;
  @Input() bindAllObject: boolean = false;
  @Input() bindValue: string = 'code';
  @Input() bindLabel: string = 'label';
  @Input() multiple: boolean = false;
  @Input() searchFunction: any;
  @Input() allowGroupBy: boolean = false;

  @Output() onSelectClear = new EventEmitter();
  @Output() onSelectChange = new EventEmitter();

  @ViewChild('selectInput') selectInput: NgSelectComponent;

  groupByFunction = (item) => { return  item.group };

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void { }

  checkIsValid(): boolean {
    return this.control.valid || !this.control.touched;
  }

  focus() {
    this.selectInput.focus();
  }

  cleared() {
    this.onSelectClear.emit();
  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

}
