import { IRuoloMenu } from "../services/ruolo.service"
import { IS1BasicEntity, S1State } from "./entita-base"
import { INegozio } from "./negozio"

export const SUPERADMIN = "SUPERADMIN";
export const AREAMANAGER = "AREA MANAGER";

export enum StatoUtente {
  pending = "PENDING",
  enabled =  "ENABLED",
  change_pwd = "CHANGE_PWD",
  blocked = "BLOCKED",
  deleted = "DELETED"
}

// Utente
export interface IUtente extends Omit<IS1BasicEntity, 'stato'> {
  username: string,
  nomeCompleto: string,
  email: string,
  serverLDAP: string,
  telefono?: string,
  cellulare?: string,
  ruoli: Partial<IRuoloMenu>[],
  negozi?: Partial<INegozio>[],
  codiceOperatoreRichiesto?: boolean,
  vediTuttiNegozi?: boolean
  stato?: StatoUtente
}