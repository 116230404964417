
<s1-card [title]="'treeEditor.title'">
  <div class="row">
    <div class="col-8">
        <div class="text-center">
            <h4>{{ 'treeEditor.structure' | translate }}</h4>
        </div>
        <button class="btn btn-success btn-sm mb-2 text-center" (click)="addFirstLevelNode(tree)" [disabled]="readOnly">
            <i class="fa fa-plus"></i> {{ 'treeEditor.addFirstLevelNode' | translate }}
        </button>
        <tree-root #tree [nodes]="nodes" [focused]="true" [options]="customTemplateStringOptions">
          <ng-template #treeNodeTemplate let-node>
            <form class="form-inline">
              <button class="btn treeIcon" type="button" (click)="setIcon(node)" [disabled]="readOnly">
                <i class="{{ node.data.icon }}"></i>
              </button>
              <input type="text" class="form-control" [(ngModel)]="node.data.text" [ngModelOptions]="{standalone: true}" [readOnly]="readOnly" />
              <button class="btn btn-success btn-sm ml-2" (click)="addNode(tree, node)" [disabled]="readOnly">
                <i class="fa fa-plus"></i>
              </button>
              <button class="btn btn-danger btn-sm ml-2" (click)="removeNode(tree, node)" [disabled]="readOnly">
                <i class="fa fa-minus"></i>
              </button>
              <button *ngIf="showRemoveAction(node)" class="btn btn-info btn-sm ml-2" (click)="checkLink(node)"> {{ node.data.descrizioneAzione }} <i class="fa fa-external-link-alt"></i></button>
              <button *ngIf="showAddAction(node)" class="btn btn-info btn-sm ml-2" (click)="addAction(tree, node)" [disabled]="readOnly">
                <i class="fa fa-link"></i>
              </button>
              <button *ngIf="showRemoveAction(node)" class="btn btn-warning btn-sm ml-2" (click)="removeAction(tree, node)" [disabled]="readOnly">
                <i class="fa fa-unlink"></i>
              </button>
              <button *ngIf="showPolicy(node)" class="btn btn-green btn-sm ml-2" (click)="managePolicy(tree, node)" [disabled]="readOnly">
                <i class="fa fa-user-lock"></i>
              </button>
            </form>
          </ng-template>
          <ng-template #loadingTemplate>{{ 'treeEditor.loadingData' | translate }}</ng-template>
        </tree-root>
    </div>
    <div class="col-4">
      <div class="text-center">
        <h4>{{ 'treeEditor.preview' | translate }}</h4>
      </div>
      <ul class="list-group">
        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: nodes }"></ng-container>
      </ul>
    </div>
  </div>
</s1-card>

<ng-template #recursiveList let-nodes>
  <li *ngFor="let node of nodes" class="list-group-item">
    <i class="{{ node.icon }} ml-2 mr-2"></i> {{ node.text }}
    <ul *ngIf="node.children.length > 0" class="list-group">
      <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: node.children }"></ng-container>
    </ul>
  </li>
</ng-template>

<!-- Modal Action Menu -->
<s1-standard-search-modal #searchActionModal (elementSelected)="actionSelectedOnSearchModal($event)"></s1-standard-search-modal>

<!-- Modal Icon Menu -->
<s1-icon-modal #iconModal (selected)="iconSelected($event)"></s1-icon-modal>

<!-- Modal Policy -->
<s1-policy-modal #policyModal></s1-policy-modal>