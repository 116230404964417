import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private datepipe: DatePipe) { }

  stingFromDate(date: Date, withTime: boolean = true) {
    if (date) {
      return this.datepipe.transform(date, `yyyy-MM-dd${ withTime ? 'THH:mm:ss' : ''}`);
    }

    return null
    
  }

  UTCDateFromLocal(date: Date): Date {
    if (date == null) { return date }
    var onlyDayDateString = new DatePipe('en-EN').transform(date, 'yyyy-MM-dd');
    onlyDayDateString += 'T00:00:00Z';
    return new Date(onlyDayDateString);
  }


  UTCDateTimeFromLocal(date: Date): Date {
    if (date == null) { return date }
    var onlyDayDateString = new DatePipe('en-EN').transform(date, 'yyyy-MM-dd HH:mm:ss');
    
    return new Date(onlyDayDateString);
  }


  UTCDateTimeFromLocalIT(date: Date | string): string {
    if (date == null) { return null }
    return new DatePipe('it-IT').transform(date, 'dd/MM/YYYY HH:mm:ss');
  }

  blobToFile = (theBlob: Blob, fileName:string): File => {
    return new File(
      [theBlob as any], // cast as any
      fileName, 
      {
          lastModified: new Date().getTime(),
          type: theBlob.type 
      }
    )

  }


}
