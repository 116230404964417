import { IBudget } from "./budget";
import { ICampoExtraRendicontoValorizzato } from "./campo-extra-rendiconto";
import { IS1BasicEntity } from "./entita-base";
import { IFasciaOraria } from "./fascia-oraria";
import { INegozio } from "./negozio";
import { IOperatore } from "./operatore";
import { ITurno } from "./turno";
import { IUtente } from "./utente";

export interface IRendiconto extends Omit<IS1BasicEntity, 'stato'> { 
  giorno: Date,
  negozio: Partial<INegozio>,
  operatore: Partial<IOperatore>,
  stato: StatoRendiconto,
  fasciaOraria: Partial<IFasciaOraria>,
  budget: Partial<IBudget>,
  storico: Partial<IRendiconto>,
  incasso: number,
  ingressi: number,
  mediaImportoScontrini: number,
  mediaPrezziRigaScontrini: number,
  numeroScontrini: number,
  numeroBanconoteIngaggio10: number,
  numeroBanconoteIngaggio15: number,
  numeroBanconoteRitirate10: number,
  numeroBanconoteRitirate15: number,
  tassoDiConversione: number,
  tassoDiConversioneVoucher10: number,
  tassoDiConversioneVoucher15: number
  turno?: ITurno
  giornoCorrente?: Date // Serve solo per storico
  numeroAcquistoDistribuiti: number,
  numeroAcquistoForniti: number,
  numeroAcquistoRicevuti: number,
  campiOperatore: ICampiOperatoreRendiconto[],
  utenteCreatoDa?: IUtente
}

export enum StatoRendiconto {
  draft = "DRAFT",
  approved = "APPROVED"
}

export interface IRendicontiRaggruppatiGiorno { 
  giorno: Date,
  rendiconti: IRendiconto[] 
}

export interface ICampiOperatoreRendiconto {
  operatore: Partial<IOperatore>,
  numeroBanconoteIngaggioOperatore10: number,
  numeroBanconoteIngaggioOperatore15: number,
  numeroBanconoteRitirateOperatore10: number,
  numeroBanconoteRitirateOperatore15: number,
  tassoDiConversioneVoucherOperatore10: number,
  tassoDiConversioneVoucherOperatore15: number,
  numeroPezziIntimoOperatore: number,
  numeroScontriniIntimoOperatore: number,
  ordinamento: number,
  campiExtra: ICampoExtraRendicontoValorizzato[]
}