import { Component, Input, OnInit } from '@angular/core';
import { ITipoAllegatoEntita } from '@app/shared/models/allegato';
import { Entita, IRicercaTipoAllegatoEntitaParams, TipoAllegatoEntitaService } from '@app/shared/services/tipo-allegato-entita.service';

@Component({
  selector: 'all-allegati-section',
  templateUrl: './allegati-section.component.html',
  styleUrls: ['./allegati-section.component.scss']
})
export class AllegatiSectionComponent implements OnInit {

  @Input() idOggetto: string;

  @Input() set entita(newValue: Entita) {

    this.searchTipi(newValue);

  }

  tipi: ITipoAllegatoEntita[] = [];

  constructor(private tipoAllegatoEntitaService: TipoAllegatoEntitaService) { }

  ngOnInit(): void { }

  searchTipi(entita: Entita) {

    const params: IRicercaTipoAllegatoEntitaParams = {
      page: 1,
      pageSize: 999,
      entita: entita,
      sort:"ordinamento asc"
    }

    this.tipoAllegatoEntitaService.ricerca(params).subscribe(tipiAllegatoEntitaPaginati => {

      this.tipi = tipiAllegatoEntitaPaginati?.tipi ?? [];

    })

  }

}
