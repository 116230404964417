import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 's1-input-mask',
  templateUrl: './s1-input-mask.component.html',
  styleUrls: ['./s1-input-mask.component.scss']
})
export class S1InputMask implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() tooltip: string = '';
  @Input() readonly: boolean = false;
  @Input() type: string = 'text';
  @Input() isValid: boolean = true;
  @Input() forceValidation: boolean = false;
  @Input() thousandSeparator: string='.';
  @Input() mask:string='';
  @Input() decimalMarker:string=',';
  @Input() append: string = '';
  @Input() allowNegativeNumbers: boolean = true;
  @Input() customClass: string = '';

  @ViewChild('input') input: ElementRef;

  autonumericOptions = {
    allowDecimalPadding: false,
    decimalCharacter: ",",
    digitGroupSeparator: "."
  }

  constructor() { }

  ngOnInit(): void { }

  checkIsValid(): boolean {

    if (this.forceValidation) {
      return this.control.valid
    }

    return this.control.valid || !this.control.touched;

  }

  focus() {
    this.input.nativeElement.focus();
  }

}
