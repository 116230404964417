import { Injectable } from '@angular/core';
import { IMenuItem } from '@app/shared';
import { BehaviorSubject } from 'rxjs';

interface IMenuItemFull {
    text: string,
    heading?: boolean,
    link?: string,     // internal route links
    elink?: string,    // used only for external links
    target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
    icon?: string,
    alert?: string,
    submenu?: Array<IMenuItemFull>
}

@Injectable()
export class MenuService {

    menuItems: any[] = [];
    _menuItems = new BehaviorSubject<any[]>([]);
    menuItems$ = this._menuItems.asObservable();

    constructor() { }

    addMenu(items: any[]) {
        items?.forEach((item) => {
            this.menuItems.push(item);
        });
        this._menuItems.next(this.menuItems);
    }

    getMenu() {
        return this.menuItems;
    }

    replaceMenu(newMenu) {

        this.menuItems = [];
        this.addMenu(newMenu);

    }

    standardizeMenu(menu: any[]): IMenuItem[] {
        return menu?.map(item => {

            return {
                text: item.text,
                icon: item.icon,
                link: item.link,
                submenu: item.children?.length ? this.standardizeMenu(item.children) : []
            } as IMenuItem

        }) ?? []
    }

}
