import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { TipoAllegatoService, IRicercaTipoAllegatoParams } from '@app/shared/services/tipo-allegato.service';

@Component({
  selector: 'all-tipo-allegato-select',
  templateUrl: './tipo-allegato-select.component.html',
  styleUrls: ['./tipo-allegato-select.component.scss']
})
export class TipoAllegatoSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() readonly: boolean;

  tipiAllegato: IS1InputSelectItem[];

  constructor(private tipoAllegatoService: TipoAllegatoService) { }

  ngOnInit(): void { 
    this.loadItems();
  }

  loadItems() {

    const params: IRicercaTipoAllegatoParams = {
      page: 1,
      pageSize: 999
    }
  
    this.tipoAllegatoService.ricerca(params).subscribe(tipiAllegatoPaginati => {
  
      this.tipiAllegato = tipiAllegatoPaginati?.tipi?.map(tipoAllegato => { 
        return { code: tipoAllegato.id, label: tipoAllegato.denominazione?.trim() }  
      }) ?? [];
  
      if (this.tipiAllegato?.length == 1) {
        this.control?.patchValue(this.tipiAllegato[0].code);
      }
  
    })

  }

}
