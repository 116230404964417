<s1-audio-recorder (onAddFileAudio)="addAudioFile($event)"></s1-audio-recorder>
<s1-input-file (newFiles)="addFiles($event)" [disabled]="disabled"></s1-input-file>
<div class="w-100 pt-2"></div>
<s1-card *ngIf="files.length">
  <div class="row border-bottom">
    <div class="col-6">{{ 's1.upload.table.columns.name' | translate }}</div>
    <div class="col-2">{{ 's1.upload.table.columns.size' | translate }}</div>
    <div class="col-3">{{ 's1.upload.table.columns.type' | translate }}</div>
    <div class="col-1"></div>
  </div>
  <div class="row border-bottom p-1" *ngFor="let file of files">
    <div class="col-6 text-left pt-2">{{ file.name }}</div>
    <div class="col-2 text-right pt-2">{{ file.size | s1Bytes }}</div>
    <div class="col-3 pt-2">{{ file.type }}</div>
    <div class="col-1 text-right"><s1-button *ngIf="!disabled" [type]="s1ButtonType.Delete" [onlyIcon]="true" (onClick)="remove(file)"></s1-button></div>
  </div>
</s1-card>
