<s1-input-label [label]="label" class="bootstrap-theme">
  <ng-select #selectInput class="form-control s1" 
  [formControl]="control"
  [items]="itemsList"
  [readonly]="readonly"
  [ngClass]="{'is-invalid' : !checkIsValid() }" 
  [bindValue]="bindAllObject ? null : this.bindValue"
  (clear)="cleared()"
  (change)="changed($event)"
  [multiple]="multiple"
  [searchFn]="searchFunction"
  [groupBy]="allowGroupBy ? groupByFunction : null"
  [selectableGroup]="true"
  [selectableGroupAsModel]="false">
  </ng-select>
</s1-input-label>