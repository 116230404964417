<s1-modal #modal [title]="'allegati.nuovo.title'" (onClose)="reset()">
  <form [formGroup]="allegatoForm">
    <div class="row">
      <all-tipo-allegato-select class="col-12" [control]="allegatoForm.controls.tipoAllegato" [readonly]="true"></all-tipo-allegato-select>
      <s1-text-area class="col-12" [control]="allegatoForm.controls.nota" [label]="'note'"></s1-text-area>
    </div>    
  </form>

  <s1-upload-section *ngIf="formData" [(formData)]="formData" [disabled]="progress"></s1-upload-section>

  <progressbar *ngIf="progress" class="progress-striped active" [animate]="true" [value]="progress" type="primary"><i>{{ progress }} %</i></progressbar>

  <div class="w-100 text-center pt-3">
    <s1-button [type]="s1ButtonType.Upload" (onClick)="upload()" [disabled]="allegatoForm.invalid || progress"></s1-button>
  </div>
  
</s1-modal>

