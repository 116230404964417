import { Directive, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { IS1Sorting, S1SortService } from './s1-sort.service';

@Directive({
  selector: '[s1-sortable-table]'
})
export class S1SortableTableDirective implements OnInit, OnDestroy  {

  @Output() sorted = new EventEmitter<IS1Sorting>();

  private sortingSubscription: Subscription;

  constructor(private s1SortService: S1SortService) { }

  ngOnInit(): void { 
    this.sortingSubscription = this.s1SortService.columnSorted$.subscribe(s1Sorting => {

      this.sorted.emit(s1Sorting);

    })
  }

  ngOnDestroy() {
    this.sortingSubscription?.unsubscribe();
  }

}
