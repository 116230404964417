import { Injectable } from '@angular/core';
import { IRendiconto } from '../models/rendiconto';
import { IS1SearchParams, IS1TypedPaginatedResult, S1ApiCrudService, S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

enum RendicontoApi {
  approva = "approva",
  nuovo = "nuovo",
  ricercaStorico = "/storico/ricerca",
  ricercaReport = "/report/ricerca"
}

export interface IRicercaRendicontoParams extends IS1SearchParams, Partial<IRendiconto> {
  daGiorno?: Date,
  aGiorno?: Date,
  mostraStoricoFasceSenzaRendiconto: boolean
}

export enum TipoCalcoloReportRendiconto {
  FASCIA_ORARIA = "FASCIA_ORARIA",
  TURNO = "TURNO"
}

export enum TipoRaggruppamentoReportRendiconto {
  NEGOZIO = "NEGOZIO",
  OPERATORE = "OPERATORE",
  GIORNO = "GIORNO"
}

export interface IRicercaReportRendicontoParams extends IRicercaRendicontoParams  {
  calcolaPer: TipoCalcoloReportRendiconto,
  escludiWeekend: boolean,
  raggruppaPer: TipoRaggruppamentoReportRendiconto,
  showAll?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class RendicontoService extends S1ApiCrudService<IRendiconto, IRicercaRendicontoParams> {

  constructor(s1HttpClient: S1HttpClientService) { 
    super(s1HttpClient, "/rendiconto");
  }

  approva(idRendiconto: string): Observable<boolean> {
    return this.s1HttpClient.put(`${this.path}/${idRendiconto}/${RendicontoApi.approva}`, {}).pipe(
      map(response => response.outcome.success)
    )
  }

  nuovo(rendiconto: Partial<IRendiconto>): Observable<IRendiconto> {
    return this.s1HttpClient.post(`${this.path}/${RendicontoApi.nuovo}`, rendiconto).pipe(
      map(response => response.item)
    )
  }

  ricercaStorico(params: IRicercaRendicontoParams): Observable<IS1TypedPaginatedResult<IRendiconto>> {

    return this.s1HttpClient.post(`${this.path}${RendicontoApi.ricercaStorico}`, params).pipe(
      map(response => {
        return  { 
          elements: response.results,
          paginationInfo: response.paginationInfo
        } as IS1TypedPaginatedResult<IRendiconto>
      })
    )

  }

  ricercaReport(params: IRicercaReportRendicontoParams): Observable<IS1TypedPaginatedResult<IRendiconto>> {

    return this.s1HttpClient.post(`${this.path}${RendicontoApi.ricercaReport}`, params).pipe(
      map(response => {
        return  { 
          elements: response.results,
          paginationInfo: response.paginationInfo
        } as IS1TypedPaginatedResult<IRendiconto>
      })
    )

  }

}
