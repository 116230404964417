import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { FasciaOrariaService, IRicercaFasciaOrariaParams } from '@app/shared/services/fascia-oraria.service';

@Component({
  selector: 'iwr-fascia-oraria-select',
  templateUrl: './fascia-oraria-select.component.html',
  styleUrls: ['./fascia-oraria-select.component.scss']
})
export class FasciaOrariaSelectComponent {

  @Input() control: UntypedFormControl;
  @Input() label: string = "fasce.fields.fascia";
  @Input() readonly: boolean = false;

  fasce: IS1InputSelectItem[];

  constructor(private fasciaOrariaService: FasciaOrariaService) {

    const params: IRicercaFasciaOrariaParams = {
      page: 1,
      pageSize: 999,
      paginable: false
    }
  
    this.fasciaOrariaService.ricerca(params).subscribe(fascePaginate => {

      this.fasce = fascePaginate?.elements?.map(fascia => { 
        return { code: fascia.id, label: fascia.nome?.trim() }  
      }) ?? [];
  
      if (this.fasce?.length == 1) {
        this.control?.patchValue(this.fasce[0].code);
      }
  
    })

  }
  
}
