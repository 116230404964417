import { Component, OnInit, ViewChild } from '@angular/core';
import { S1Modal, S1ModalSizes } from '@app/s1';
import { ModalComponent } from 'ng-modal-lib';

@Component({
  selector: 'all-pdf-modal',
  templateUrl: './pdf-modal.component.html',
  styleUrls: ['./pdf-modal.component.scss']
})
export class PdfModalComponent implements OnInit {

  @ViewChild('modal') modal: S1Modal;
  @ViewChild('resizableModal') resizableModal: ModalComponent;

  firstPdf: Blob;
  secondPdf: Blob;

  constructor() { }

  ngOnInit(): void { }

  open(firstPdf: Blob, secondPdf?: Blob) {

    this.firstPdf = firstPdf;
    this.secondPdf = secondPdf;

    this.modal.open(S1ModalSizes.XL);
    //this.resizableModal.show();

  }

  close() {
    this.modal.close();
  }

}
