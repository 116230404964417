import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IS1PaginatedResult, IS1SearchParams, S1HttpClientService } from '@app/s1';

export interface IMenuRicercaParams extends IS1SearchParams {
  descrizione?: string;
}

export interface IMenuPaginati extends IS1PaginatedResult {
  menu: any[];
}

export interface IPreferitiUtente  {
  azioni: any[];
}

export enum MenuAPI {
  ricerca = "/ricerca",
  istanza = "/istanza",
  clona = "/clona",
  preferiti="/Preferiti/"  
}

@Injectable({
  providedIn: 'root'
})
export class MenuApiService {

  private path = "/Menu"

  constructor(private s1HttpClient: S1HttpClientService) { }

  ricerca(params: IMenuRicercaParams): Observable<IMenuPaginati> {

    return this.s1HttpClient.post(this.path + MenuAPI.ricerca, params).pipe(
      map(response => {
        return  { 
          menu: response.results,
          paginationInfo: response.paginationInfo
        } as IMenuPaginati
      })
    )

  }

  getDettaglio(id: string): Observable<any> {

    return this.s1HttpClient.get(`${this.path}/${id}`).pipe(
      map(response => {
        return response.item
      })
    )

  }

  create(menu: any): Observable<string> {

    return this.s1HttpClient.post(`${this.path}`, menu).pipe(
      map(response => {
        return response.item
      })
    )

  }

  update(menu: any): Observable<string> {

    return this.s1HttpClient.put(`${this.path}/${menu?.id}`, menu).pipe(
      map(response => {
        return response.item
      })
    )

  }

  istanza(): Observable<any> {

    return this.s1HttpClient.post(`${this.path}${MenuAPI.istanza}`, {}).pipe(
      map(response => {
        return response.item
      })
    )

  }

  clona(id: string): Observable<any> {

    return this.s1HttpClient.post(`${this.path}${MenuAPI.clona}/${id}`, {}).pipe(
      map(response => {
        return response.item
      })
    )

  }

  delete(id: string): Observable<boolean> {

    return this.s1HttpClient.delete(`${this.path}/${id}`).pipe(
      map(response => {
        return response.outcome.success
      })
    )

  }

  preferitiUtente():Observable<IPreferitiUtente>{
    return this.s1HttpClient.get(this.path + MenuAPI.preferiti+"Ricerca", null).pipe(
      map(response => {
        return  { 
          azioni:response.item
        } as IPreferitiUtente
      })
    )
  }

  eliminaPreferito(id:string):Observable<boolean>{
    return this.s1HttpClient.delete(this.path + MenuAPI.preferiti+id, null).pipe(
      map(response => {
        return response.outcome.success
      })
    )
  }

  impostaPreferito(id:string):Observable<boolean>{
    return this.s1HttpClient.post(this.path + MenuAPI.preferiti+id, null).pipe(
      map(response => {
        return response.outcome.success
      })
    )
  }

}
