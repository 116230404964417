import { Component, Input } from '@angular/core';
import { ICategoria } from '@app/shared/models/categoria';

@Component({
  selector: 'iwr-categoria-tag',
  templateUrl: './categoria-tag.component.html',
  styleUrls: ['./categoria-tag.component.scss']
})
export class CategoriaTagComponent {

  @Input() categoria: ICategoria;

}
