<s1-input-label [label]="label">
  <input #input
    type="text"
    class="form-control"
    [ngClass]="{'is-invalid' : !isValid }"
    [formControl]="control"
    [typeahead]="dataSource"
    (typeaheadLoading)="changeTypeaheadLoading($event)"
    (typeaheadNoResults)="changeTypeaheadNoResults($event)"
    (typeaheadOnSelect)="typeaheadOnSelect($event)"
    [typeaheadWaitMs]="300"
    [typeaheadOptionsLimit]="100"
    [typeaheadOptionField]="'codice'"
    [typeaheadItemTemplate]="customTemplate ? customTemplate : templateStandard"
    [readonly]="readonly" />
    <div class="input-group-append" *ngIf="withButtonList && !readonly">
      <button type="button" class="btn btn-outline-primary" (click)="standardSearchModal.open(config.standardSearch)">
          <i class="fa fa-list"></i>
      </button>
    </div>
</s1-input-label>

<!-- Modal Search -->
<s1-standard-search-modal #standardSearchModal (elementSelected)="elementSelectedOnStandardSearchModal($event)"></s1-standard-search-modal>

<!-- Autocomplete Standard list template-->
<ng-template #templateStandard let-item="item">
  <h5 *ngIf="item.id!=null && config.withId==null"> {{ item.id }} - {{item.codice }} - {{item.descrizione}}</h5>
  <h5 *ngIf="item.id!=null && config.withId==true"> {{ item.id }} - {{item.codice }} - {{item.descrizione}}</h5>
  <h5 *ngIf="item.id!=null && config.withId==false">  {{item.codice }} - {{item.descrizione}}</h5>
  <h5 *ngIf="item.id==null"> {{item.codice }} - {{item.descrizione}}</h5>
</ng-template>