import { Component, Input } from '@angular/core';
import { PrioritaTask } from '@app/shared/models/task';

@Component({
  selector: 'iwr-priorita-tag',
  templateUrl: './priorita-tag.component.html',
  styleUrls: ['./priorita-tag.component.scss']
})
export class PrioritaTagComponent {

  @Input() priorita: PrioritaTask

  styles: { [key in PrioritaTask] : string } = {
    [PrioritaTask.BASSA]: 'info',
    [PrioritaTask.MEDIA]: 'warning',
    [PrioritaTask.ALTA]: 'danger'
  }

}
