import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { S1ButtonType, S1Table, S1UIService } from '@app/s1';
import { AzioneMenuService, IAzioneMenu, IAzioneMenuRicercaParams } from '@app/shared/services/azione-menu.service';
import { MenuApiService } from '@app/shared/services/menu-api.service';
import { AzioneMenuModalComponent } from '../azione-menu-modal/azione-menu-modal.component';

@Component({
  selector: 'azioni-menu-ricerca',
  templateUrl: './azioni-menu-ricerca.component.html',
  styleUrls: ['./azioni-menu-ricerca.component.scss']
})


export class AzioniMenuRicercaComponent implements OnInit, AfterViewInit {

  @Input() ricercaAzioniPreferiti: boolean;

  @ViewChild('table') table: S1Table;
  @ViewChild('azioneModal') azioneModal: AzioneMenuModalComponent;

  s1ButtonType = S1ButtonType;

  searchForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private azioneMenuService: AzioneMenuService, 
    private ui: S1UIService, private menuService: MenuApiService) { 

    this.searchForm = this.formBuilder.group({
      'codice': null,
      'descrizione': null,
      'url': null
    });

  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.search();
  }

  search() {

    let params: IAzioneMenuRicercaParams = {
      page: this.table?.config.pagination.page.page,
      pageSize: +this.table?.config.pagination.page.itemsPerPage,
      codice: this.searchForm.controls.codice.value,
      descrizione: this.searchForm.controls.descrizione.value,
      filtraAzionePreferiti:this.ricercaAzioniPreferiti
    }

    this.azioneMenuService.ricerca(params).subscribe(azioniPaginate => {

      this.table.updateData(azioniPaginate.azioni, azioniPaginate.paginationInfo);

    })

  }

  new() {
    this.azioneModal.open(null);
  }

  edit(azione: IAzioneMenu) {
    this.azioneModal.open(azione);
  }

  askDelete(azione: IAzioneMenu) {

    this.ui.showDialogPopup("menuAzioni.messages.delete").then(result => {
      if (result.value) {
        this.delete(azione);
      }
    })

  }

  askImpostaPreferito(azione: IAzioneMenu) {

    this.ui.showDialogPopup("menuAzioni.messages.impostarePreferito").then(result => {
      if (result.value) {
        this.impostaPreferito(azione);
      }
    })

  }

  private delete(azioneToDelete: IAzioneMenu) {

    this.azioneMenuService.delete(azioneToDelete).subscribe(deleted => {

      if (deleted) {
        this.ui.showSuccessToast("menuAzioni.messages.deleted");
        this.search();
      }

    })

  }


  private impostaPreferito(azione : IAzioneMenu) {

    this.menuService.impostaPreferito(azione.id).subscribe(result => {

      /* if (result) {
        
        this.search();
      } */

    })

  }

}
