import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { S1ButtonType } from '../s1-button/s1-button.component';

@Component({
  selector: 's1-upload-section',
  templateUrl: './s1-upload-section.component.html',
  styleUrls: ['./s1-upload-section.component.scss']
})
export class S1UploadSection implements OnInit {

  @Input() formData: FormData;
  @Output() formDataChange = new EventEmitter<FormData>();
  @Input() disabled: boolean;
  @Input() parameterName: string;
  @Input() allowRecordAudio: boolean = false;

  s1ButtonType = S1ButtonType;

  files: File[] = [];

  constructor() { }

  ngOnInit(): void { 
    this.files = [];
  }

  addFiles(files: FileList) {

    for (let i = 0; i < files.length; i++) {
      this.files.push(files[i]);
      this.formData.append(this.parameterName ?? files[i].name, files[i]);
    }
    
    this.formDataChange.emit(this.formData);

  }

  addAudioFile(file: File) {


    this.files.push(file);
    this.formData.append(this.parameterName ?? file.name, file);
  }

  remove(file: File) {

    this.files = this.files.filter(f => f.name != file.name);

    this.formData.delete(file.name);
    this.formDataChange.emit(this.formData);

  }

}
