import { Injectable } from '@angular/core';
import { IS1RicercaStandardParams, IS1PaginatedResult, S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITipoAllegato } from '../models/allegato';

export interface IRicercaTipoAllegatoParams extends IS1RicercaStandardParams { 
  denominazione?: string
}

export interface ITipiAllegatoPaginati extends IS1PaginatedResult {
  tipi: ITipoAllegato[],
}

export enum TipoAllegatoAPI {
  ricerca = "/ricerca"
}

@Injectable({
  providedIn: 'root'
})
export class TipoAllegatoService {

  private path = "/TipoAllegato"

  constructor(private s1HttpClient: S1HttpClientService) { }

  ricerca(params: IRicercaTipoAllegatoParams): Observable<ITipiAllegatoPaginati> {

    return this.s1HttpClient.post(`${this.path}${TipoAllegatoAPI.ricerca}`, params).pipe(
      map(response => {
        return  { 
          tipi: response.results,
          paginationInfo: response.paginationInfo
        } as ITipiAllegatoPaginati
      })
    )

  }

  crea(params: ITipoAllegato): Observable<boolean> {
    return this.s1HttpClient.post(`${this.path}`, params).pipe(
      map(response => response.outcome.success)
    )
  }

  modifica(params: ITipoAllegato): Observable<boolean> {
    return this.s1HttpClient.put(`${this.path}/${params.id}`, params).pipe(
      map(response => response.outcome.success)
    )
  }

  dettaglio(id: string): Observable<ITipoAllegato> {
    return this.s1HttpClient.get(`${this.path}/${id}`).pipe(
      map(response => response.item)
    )
  }

  delete(id: string): Observable<boolean> {
    return this.s1HttpClient.delete(`${this.path}/${id}`).pipe(
      map(response => response.outcome.success)
    )
  }

}
