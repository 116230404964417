import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { S1HttpClientService, IS1TypedPaginatedResult } from './s1-http-client.service';
import { map } from 'rxjs/operators';

export enum CrudAPI {
  ricerca = "/ricerca"
}

interface IS1BasicObject {
  id?: string
}

/**
 *  Abstract generic service that provide fucntion to call the standard crud API
 * 
 *  - ricerca
 *  - crea
 *  - modifica
 *  - dettaglio
 *  - elimina
 * 
 */
@Injectable({
  providedIn: 'root'
})
export abstract class S1ApiCrudService<T extends IS1BasicObject, TParams> {

  constructor(protected s1HttpClient: S1HttpClientService, protected path: string) { }

  ricerca(params: TParams, path: string = this.path): Observable<IS1TypedPaginatedResult<T>> {

    return this.s1HttpClient.post(`${path}${CrudAPI.ricerca}`, params).pipe(
      map(response => {
        return  { 
          elements: response.results,
          paginationInfo: response.paginationInfo
        } as IS1TypedPaginatedResult<T>
      })
    )

  }

  crea(params: T): Observable<string> {
    return this.s1HttpClient.post(`${this.path}`, params).pipe(
      map(response => response.item)
    )
  }

  modifica(params: T): Observable<boolean> {
    return this.s1HttpClient.put(`${this.path}/${params.id}`, params).pipe(
      map(response => response.outcome.success)
    )
  }

  dettaglio(id: string): Observable<T> {
    return this.s1HttpClient.get(`${this.path}/${id}`).pipe(
      map(response => response.item)
    )
  }

  elimina(id: string): Observable<boolean> {
    return this.s1HttpClient.delete(`${this.path}/${id}`).pipe(
      map(response => response.outcome.success)
    )
  }

}
