import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { PrioritaTask } from '@app/shared/models/task';

@Component({
  selector: 'iwr-priorita-select',
  templateUrl: './priorita-select.component.html',
  styleUrls: ['./priorita-select.component.scss']
})
export class PrioritaSelectComponent {

  @Input() control: UntypedFormControl;
  @Input() readonly: boolean;

  priorita: IS1InputSelectItem[] = [];

  constructor() { }

  ngOnInit(): void { 
    this.loadItems();
  }

  loadItems() {

    this.priorita = [];

    Object.keys(PrioritaTask).forEach(priorita => {

      const option: IS1InputSelectItem = {
        code: priorita, label:PrioritaTask[priorita] 
      }

      this.priorita.push(option);

    })

    this.priorita = [... this.priorita];

  }

}
