import { Injectable } from '@angular/core';
import { IS1PaginatedResult, IS1RicercaStandardParams, S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { IAllegato } from '../models/allegato';
import { map } from 'rxjs/operators';
import { Entita } from './tipo-allegato-entita.service';

export interface IRicercaAllegatiParams extends IS1RicercaStandardParams { 
  id?: string,
  idTipoAllegato?: string,
  idEntita: string
}

export interface IAllegatiPaginati extends IS1PaginatedResult {
  allegati: IAllegato[]
}

export enum AllegatiAPI {
  ricerca = "/ricerca",
  download = "/download",
  upload = "/upload",
  elimina = "/elimina"
}

@Injectable({
  providedIn: 'root'
})
export class AllegatiService {

  private path = "/Allegati"

  basePath: { [key in Entita] : string } = {
    0: "",
    1: "/Litografia",
    2: "/AccessoVisitatore",
    3: "/Rullo"
  }

  constructor(private s1HttpClient: S1HttpClientService) { }

  fullPath(tipo: Entita) {
    return `${this.basePath[tipo]}${this.path}`
  }

  ricerca(params: IRicercaAllegatiParams, tipo: Entita): Observable<IAllegatiPaginati> {

    const specificParams: any = params;

    if (tipo == Entita.Visitatore) {
      specificParams.idVisitatore = specificParams.idEntita;
    } else if (tipo == Entita.Litografia) {
      specificParams.idLitografia = specificParams.idEntita;
    }

    return this.s1HttpClient.post(`${this.fullPath(tipo)}${AllegatiAPI.ricerca}`, params).pipe(
      map(response => {
        return  { 
          allegati: response.results,
          paginationInfo: response.paginationInfo
        } as IAllegatiPaginati
      })
    )

  }

  download(idAllegato: string, tipo: Entita): Observable<any> {
    return this.s1HttpClient.download(`${this.fullPath(tipo)}${AllegatiAPI.download}/${idAllegato}`).pipe(
      map(response => response)
    )
  }

  upload(formData: FormData, tipo: Entita): Observable<any> {
    return this.s1HttpClient.upload(`${this.fullPath(tipo)}${AllegatiAPI.upload}`, formData).pipe(
      map(response => response)
    )
  }

  elimina(idAllegato: string, tipo: Entita): Observable<boolean> {
    return this.s1HttpClient.delete(`${this.fullPath(tipo)}${AllegatiAPI.elimina}/${idAllegato}`).pipe(
      map(response => response.outcome.success)
    )
  }
}
