<s1-modal #modal [title]="title">

  <form [formGroup]="searchForm">
    <s1-input-text *ngIf="config?.withId" [control]="searchForm.controls.id" [label]="'s1.standardSearchModal.fields.id'"  [readonly]="idReadOnly"></s1-input-text>
    <div class="row">
      <s1-input-text [control]="searchForm.controls.codice" [label]="'s1.standardSearchModal.fields.codice'" [readonly]="codiceReadonly" class="col-6"></s1-input-text>
      <s1-input-switch   *ngIf="config?.visualizzaCodiceIniziaPer"  [label]="'s1.standardSearchModal.fields.iniziaPer'"   [control]="searchForm.controls.codiceIniziaPer"  class="col-4" [readonly]="codiceReadonly"></s1-input-switch>
    </div>
    
    <s1-input-text [control]="searchForm.controls.descrizione" [label]="'s1.standardSearchModal.fields.descrizione'" [readonly]="descrizioneReadonly"></s1-input-text>
    <s1-button [type]="s1ButtonType.Search" (onClick)="search()"></s1-button>
  </form>
  <div class="pt-3"></div>
  <s1-card>
    <s1-table #table (pageChanged)="search()">
      <thead header>
        <tr>
          <th> # </th>
          <th *ngIf="config?.withId">{{ 's1.standardSearchModal.table.columns.id' | translate }}</th>             
          <th>{{ 's1.standardSearchModal.table.columns.codice' | translate }}</th>
          <th>{{ 's1.standardSearchModal.table.columns.descrizione' | translate }}</th>                                    
        </tr>
      </thead>
      <tbody body>
        <tr *ngFor="let result of table.config.rows; let i = index" (click)="resultSelected(result)">
          <td>{{ i | s1TableGeneralIndex: table }}</td>
          <td *ngIf="config?.withId">{{ result.id }}</td>
          <td>{{ result.codice }}</td>
          <td class="text-left">{{ result.descrizione }}</td>
        </tr>
      </tbody>
    </s1-table>
  </s1-card>
  
</s1-modal>
