import { Injectable } from '@angular/core';
import { IS1SearchParams, S1ApiCrudService, S1HttpClientService } from '@app/s1';
import { INegozio } from '../models/negozio';

export interface IRicercaNegozioParams extends IS1SearchParams { 
  nome?: string
}

@Injectable({
  providedIn: 'root'
})
export class NegozioService extends S1ApiCrudService<INegozio, IRicercaNegozioParams> {

  constructor(s1HttpClient: S1HttpClientService) { 
    super(s1HttpClient, "/negozio");
  }

}
