import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { S1ButtonType, S1Table, S1UIService } from '@app/s1';
import { AzioneMenuService, IAzioneMenu, IFunzioneAzioneMenu, IFunzioneAzioneMenuRicercaParams } from '@app/shared/services/azione-menu.service';
import { FunzioneAzioneMenuModalComponent } from '../funzione-azione-menu-modal/funzione-azione-menu-modal.component';

@Component({
  selector: 'funzioni-azione-menu-section',
  templateUrl: './funzioni-azione-menu-section.component.html',
  styleUrls: ['./funzioni-azione-menu-section.component.scss']
})
export class FunzioniAzioneMenuSectionComponent implements OnInit {

  @Input() set azione(newValue: IAzioneMenu) {
    this._azione = newValue;

    if (this._azione?.id) {
      this.search();
    }

  }

  @ViewChild('table') table: S1Table;
  @ViewChild('funzioneModal') funzioneModal: FunzioneAzioneMenuModalComponent;

  _azione: IAzioneMenu;

  s1ButtonType = S1ButtonType;

  constructor(private azioneMenuService: AzioneMenuService, private ui: S1UIService) { }

  ngOnInit(): void { }

  search() {

    let params: IFunzioneAzioneMenuRicercaParams = {
      page: this.table?.config.pagination.page.page,
      pageSize: +this.table?.config.pagination.page.itemsPerPage
    }

    this.azioneMenuService.ricercaFunzioni(this._azione?.id, params).subscribe(funzioniPaginate => {

      this.table.updateData(funzioniPaginate.funzioni, funzioniPaginate.paginationInfo);

    })
    
  }

  new() {
    this.funzioneModal.open(this._azione, null);
  }

  edit(funzione: IFunzioneAzioneMenu) {
    this.funzioneModal.open(this._azione, funzione);
  }

  askDelete(funzione: IFunzioneAzioneMenu) {

    this.ui.showDialogPopup("funzioneAzioneMenu.messages.delete").then(result => {
      if (result.value) {
        this.delete(funzione);
      }
    })

  }

  private delete(funzioneToDelete: IFunzioneAzioneMenu) {

    this.azioneMenuService.deleteFunzione(this._azione?.id, funzioneToDelete).subscribe(deleted => {

      if (deleted) {
        this.ui.showSuccessToast("funzioneAzioneMenu.messages.deleted");
        this.search();
      }

    });

  }

}
