import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 's1-input-currency',
  templateUrl: './s1-input-currency.component.html',
  styleUrls: ['./s1-input-currency.component.scss']
})
export class S1InputCurrency implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() readonly: boolean = false;
  @Input() currency: string = '€';

  constructor() { }

  ngOnInit(): void {
  }

}
