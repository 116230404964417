import { Component, Input } from '@angular/core';
import { IOperatore } from '@app/shared/models/operatore';
import { IRendiconto } from '@app/shared/models/rendiconto';
import { ITurno } from '@app/shared/models/turno';
import { TipoDatoCellaReportConversione } from '../report-tasso-conversione-cell/report-tasso-conversione-cell.component';

@Component({
  selector: 'iwr-report-tasso-conversione-cell-total',
  templateUrl: './report-tasso-conversione-cell-total.component.html',
  styleUrls: ['./report-tasso-conversione-cell-total.component.scss']
})
export class ReportTassoConversioneCellTotalComponent {

  @Input() set rendiconti(newValue: IRendiconto[]) {
    this._rendiconti = newValue;
    this.mapValue();
  };

  @Input() set operatori(newValue: IOperatore[]) {
    this._operatori = newValue;
    this.mapValue();
  }

  @Input() set turni(newValue: ITurno[]) {
    this._turni = newValue;
    this.mapValue();
  }

  @Input() set tipoDato(newValue: TipoDatoCellaReportConversione) {
    this._tipoDato = newValue;
    this.mapValue();
  }

  _rendiconti: IRendiconto[] = [];
  _operatori: IOperatore[] = [];
  _turni: ITurno[] = [];
  _tipoDato: TipoDatoCellaReportConversione;

  tipoDatoCellaReportConversione = TipoDatoCellaReportConversione;

  valore: any = "-";

  mapValue() {

    if (!(this._rendiconti && this._tipoDato)) {
      return;
    }

    const rendicontiFiltered = this._rendiconti.filter(rendiconto => this._operatori.map(operatore => operatore.id).includes(rendiconto.operatore?.id) && this._turni.map(turno => turno.id).includes(rendiconto.turno?.id));

    if (rendicontiFiltered) {

      switch (this._tipoDato) {
        case TipoDatoCellaReportConversione.scontrini:
          this.valore = rendicontiFiltered.map(rendiconto => rendiconto.numeroScontrini).reduce((sum, val) => sum + val, 0);
          break;
        case TipoDatoCellaReportConversione.ingressi:
          this.valore = rendicontiFiltered.map(rendiconto => rendiconto.ingressi).reduce((sum, val) => sum + val, 0);
          break;
        case TipoDatoCellaReportConversione.tasso:
          this.valore = rendicontiFiltered.map(rendiconto => rendiconto.tassoDiConversione).reduce((sum, val) => sum + val, 0) / rendicontiFiltered.length;
          break;
      }

    }

  }

}
