<div class="row">
    <div class="col-2" *ngFor="let favourite of favourites; let i = index">
      <div class="card" (click)="goTo(favourite)">
        <div class="card-body text-center">
          <button type="button" class="btn btn-xs btn-danger btn-top-right" (click)="askDelete(favourite, $event)">
              <i class="fa fa-trash-alt"></i>
          </button>
          <h3>
              <i class="{{ favourite.icona }}"></i>
          </h3>
          <div>
            <strong>{{ favourite.descrizione }}</strong>
          </div>  
        </div>
      </div>
    </div>
  
    <div class="col-2">
      <div class="card" >
        <button type="button" class="btn btn-primary btn-favourite" (click)="addFavourite()">
          <div class="card-body text-center">
            <h3>
              <i class="fa fa-plus"></i>
            </h3>
              <strong>{{ 'favourites.add' | translate }}</strong>
          </div>
        </button>
      </div>
    </div>
  
  </div>
  
  <!-- Favourite Modal -->
  <azioni-preferiti-modal #favouriteModal (closed)="getFavourites()" (needsUpdate)="getFavourites()"> </azioni-preferiti-modal>