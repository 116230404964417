import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { S1ButtonType, S1Modal, S1ModalSizes, S1UIService } from '@app/s1';
import { AzioneMenuService, IAzioneMenu, IFunzioneAzioneMenu } from '@app/shared/services/azione-menu.service';

@Component({
  selector: 'funzione-azione-menu-modal',
  templateUrl: './funzione-azione-menu-modal.component.html',
  styleUrls: ['./funzione-azione-menu-modal.component.scss']
})
export class FunzioneAzioneMenuModalComponent implements OnInit {

  @Output() elementSaved = new EventEmitter<boolean>();

  @ViewChild('modal') modal: S1Modal;

  s1ButtonType = S1ButtonType;

  funzioneForm: UntypedFormGroup;

  azioneMenu: IAzioneMenu;
  funzioneAzioneMenu: IFunzioneAzioneMenu = {
    id: null,
    codice: null,
    descrizione: null
  };

  constructor(private formBuilder: UntypedFormBuilder, private azioneMenuService: AzioneMenuService, private ui: S1UIService) { 

    this.funzioneForm = this.formBuilder.group({
      'codice': null,
      'descrizione': null
    });

  }

  ngOnInit(): void { }

  open(azione: IAzioneMenu, funzione: IFunzioneAzioneMenu) {

    this.azioneMenu = azione;

    if (funzione) {
      this.funzioneAzioneMenu = funzione;
    } else {
      this.funzioneAzioneMenu.id = null;
    }

    this.funzioneForm.patchValue({
      'id': funzione?.id,
      'codice': funzione?.codice,
      'descrizione': funzione?.descrizione
    });

    this.modal.open(S1ModalSizes.LG);

  }

  close() {
    this.modal.close();
  }

  save() {

    this.funzioneAzioneMenu.codice = this.funzioneForm.controls.codice.value;
    this.funzioneAzioneMenu.descrizione = this.funzioneForm.controls.descrizione.value;

    let $api = this.funzioneAzioneMenu.id ? this.azioneMenuService.updateFunzione(this.azioneMenu?.id, this.funzioneAzioneMenu) : this.azioneMenuService.createFunzione(this.azioneMenu?.id, this.funzioneAzioneMenu);
    let message = this.funzioneAzioneMenu.id ? "updated" : "created";

    $api.subscribe(success => {

      if (success) {
        this.ui.showSuccessToast("funzioneAzioneMenu.messages." + message);
        this.elementSaved.emit(true);
        this.close();
      }

    })

  }

}
