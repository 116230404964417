import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { S1ButtonType, S1IconModal, S1Modal, S1ModalSizes, S1UIService } from '@app/s1';
import { AzioneMenuService, IAzioneMenu } from '@app/shared/services/azione-menu.service';

@Component({
  selector: 'azione-menu-modal',
  templateUrl: './azione-menu-modal.component.html',
  styleUrls: ['./azione-menu-modal.component.scss']
})
export class AzioneMenuModalComponent implements OnInit {

  menuAzioni: IAzioneMenu = {
    'id': null,
    'codice': null,
    'descrizione': null,
    'url': null
  };

  @Output() elementSaved = new EventEmitter<boolean>();

  @ViewChild('modal') modal: S1Modal;
  @ViewChild('iconModal') public iconModal: S1IconModal;

  menuAzioniForm: UntypedFormGroup;

  isEditMode = true;

  s1ButtonType = S1ButtonType;

  constructor(private formBuilder: UntypedFormBuilder, private azioneMenuService: AzioneMenuService, private ui: S1UIService) { 

    this.menuAzioniForm = this.formBuilder.group({
      'id': null,
      'codice': null,
      'descrizione': null,
      'url': null,
      'icona': null
    });

  }

  ngOnInit(): void { }

  open(azione: IAzioneMenu) {

    if (azione) {
      this.menuAzioni = azione;
    } else {
      this.menuAzioni.id = null;
    }

    this.menuAzioniForm.patchValue({
      'id': azione?.id,
      'codice': azione?.codice,
      'descrizione': azione?.descrizione,
      'url': azione?.url
    });

    this.modal.open(S1ModalSizes.LG);
  }

  close() {
    this.modal.close();
  }

  save() {

    this.menuAzioni.codice = this.menuAzioniForm.controls.codice.value;
    this.menuAzioni.descrizione = this.menuAzioniForm.controls.descrizione.value;
    this.menuAzioni.url = this.menuAzioniForm.controls.url.value;
    this.menuAzioni.icona = this.menuAzioni.icona ? this.menuAzioni.icona : '-';

    let $api = this.menuAzioni.id ? this.azioneMenuService.update(this.menuAzioni) : this.azioneMenuService.create(this.menuAzioni);
    let message = this.menuAzioni.id ? "updated" : "created";

    $api.subscribe(success => {

      if (success) {
        this.ui.showSuccessToast("menuAzioni.messages." + message);
        this.elementSaved.emit(true);
        this.close();
      }

    })

  }

  setIcon() {

    this.iconModal.load();

  }

  iconSelected(icon) {

    this.menuAzioni.icona = icon;
    this.iconModal.close();

  }

}
