import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { IS1Modal, S1Modal } from '../s1-modal/s1-modal.component';
import { S1ButtonType } from '../s1-button/s1-button.component';

@Component({
  selector: 's1-button-check-all',
  templateUrl: './s1-button-check-all.component.html',
  styleUrls: ['./s1-button-check-all.component.scss']
})
export class S1ButtonCheckAll implements OnInit, IS1Modal {

  @Input() title: string ='s1.buttonCheckAll.title';
  @Input() description: string = 's1.buttonCheckAll.description';
  @Input() checkedAll: boolean = false;
  @Input() uncheckedAll: boolean = true;

  @Output() updated = new EventEmitter<boolean>()

  @ViewChild('modal') modal: S1Modal;

  s1ButtonType = S1ButtonType

  constructor() { }

  ngOnInit(): void { }

  ask() {

    if (this.uncheckedAll) {
      this.check();
    } else if (this.checkedAll){
      this.uncheck();
    } else {
      this.modal.open();
    }
    
  }

  check() {
    this.updated.emit(true);
    this.close();
  }

  uncheck() {
    this.updated.emit(false);
    this.close();
  }

  close() {
    this.modal.close();
  }

}