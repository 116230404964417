import { Component, Input } from '@angular/core';
import { IOperatore } from '@app/shared/models/operatore';
import { IRendiconto } from '@app/shared/models/rendiconto';
import { ITurno } from '@app/shared/models/turno';

export enum TipoDatoCellaReportConversione {
  scontrini = "scontrini",
  ingressi = "ingressi",
  tasso = "tasso"
}

@Component({
  selector: 'iwr-report-tasso-conversione-cell',
  templateUrl: './report-tasso-conversione-cell.component.html',
  styleUrls: ['./report-tasso-conversione-cell.component.scss']
})
export class ReportTassoConversioneCellComponent {

  @Input() set rendiconti(newValue: IRendiconto[]) {
    this._rendiconti = newValue;
    this.mapValue();
  };

  @Input() set operatore(newValue: IOperatore) {
    this._operatore = newValue;
    this.mapValue();
  }

  @Input() set turno(newValue: ITurno) {
    this._turno = newValue;
    this.mapValue();
  }

  @Input() set tipoDato(newValue: TipoDatoCellaReportConversione) {
    this._tipoDato = newValue;
    this.mapValue();
  }

  _rendiconti: IRendiconto[] = [];
  _operatore: IOperatore;
  _turno: ITurno;
  _tipoDato: TipoDatoCellaReportConversione;

  tipoDatoCellaReportConversione = TipoDatoCellaReportConversione;

  valore: any = "-";

  mapValue() {

    if (!(this._rendiconti && this._operatore && this._turno && this._tipoDato)) {
      return;
    }

    const rendiconto = this._rendiconti.find(rendiconto => rendiconto.operatore?.id == this._operatore?.id && rendiconto.turno?.id == this._turno?.id);

    if (rendiconto) {

      switch (this._tipoDato) {
        case TipoDatoCellaReportConversione.scontrini:
          this.valore = rendiconto.numeroScontrini;
          break;
        case TipoDatoCellaReportConversione.ingressi:
          this.valore = rendiconto.ingressi;
          break;
        case TipoDatoCellaReportConversione.tasso:
          this.valore = rendiconto.tassoDiConversione;
          break;
      }

    }

  }

}
