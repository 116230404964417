import { Injectable } from '@angular/core';
import { IS1SearchParams, S1ApiCrudService, S1HttpClientService } from '@app/s1';
import { ITurno } from '../models/turno';

export interface IRicercaTurnoParams extends IS1SearchParams { 
  nome?: string 
}

@Injectable({
  providedIn: 'root'
})
export class TurnoService extends S1ApiCrudService<ITurno, IRicercaTurnoParams> {

  constructor(s1HttpClient: S1HttpClientService) { 
    super(s1HttpClient, "/turno");
  }
  
}
