import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1SearchParams } from '@app/s1';
import { INegoziRaggruppati, INegozio, TipoNegozio } from '@app/shared/models/negozio';
import { NegozioService } from '@app/shared/services/negozio.service';
import { Subscription, forkJoin } from 'rxjs';
import { TipoDatoCellaReportRendiconto } from '../report-rendiconti-cell/report-rendiconti-cell.component';
import { IRicercaRendicontoParams, RendicontoService } from '@app/shared/services/rendiconto.service';
import { IRendiconto } from '@app/shared/models/rendiconto';
import { BudgetService, IRicercaBudgetParams } from '@app/shared/services/budget.service';
import { IBudget } from '@app/shared/models/budget';
import { FasciaOrariaService, IRicercaFasciaOrariaParams } from '@app/shared/services/fascia-oraria.service';
import { IFasciaOraria } from '@app/shared/models/fascia-oraria';
import { UtilityService } from '@app/shared/services/utility.service';
import { ICampoExtraRendiconto } from '@app/shared/models/campo-extra-rendiconto';

@Component({
  selector: 'iwr-report-rendiconti-section',
  templateUrl: './report-rendiconti-section.component.html',
  styleUrls: ['./report-rendiconti-section.component.scss']
})
export class ReportRendicontiSectionComponent {

  @Input() dataControl: UntypedFormControl;

  data: Date = new Date();
  negozi: INegozio[] = [];
  negoziRaggruppati: INegoziRaggruppati[] = [];
  fasce: IFasciaOraria[] = [];
  budgets: IBudget[] = [];
  tipiDato: TipoDatoCellaReportRendiconto[] = [];
  rendiconti: IRendiconto[] = [];
  storici: Partial<IRendiconto>[] = [];
  campiExtra: Partial<ICampoExtraRendiconto>[] = [];

  dataSub: Subscription;

  constructor(private negozioService: NegozioService, private fasciaOrariaService: FasciaOrariaService, private rendicontoService: RendicontoService, private budgetService: BudgetService, private utilityService: UtilityService) {
    this.dataControl = new UntypedFormControl(new Date());
    this.tipiDato =  Object.keys(TipoDatoCellaReportRendiconto).map(tipo => TipoDatoCellaReportRendiconto[tipo]);
    this.load();

    this.dataSub = this.dataControl.valueChanges.subscribe(changed => {
      this.load();
    })

  }

  load() {

    const searchParams: IS1SearchParams = {
      page: 1,
      pageSize: 999,
      paginable: false
    }

    const searchFasceParams: IRicercaFasciaOrariaParams = {
      page: 1,
      pageSize: 999,
      paginable: false,
      sort: 'ordinamento'
    }

    const searchParamsWithDate: IRicercaRendicontoParams | IRicercaBudgetParams = {
      page: 1,
      pageSize: 999,
      paginable: false,
      giorno: this.dataControl.value ? this.utilityService.UTCDateFromLocal(this.dataControl.value) : null
    }

    const searchRendicontoParams: IRicercaRendicontoParams = {
      page: 1,
      pageSize: 999,
      paginable: false,
      giorno: this.dataControl.value ? this.utilityService.UTCDateFromLocal(this.dataControl.value) : null,
      mostraStoricoFasceSenzaRendiconto: true
    }

    const searchRendicontoStoricoParams: IRicercaRendicontoParams = {
      page: 1,
      pageSize: 999,
      paginable: false,
      giorno: this.dataControl.value ? this.utilityService.UTCDateFromLocal(this.dataControl.value) : null,
      mostraStoricoFasceSenzaRendiconto: true
    }

    forkJoin([
      this.negozioService.ricerca(searchParams),
      this.fasciaOrariaService.ricerca(searchFasceParams),
      this.budgetService.ricerca(searchParamsWithDate as IRicercaBudgetParams),
      this.rendicontoService.ricerca(searchRendicontoParams as IRicercaRendicontoParams),
      this.rendicontoService.ricercaStorico(searchRendicontoStoricoParams)
    ]).subscribe(([negozi, turni, budgets, rendiconti, storici]) => {

      this.negoziRaggruppati = [];
      this.negozi = negozi?.elements ?? [];
      this.fasce = turni?.elements ?? [];
      this.budgets = budgets?.elements ?? [];
      this.rendiconti = rendiconti?.elements ?? [];
      this.storici = storici?.elements ?? [];

      const campiOpRend = this.rendiconti.map(rendiconto => rendiconto.campiOperatore)
      const campiOp = campiOpRend.reduce((accumulator, value) => accumulator.concat(value), []);
      const campiExOp = campiOp.map(campoOp => campoOp.campiExtra)
      const campiEx = campiExOp.reduce((accumulator, value) => accumulator.concat(value), []);
      const campiExS = [...new Set(campiEx.map(campoEx => { return { codice: campoEx.codice, nome: campoEx.nome } as { codice: string, nome: string }}))]
      this.campiExtra = []

      campiExS.forEach(campoEx => {

        const found = this.campiExtra.find(campo => campo.codice == campoEx.codice && campo.nome == campoEx.nome)

        if (!found) {
          this.campiExtra.push(campoEx)
        }

      })

      Object.keys(TipoNegozio).forEach(tipo => {

        const gruppo: INegoziRaggruppati = {
          tipo: TipoNegozio[tipo],
          negozi: negozi.elements.filter(negozio => negozio.tipo == TipoNegozio[tipo]),
          expand: true
        }

        this.negoziRaggruppati.push(gruppo);
      })
      
    },
      (err) => { console.log("ERR fork: ", err)}
    );
  }

}
