import { Component, EventEmitter, Output, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export enum S1ButtonType {
  Normal = 'NORMAL',
  Back = 'BACK',
  New = 'NEW',
  Save = 'SAVE',
  Edit = 'EDIT',
  Delete = 'DELETE',
  Search = 'SEARCH',
  Link = 'LINK',
  Unlink = 'UNLINK',
  Add = 'ADD',
  Empty = 'EMPTY',
  Remove = 'REMOVE',
  Next = 'NEXT',
  Previous = 'PREVIOUS',
  Check = 'CHECK',
  Uncheck = 'UNCHECK',
  Cancel = 'CANCEL',
  Import = 'IMPORT',
  Send = 'SEND',
  Copy = 'COPY',
  View = 'VIEW',
  Download = "DOWNLOAD",
  Upload = "UPLOAD",
  Print = "PRINT",
  Reset = "RESET"
}

interface IS1Button {
  class: string;
  classOutline: string;
  icon: string;
  label: string;
}

@Component({
  selector: 's1-button',
  templateUrl: './s1-button.component.html',
  styleUrls: ['./s1-button.component.scss']
})

export class S1Button {

  @Input() type: S1ButtonType = S1ButtonType.Normal;
  @Input() label: string;
  @Input() icon: string;
  @Input() disabled: boolean = false;
  @Input() hidden: boolean = false;
  @Input() outline: boolean = false;
  @Input() onlyIcon: boolean = false;
  @Input() tooltipLabel: string;
  @Output() onClick = new EventEmitter<any>();

  s1ButtonType = S1ButtonType

  styles: { [key in S1ButtonType] : IS1Button } = {
    NORMAL: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon:'', label: ''  },
    BACK: { class: 'btn-primary', classOutline: 'btn-outline-warning', icon:'fa fa-chevron-left', label: 's1.button.back' },
    NEW: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon:'fa fa-plus', label: 's1.button.new' },
    SAVE : { class: 'btn-primary', classOutline: 'btn-outline-primary', icon:'fa fa-save', label: 's1.button.save' },
    EDIT : { class: 'btn-primary', classOutline: 'btn-outline-primary', icon:'fa fa-edit', label: 's1.button.edit' },
    DELETE : { class: 'btn-danger', classOutline: 'btn-outline-danger', icon:'fa fa-trash-alt', label: 's1.button.delete' },
    SEARCH : { class: 'btn-primary', classOutline: 'btn-outline-primary', icon:'fa fa-search', label: 's1.button.search' },
    LINK : { class: 'btn-success', classOutline: 'btn-outline-success', icon:'fa fa-link', label: 's1.button.link' },
    UNLINK : { class: 'btn-warning', classOutline: 'btn-outline-warning', icon:'fa fa-unlink', label: 's1.button.unlink' },
    ADD: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon:'fa fa-plus', label: 's1.button.add' },
    REMOVE : { class: 'btn-danger', classOutline: 'btn-outline-danger', icon:'fa fa-trash-alt', label: 's1.button.remove' },
    EMPTY : { class: 'btn-danger', classOutline: 'btn-outline-danger', icon:'fa fa-trash-alt', label: 's1.button.empty' },
    NEXT: { class: 'btn-primary', classOutline: 'btn-outline-primary', icon:'fa fa-chevron-right', label: 's1.button.next' },
    PREVIOUS: { class: 'btn-warning', classOutline: 'btn-outline-warning', icon:'fa fa-chevron-left', label: 's1.button.previous' },
    CHECK : { class: 'btn-success', classOutline: 'btn-outline-success', icon:'fa fa-check', label: 's1.button.check' },
    UNCHECK : { class: 'btn-danger', classOutline: 'btn-outline-danger', icon:'fa fa-times', label: 's1.button.uncheck' },
    CANCEL : { class: 'btn-secondary', classOutline: 'btn-outline-secondary', icon:'fa fa-undo-alt', label: 's1.button.cancel' },
    IMPORT : { class: 'btn-info', classOutline: 'btn-outline-info', icon:'fa fa-file-import', label: 's1.button.import' },
    SEND : { class: 'btn-primary', classOutline: 'btn-outline-primary', icon:'fa fa-paper-plane', label: 's1.button.send' },
    COPY : { class: 'btn-primary', classOutline: 'btn-outline-primary', icon:'fa fa-copy', label: 's1.button.copy' },
    VIEW : { class: 'btn-warning', classOutline: 'btn-outline-warning', icon:'fa fa-eye', label: 's1.button.view' },
    DOWNLOAD : { class: 'btn-primary', classOutline: 'btn-primary-warning', icon:'fa fa-download', label: 's1.button.download' },
    UPLOAD : { class: 'btn-primary', classOutline: 'btn-primary-warning', icon:'fa fa-upload', label: 's1.button.upload' },
    PRINT:   { class: 'btn-primary', classOutline: 'btn-primary-primary', icon:'fas fa-print', label: 's1.button.print' },
    RESET: { class: 'btn-warning', classOutline: 'btn-outline-warning', icon:'fas fa-undo-alt', label: 's1.button.reset' },
  };

  constructor(private translate: TranslateService) { }

  click() {

    this.onClick.emit();

  }

  getTooltip(): string {
    return this.translate.instant(this.tooltipLabel);
  }

}
