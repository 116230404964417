import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { OperatoreService, IRicercaOperatoreParams } from '@app/shared/services/operatore.service';

@Component({
  selector: 'iwr-operatore-select',
  templateUrl: './operatore-select.component.html',
  styleUrls: ['./operatore-select.component.scss']
})
export class OperatoreSelectComponent {

  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() readonly: boolean;

  operatori: IS1InputSelectItem[] = [];

  get selected() {
    return this.operatori?.find(op => op.code == this.control?.value);
  }

  constructor(private operatoreService: OperatoreService) {

    const params: IRicercaOperatoreParams = {
      page: 1,
      pageSize: 999,
      paginable: false
    }
  
    this.operatoreService.ricerca(params).subscribe(operatoriPaginati => {

      this.operatori = operatoriPaginati?.elements?.map(operatore => { 
        return { code: operatore.id, label: operatore.codice?.trim() + " - " + operatore.nomeCompleto?.trim() }  
      }) ?? [];
  
      if (this.operatori?.length == 1) {
        this.control?.patchValue(this.operatori[0].code);
      }
  
    })

  }

}
