import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';
import { UtenteService } from '@app/shared/services/utente.service';
import { IUtente } from '@app/shared';
import { IOperatore } from '@app/shared/models/operatore';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

    utente: IUtente;
    operatore: IOperatore;
  
    operatoreSub: Subscription;

    constructor(private settings: SettingsService, private router: Router, private utenteService: UtenteService) { 
        // Check token data
        if (localStorage.getItem("token") === null && sessionStorage.getItem("token") === null) {
            this.settings.sessionExpired();
            this.router.navigate(["/login"]);
        }

        this.utenteService.getCurrentUser().subscribe(utente => { 
            this.utente = utente;
            this.utenteService.checkOpenPopupOperatore();
          })
      
        this.operatoreSub = this.utenteService.operatore$.subscribe(operatore => {
            this.operatore = operatore;
        });

    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.operatoreSub.unsubscribe();
    }

}
