

<div class="card p-4">
  <div class="row">
    <s1-input-text [control]="operatoreForm.controls.operatoreName" [label]="'rendiconti.fields.operatore'" class="col" [readonly]="true"></s1-input-text>
    <div class="col pt-4">
      <ng-container *ngIf="!readonly">
        <s1-button *ngIf="!isMainOperatore" [type]="s1ButtonType.Search" (onClick)="askCodice()" [onlyIcon]="true"></s1-button>
        <s1-button *ngIf="!isMainOperatore && operatoreForm.controls.operatoreName.value" [type]="s1ButtonType.Delete" (onClick)="askDelete()" [onlyIcon]="true" class="pl-2"></s1-button>
      </ng-container>
    </div>
    <div class="col"></div>
    <div class="col"></div>
    <div class="col"></div>
  </div>
  <div class="row">
    <s1-input-mask [control]="operatoreForm.controls.numeroBanconoteIngaggioOperatore10" [label]="'rendiconti.fields.numeroBanconoteIngaggioOperatore10'" class="col" mask="separator.0" thousandSeparator="." decimalMarker="," [allowNegativeNumbers]="false" [customClass]="'text-right'" [readonly]="readonly"></s1-input-mask>
    <s1-input-mask [control]="operatoreForm.controls.numeroBanconoteRitirateOperatore10" [label]="'rendiconti.fields.numeroBanconoteRitirateOperatore10'" class="col" mask="separator.0" thousandSeparator="." decimalMarker="," [allowNegativeNumbers]="false" [customClass]="'text-right'" [readonly]="readonly"></s1-input-mask>
    <s1-input-mask [control]="operatoreForm.controls.tassoDiConversioneBanconoteOperatore10Percent" [label]="'rendiconti.fields.tassoDiConversioneVoucher10Operatore'" class="col" mask="separator.2" thousandSeparator="." decimalMarker="," [allowNegativeNumbers]="false" [customClass]="'text-right'" [readonly]="true" [append]="'%'"></s1-input-mask>
    <div class="col"></div>
    <div class="col"></div>
  </div>
  <div class="row">
    <s1-input-mask [control]="operatoreForm.controls.numeroBanconoteIngaggioOperatore15" [label]="'rendiconti.fields.numeroBanconoteIngaggioOperatore15'" class="col" mask="separator.0" thousandSeparator="." decimalMarker="," [allowNegativeNumbers]="false" [customClass]="'text-right'" [readonly]="readonly"></s1-input-mask>
    <s1-input-mask [control]="operatoreForm.controls.numeroBanconoteRitirateOperatore15" [label]="'rendiconti.fields.numeroBanconoteRitirateOperatore15'" class="col" mask="separator.0" thousandSeparator="." decimalMarker="," [allowNegativeNumbers]="false" [customClass]="'text-right'" [readonly]="readonly"></s1-input-mask>
    <s1-input-mask [control]="operatoreForm.controls.tassoDiConversioneBanconoteOperatore15Percent" [label]="'rendiconti.fields.tassoDiConversioneVoucher15Operatore'" class="col" mask="separator.2" thousandSeparator="." decimalMarker="," [allowNegativeNumbers]="false" [customClass]="'text-right'" [readonly]="true" [append]="'%'"></s1-input-mask>
    <div class="col"></div>
    <div class="col"></div>
  </div>
  <div class="row" *ngIf="_campiOperatore.campiExtra?.length">
    <ng-container *ngFor="let _ of _campiOperatore.campiExtra; let i = index">
      <iwr-input-campo-extra-rendiconto class="col" [(campoValorizzato)]="_campiOperatore.campiExtra[i]" (campoValorizzatoChange)="updateCampiExtra()" [readonly]="readonly"></iwr-input-campo-extra-rendiconto>
      <div class="col-12" *ngIf="i > 0 && i % 4 == 0"></div>
    </ng-container>
    <div class="col" *ngIf="_campiOperatore.campiExtra?.length % 5 == 1"></div>
    <div class="col" *ngIf="_campiOperatore.campiExtra?.length % 5 <= 2"></div>
    <div class="col" *ngIf="_campiOperatore.campiExtra?.length % 5 <= 3"></div>
    <div class="col" *ngIf="_campiOperatore.campiExtra?.length % 5 <= 4"></div>
  </div>
</div>

