import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 's1ServerDateTime'
})
export class S1ServerDateTimePipe extends DatePipe implements PipeTransform  {

  transform(date: Date, ...args: unknown[]): null
  transform(date: Date, ...args: unknown[]): string {

    if (!date) {
      return null
    } 

    return super.transform(date, 'yyyy-MM-ddTHH:mm:ss.sssZ')

  }

}
