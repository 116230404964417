<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.operatore">{{ this.valore }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.storico" class="d-flex {{ this.valore == '-' ? 'justify-content-center' : 'justify-content-end' }}">{{ this.valore == "-" ? this.valore : (this.valore | s1Currency) }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.incasso">{{ this.valore == "-" ? this.valore : (this.valore | s1Currency) }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.ingressi">{{ this.valore == "-" ? this.valore : (this.valore | number: '1.0-2') }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.scontrini">{{ this.valore == "-" ? this.valore : (this.valore | number: '1.0-2') }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.tassoDiConversione">{{ this.valore == "-" ? this.valore : (this.valore | percent) }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.voucherDistribuiti10">{{ this.valore == "-" ? this.valore : (this.valore | number: '1.0-2') }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.voucherRicevuti10">{{ this.valore == "-" ? this.valore : (this.valore | number: '1.0-2') }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.tassoVoucher10">{{ this.valore == "-" ? this.valore : (this.valore | percent) }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.voucherDistribuiti15">{{ this.valore == "-" ? this.valore : (this.valore | number: '1.0-2') }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.voucherRicevuti15">{{ this.valore == "-" ? this.valore : (this.valore | number: '1.0-2') }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.tassoVoucher15">{{ this.valore == "-" ? this.valore : (this.valore | percent) }}</span>