<s1-modal #modal [title]="'policyModal.title'">
  <s1-table #table [onlinePagination]="false">
    <thead header>
      <tr>
        <th>#</th>
        <th>{{ 'policyModal.table.columns.codice' | translate }}</th>
        <th>{{ 'policyModal.table.columns.descrizione' | translate }}</th>
        <th></th>                                    
      </tr>
    </thead>
    <tbody body>
      <tr *ngFor="let funzione of table.config.rows; let i = index">
        <td [ngClass]="funzione.funzioneAttiva ? 'text-success' : 'text-danger'">
          <i class="fa" [ngClass]="funzione.funzioneAttiva ? 'fa-unlock' : 'fa-lock'"></i>
        </td>
        <td>{{ funzione.codiceFunzione }}</td>
        <td>{{ funzione.descrizioneFunzione | translate }}</td>
        <td>
          <label class="switch m-0">
            <input type="checkbox" [(ngModel)]="funzione.funzioneAttiva"/>
            <span></span>
          </label>
        </td>
      </tr>
    </tbody>
  </s1-table>
  <div class="row">
    <div class="col-12 text-center mt-3">
      <s1-button [type]="s1ButtonType.Edit" (onClick)="close()"></s1-button>
    </div>
  </div>
</s1-modal>
