import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';

import { menu } from './menu';
import { routes } from './routes';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes, {}),
        PagesModule
    ],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    constructor(public menuService: MenuService, tr: TranslatorService) {

        const sessionMenu = localStorage.getItem('menu') == 'undefined' ? null : localStorage.getItem('menu');

        const usermenu = sessionMenu ? JSON.parse(sessionMenu) : menu;

        menuService.addMenu(usermenu);
    }
}
