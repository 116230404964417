import { Injectable } from '@angular/core';
import { IS1RicercaStandardParams, IS1PaginatedResult, S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITipoAllegatoEntita } from '../models/allegato';

export interface IRicercaTipoAllegatoEntitaParams extends IS1RicercaStandardParams {
  entita?:number;
  idTipoAllegato?:string;
 }
export enum Entita {
  None = 0,
  Litografia=1,
  Visitatore=2,
  Rullo=3
}

export interface IRicercaTipoAllegatoEntitaParams extends IS1RicercaStandardParams { 
  entita?: number
}

export interface ITipiAllegatoEntitaPaginati extends IS1PaginatedResult {
  tipi: ITipoAllegatoEntita[],
}

export enum TipoAllegatoEntitaAPI {
  ricerca = "/ricerca"
}

@Injectable({
  providedIn: 'root'
})
export class TipoAllegatoEntitaService {

  private path = "/TipoAllegatoEntita"

  constructor(private s1HttpClient: S1HttpClientService) { }

  ricerca(params: IRicercaTipoAllegatoEntitaParams): Observable<ITipiAllegatoEntitaPaginati> {

    return this.s1HttpClient.post(`${this.path}${TipoAllegatoEntitaAPI.ricerca}`, params).pipe(
      map(response => {
        return  { 
          tipi: response.results,
          paginationInfo: response.paginationInfo
        } as ITipiAllegatoEntitaPaginati
      })
    )

  }

  crea(params: ITipoAllegatoEntita): Observable<boolean> {
    return this.s1HttpClient.post(`${this.path}`, params).pipe(
      map(response => response.outcome.success)
    )
  }

  modifica(params: ITipoAllegatoEntita): Observable<boolean> {
    return this.s1HttpClient.put(`${this.path}/${params.id}`, params).pipe(
      map(response => response.outcome.success)
    )
  }

  dettaglio(id: string): Observable<ITipoAllegatoEntita> {
    return this.s1HttpClient.get(`${this.path}/${id}`).pipe(
      map(response => response.item)
    )
  }

  delete(id: string): Observable<boolean> {
    return this.s1HttpClient.delete(`${this.path}/${id}`).pipe(
      map(response => response.outcome.success)
    )
  }

}
