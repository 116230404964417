import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ICampoExtraRendicontoValorizzato } from '@app/shared/models/campo-extra-rendiconto';

@Component({
  selector: 'iwr-input-campo-extra-rendiconto',
  templateUrl: './input-campo-extra-rendiconto.component.html',
  styleUrls: ['./input-campo-extra-rendiconto.component.scss']
})
export class InputCampoExtraRendicontoComponent {

  @Input() set campoValorizzato(newValue: ICampoExtraRendicontoValorizzato) {
    this._campoValorizzato = newValue;
    this.control.patchValue(Number(String(newValue?.valore).replace(",",".")));
  }

  @Input() readonly: boolean = false;

  @Output() campoValorizzatoChange = new EventEmitter<ICampoExtraRendicontoValorizzato>();

  control = new UntypedFormControl();

  _campoValorizzato: ICampoExtraRendicontoValorizzato;

  constructor() {
    this.control.valueChanges.subscribe(val => {

      this._campoValorizzato.valore = val;
      this.campoValorizzatoChange.emit(this._campoValorizzato);

    })
  }

}
