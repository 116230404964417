import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { IRuoloMenu, IRuoloRicercaParams, RuoloService } from '@app/shared/services/ruolo.service';

@Component({
  selector: 'ruoli-utente-select',
  templateUrl: './ruoli-utente-select.component.html',
  styleUrls: ['./ruoli-utente-select.component.scss']
})
export class RuoliUtenteSelectComponent {
  
  @Input() control: UntypedFormControl;
  @Input() label: string;

  ruoli: IS1InputSelectItem[];

  constructor(private ruoloService: RuoloService) {

    const params: IRuoloRicercaParams = {
      page: 1,
      pageSize: 999,
      paginable: false
    }
  
    this.ruoloService.ricerca(params).subscribe(ruoliPaginati => {

      this.ruoli = ruoliPaginati?.ruoli?.map(ruolo => { 
        return { code: ruolo.id, label: ruolo.codice?.trim() }  
      }) ?? [];

      if (this.ruoli?.length == 1) {
        this.control?.patchValue(this.ruoli[0]?.code);
      }
  
    })

  }

}
