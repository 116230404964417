import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { S1ButtonType, S1PopUpType, S1UIService } from '@app/s1';
import { ICampoExtraRendicontoValorizzato } from '@app/shared/models/campo-extra-rendiconto';
import { IOperatore } from '@app/shared/models/operatore';
import { ICampiOperatoreRendiconto } from '@app/shared/models/rendiconto';
import { CampoExtraRendicontoService, IRicercaCampoExtraRendicontoParams } from '@app/shared/services/campo-extra-rendiconto.service';
import { IRicercaOperatoreParams, OperatoreService } from '@app/shared/services/operatore.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'iwr-campi-operatore-rendiconto-section',
  templateUrl: './campi-operatore-rendiconto-section.component.html',
  styleUrls: ['./campi-operatore-rendiconto-section.component.scss']
})
export class CampiOperatoreRendicontoSectionComponent {

  @Input() isMainOperatore: boolean = false;
  @Input() set campiOperatore(newValue: ICampiOperatoreRendiconto) {
    this._campiOperatore = newValue;
    this.mapForm();
  };

  @Input() readonly: boolean = false;

  @Output() campiOperatoreChange = new EventEmitter<ICampiOperatoreRendiconto>();
  @Output() delete = new EventEmitter<boolean>();

  operatoreForm: UntypedFormGroup;

  _campiOperatore: ICampiOperatoreRendiconto;

  s1ButtonType = S1ButtonType;

  campiExtra: ICampoExtraRendicontoValorizzato[] = [];

  constructor(private formBuilder: UntypedFormBuilder, private operatoreService: OperatoreService, private campoExtraRendicontoService: CampoExtraRendicontoService, private ui: S1UIService) {
    this.operatoreForm = this.formBuilder.group({ 
      'operatore': [null, null],
      'operatoreName': [null, null],
      'numeroBanconoteIngaggioOperatore10': [null, null],
      'numeroBanconoteRitirateOperatore10': [null, null],
      'tassoDiConversioneBanconoteOperatore10': [null, null],
      'tassoDiConversioneBanconoteOperatore10Percent': [null, null],
      'numeroBanconoteIngaggioOperatore15': [null, null],
      'numeroBanconoteRitirateOperatore15': [null, null],
      'tassoDiConversioneBanconoteOperatore15': [null, null],
      'tassoDiConversioneBanconoteOperatore15Percent': [null, null],
      'numeroPezziIntimoOperatore': [null, null],
      'numeroScontriniIntimoOperatore': [null, null]
    })

    this.operatoreForm.valueChanges.subscribe(op => {

      const operatore: Partial<IOperatore> = { id: this.operatoreForm.controls.operatore.value, nomeCompleto: this.operatoreForm.controls.operatoreName.value };

      this._campiOperatore.operatore = operatore;
      this._campiOperatore.numeroBanconoteIngaggioOperatore10 = this.operatoreForm.controls.numeroBanconoteIngaggioOperatore10.value;
      this._campiOperatore.numeroBanconoteRitirateOperatore10 = this.operatoreForm.controls.numeroBanconoteRitirateOperatore10.value;
      this._campiOperatore.tassoDiConversioneVoucherOperatore10 = this.operatoreForm.controls.tassoDiConversioneBanconoteOperatore10.value;
      this._campiOperatore.numeroBanconoteIngaggioOperatore15 = this.operatoreForm.controls.numeroBanconoteIngaggioOperatore15.value;
      this._campiOperatore.numeroBanconoteRitirateOperatore15 = this.operatoreForm.controls.numeroBanconoteRitirateOperatore15.value;
      this._campiOperatore.tassoDiConversioneVoucherOperatore15 = this.operatoreForm.controls.tassoDiConversioneBanconoteOperatore15.value;
      this._campiOperatore.numeroPezziIntimoOperatore = this.operatoreForm.controls.numeroPezziIntimoOperatore.value;
      this._campiOperatore.numeroScontriniIntimoOperatore = this.operatoreForm.controls.numeroScontriniIntimoOperatore.value;

      this.campiOperatoreChange.emit(this._campiOperatore);

    })

    combineLatest([
      this.operatoreForm.controls.numeroBanconoteIngaggioOperatore10.valueChanges,
      this.operatoreForm.controls.numeroBanconoteRitirateOperatore10.valueChanges
    ]).subscribe(([distribuiti, ricevuti]) => {

      this.operatoreForm.controls.tassoDiConversioneBanconoteOperatore10.patchValue(ricevuti/distribuiti);
      this.operatoreForm.controls.tassoDiConversioneBanconoteOperatore10Percent.patchValue(ricevuti/distribuiti*100);

    })

    combineLatest([
      this.operatoreForm.controls.numeroBanconoteIngaggioOperatore15.valueChanges,
      this.operatoreForm.controls.numeroBanconoteRitirateOperatore15.valueChanges
    ]).subscribe(([distribuiti, ricevuti]) => {

      this.operatoreForm.controls.tassoDiConversioneBanconoteOperatore15.patchValue(ricevuti/distribuiti);
      this.operatoreForm.controls.tassoDiConversioneBanconoteOperatore15Percent.patchValue(ricevuti/distribuiti*100);
//
    })

  }

  private mapForm() {
    this.operatoreForm.patchValue({
      'operatore': this._campiOperatore?.operatore?.id,
      'operatoreName': this._campiOperatore?.operatore?.nomeCompleto,
      'numeroBanconoteIngaggioOperatore10': this._campiOperatore?.numeroBanconoteIngaggioOperatore10,
      'numeroBanconoteRitirateOperatore10': this._campiOperatore?.numeroBanconoteRitirateOperatore10,
      'tassoDiConversioneBanconoteOperatore10': this._campiOperatore?.tassoDiConversioneVoucherOperatore10,
      'tassoDiConversioneBanconoteOperatore10Percent': this._campiOperatore?.tassoDiConversioneVoucherOperatore10 * 100,
      'numeroBanconoteIngaggioOperatore15': this._campiOperatore?.numeroBanconoteIngaggioOperatore15,
      'numeroBanconoteRitirateOperatore15': this._campiOperatore?.numeroBanconoteRitirateOperatore15,
      'tassoDiConversioneBanconoteOperatore15': this._campiOperatore?.tassoDiConversioneVoucherOperatore15,
      'tassoDiConversioneBanconoteOperatore15Percent': this._campiOperatore?.tassoDiConversioneVoucherOperatore15 * 100,
      'numeroPezziIntimoOperatore': this._campiOperatore?.numeroPezziIntimoOperatore,
      'numeroScontriniIntimoOperatore': this._campiOperatore?.numeroScontriniIntimoOperatore
    })

    this.searchCampiExtra();

  }

  searchCampiExtra() {

    let params: IRicercaCampoExtraRendicontoParams = {
      page: 1,
      pageSize: 999,
      paginable: false,
      sort: "ordinamento asc"
    }

    this.campoExtraRendicontoService.ricerca(params).subscribe(campiExtraRendicontoPaginati => {

      if (!this._campiOperatore.campiExtra) {
        this._campiOperatore.campiExtra = [];
      }

      const codCampiGiaValorizzati = this._campiOperatore.campiExtra.map(campo => campo.codice);

      // Dalla lista dei campi extra attivi prendo solo quelli che non sono già stati valorizzati
      this.campiExtra = campiExtraRendicontoPaginati.elements.filter(campo => !codCampiGiaValorizzati.includes(campo.codice)).map(campoNuovo => {

        return { 
          codice: campoNuovo.codice,
          nome: campoNuovo.nome,
          valore: null
        } as ICampoExtraRendicontoValorizzato

      })

      this._campiOperatore.campiExtra = this._campiOperatore.campiExtra.concat(this.campiExtra);

    })

  }

  askDelete() {

    this.ui.showDialogPopup("rendiconti.messages.askDeleteCampiOperatore").then(result => {
      if (result.value) {
        this.delete.emit(true);
      }
    })

  }

  askCodice() {

    this.ui.showInputPopup("s1.swal.attention", "operatori.messages.askCode", "operatori.fields.codice").then(resp => {

      const codOperatore = resp.value;

      if (codOperatore && codOperatore != '') { 
        this.searchOperatore(codOperatore);
      }

    })

  }

  searchOperatore(codice: string) {

    this.operatoreService.controllaCodice(codice).subscribe(operatore => {
        
      if (operatore){
        this.operatoreForm.patchValue({
          'operatore': operatore.id,
          'operatoreName': `${operatore.codice} - ${operatore.nomeCompleto}`
        })
      } else {
        this.ui.showPopup(S1PopUpType.Error, "s1.swal.error", "operatori.messages.noCode");
      }
  
    }, _ => this.ui.showPopup(S1PopUpType.Error, "s1.swal.error", "operatori.messages.noCode"))

  }

  updateCampiExtra() {

    this.campiOperatoreChange.emit(this._campiOperatore);

  }

}
