import { Injectable } from '@angular/core';
import { IS1SearchParams, S1ApiCrudService, S1HttpClientService } from '@app/s1';
import { ICategoria } from '../models/categoria';

export interface IRicercaCategoriaParams extends IS1SearchParams, Partial<ICategoria> { }

@Injectable({
  providedIn: 'root'
})
export class CategoriaService extends S1ApiCrudService<ICategoria, IRicercaCategoriaParams> {

  constructor(s1HttpClient: S1HttpClientService) { 
    super(s1HttpClient, "/categoria");
  }
  
}
