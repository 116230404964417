import { IAllegatoTask } from "./allegato-task";
import { ICategoria } from "./categoria";
import { IS1BasicEntity } from "./entita-base";
import { INegozio } from "./negozio";
import { StatoTaskNegozio } from "./task-negozio";
import { IUtente } from "./utente";

export interface ITask extends IS1BasicEntity {
  idProgressive?: number,
  descrizione: string,
  oggetto: string,
  priorita: PrioritaTask,
  categorie: Partial<ICategoria>[],
  negozi: Partial<INegozio>[],
  utenteCreatoDa?: Partial<IUtente>
  statoGlobale: StatoTaskNegozio,
  allegati?: IAllegatoTask[],
  giornoFine?: Date,
  giornoInizio?: Date,
  dataCreazione?: Date,
  dataUltimaModifica?: Date,
}

export enum PrioritaTask {
  BASSA = "BASSA",
  MEDIA = "MEDIA",
  ALTA = "ALTA"
}