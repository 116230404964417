import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { S1Modal, S1ModalSizes } from '../s1-modal/s1-modal.component';

@Component({
  selector: 's1-icon-modal',
  templateUrl: './s1-icon-modal.component.html',
  styleUrls: ['./s1-icon-modal.component.scss']
})
export class S1IconModal implements OnInit {

  @Output() selected = new EventEmitter<any>();

  @ViewChild('modal') modal: S1Modal;

  icons: any[] = [];

  constructor() { 

    this.icons = [
      "fas fa-home",
      "fas fa-bars",
      "fa fa-clipboard",
      "fa fa-code",
      "fa fa-cloud",
      "fa fa-comment",
      "fa fa-database",
      "fa fa-ellipsis-v",
      "fa fa-file",
      "fa fa-file-alt",
      "fa fa-file-pdf",
      "fa fa-folder",
      "fa fa-hdd",
      "fa fa-info-circle",
      "fa fa-envelope",
      "fa fa-barcode",
      "fa fa-filter",
      "fa fa-handshake",
      "fa fa-heart",
      "fa fa-id-badge",
      "fa fa-id-card",
      "fa fa-image",
      "fa fa-inbox",
      "fa fa-industry",
      "fa fa-key",
      "fa fa-keyboard",
      "fa fa-language",
      "fa fa-laptop",
      "fa fa-link",
      "fa fa-unlink",
      "fa fa-list",
      "fa fa-print",
      "fa fa-download",
      "fa fa-fax",
      "fa fa-file-excel",
      "fa fa-globe",
      "fa fa-lock",
      "fa fa-lock-open",
      "fa fa-map",
      "fa fa-microphone",
      "fa fa-microphone-slash",
      "fa fa-mobile-alt",
      "fa fa-money-bill",
      "fa fa-pallet",
      "fa fa-paper-plane",
      "fa fa-paperclip",
      "fa fa-people-carry",
      "fa fa-percent",
      "fa fa-phone",
      "fa fa-piggy-bank",
      "fa fa-plane",
      "fab fa-react",
      "fa fa-random",
      "fab fa-readme",
      "fa fa-receipt",
      "fa fa-recycle",
      "fa fa-road",
      "fa fa-ruler",
      "fa fa-server",
      "fa fa-ship",
      "fa fa-shipping-fast",
      "fa fa-sitemap",
      "fa fa-star",
      "fa fa-sticky-note",
      "fa fa-subway",
      "fa fa-sync",
      "fa fa-table",
      "fa fa-tablet-alt",
      "fa fa-tag",
      "fa fa-train",
      "fa fa-trash-alt",
      "fa fa-tree",
      "fa fa-truck",
      "fa fa-truck-loading",
      "fa fa-upload",
      "fa fa-user",
      "fa fa-user-cog",
      "fa fa-user-edit",
      "fa fa-user-friends",
      "fa fa-user-lock",
      "fa fa-user-shield",
      "fa fa-user-tie",
      "fa fa-users",
      "fa fa-warehouse",
      "fa fa-wrench"
    ];

  }

  ngOnInit(): void { }

  load() {
    this.modal.open(S1ModalSizes.LG);
  }

  iconSelected(icon) {
    this.selected.emit(icon);
  }

  close() {
    this.modal.close();
  }

}
