import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { StatoTaskNegozio } from '@app/shared/models/task-negozio';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'iwr-stato-task-select',
  templateUrl: './stato-task-select.component.html',
  styleUrls: ['./stato-task-select.component.scss']
})
export class StatoTaskSelectComponent {

  @Input() control: UntypedFormControl;
  @Input() readonly: boolean;

  stati: IS1InputSelectItem[] = [];

  constructor(private translate: TranslateService) { }

  ngOnInit(): void { 
    this.loadItems();
  }

  loadItems() {

    this.stati = [];

    Object.keys(StatoTaskNegozio).forEach(stato => {

      const option: IS1InputSelectItem = {
        code: stato, label: this.translate.instant(`stato.${StatoTaskNegozio[stato].toLowerCase()}`)
      }

      this.stati.push(option);

    })

    this.stati = [... this.stati];

  }

}
