import { Component, Input } from '@angular/core';
import { IFasciaOraria } from '@app/shared/models/fascia-oraria';
import { INegozio } from '@app/shared/models/negozio';
import { IRendiconto } from '@app/shared/models/rendiconto';

export enum TipoDatoCellaReportRendiconto {
  storico = "storico",
  operatore = "operatore",
  incasso = "incasso",
  ingressi = "ingressi",
  scontrini = "scontrini",
  tassoDiConversione = "tassoDiConversione", 
  voucherDistribuiti10 = "voucherDistribuiti10",
  voucherRicevuti10 = "voucherRicevuti10",
  tassoVoucher10 = "tassoVoucher10",
  voucherDistribuiti15 = "voucherDistribuiti15",
  voucherRicevuti15 = "voucherRicevuti15",
  tassoVoucher15 = "tassoVoucher15"
}

@Component({
  selector: 'iwr-report-rendiconti-cell',
  templateUrl: './report-rendiconti-cell.component.html',
  styleUrls: ['./report-rendiconti-cell.component.scss']
})
export class ReportRendicontiCellComponent {

  @Input() set rendiconti(newValue: IRendiconto[]) {
    this._rendiconti = newValue;
    this.mapValue();
  };

  @Input() set negozio(newValue: INegozio) {
    this._negozio = newValue;
    this.mapValue();
  }

  @Input() set fascia(newValue: IFasciaOraria) {
    this._fascia = newValue;
    this.mapValue();
  }

  @Input() set tipoDato(newValue: TipoDatoCellaReportRendiconto) {
    this._tipoDato = newValue;
    this.mapValue();
  }

  _rendiconti: IRendiconto[] = [];
  _negozio: INegozio;
  _fascia: IFasciaOraria;
  _tipoDato: TipoDatoCellaReportRendiconto;

  valore: any = "-";
  tipoDatoCellaReportRendiconto = TipoDatoCellaReportRendiconto;

  idRendiconto: string;
  rendiconto: IRendiconto;

  mapValue() {

    if (!(this._rendiconti && this._negozio && this._fascia && this._tipoDato)) {
      return;
    }

    this.rendiconto = this._rendiconti.find(rendiconto => rendiconto.negozio?.id == this._negozio?.id && rendiconto.fasciaOraria?.id == this._fascia?.id);
    this.idRendiconto = this.rendiconto?.id;

    if (this.rendiconto) {

      switch (this._tipoDato) {
        case TipoDatoCellaReportRendiconto.storico:
          this.valore = this.rendiconto.storico?.incasso ?? "-";
          break;
        case TipoDatoCellaReportRendiconto.incasso:
          this.valore = this.rendiconto.incasso ?? "-";
          break;
        case TipoDatoCellaReportRendiconto.ingressi:
          this.valore = this.rendiconto.ingressi ?? "-";
          break;
        case TipoDatoCellaReportRendiconto.scontrini:
          this.valore = this.rendiconto.numeroScontrini ?? "-";
          break;
        case TipoDatoCellaReportRendiconto.tassoDiConversione:
          this.valore = this.rendiconto.tassoDiConversione ?? "-";
          break;
        case TipoDatoCellaReportRendiconto.operatore:
          this.valore = this.rendiconto.operatore?.nomeCompleto ?? "-";
          break;
        case TipoDatoCellaReportRendiconto.voucherDistribuiti10:
          this.valore = this.rendiconto.numeroBanconoteIngaggio10 ?? "-";
          break;
        case TipoDatoCellaReportRendiconto.voucherRicevuti10:
          this.valore = this.rendiconto.numeroBanconoteRitirate10 ?? "-";
          break;
        case TipoDatoCellaReportRendiconto.tassoVoucher10:
          this.valore = this.rendiconto.tassoDiConversioneVoucher10 ?? "-";
          break;
        case TipoDatoCellaReportRendiconto.voucherDistribuiti15:
          this.valore = this.rendiconto.numeroBanconoteIngaggio15 ?? "-";
          break;
        case TipoDatoCellaReportRendiconto.voucherRicevuti15:
          this.valore = this.rendiconto.numeroBanconoteRitirate15 ?? "-";
          break;
        case TipoDatoCellaReportRendiconto.tassoVoucher15:
          this.valore = this.rendiconto.tassoDiConversioneVoucher15 ?? "-";
          break;
      }

    }

  }

}
