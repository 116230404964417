import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '@app/core/menu/menu.service';
import { S1UIService } from '@app/s1';
import { MenuApiService } from '@app/shared/services/menu-api.service';
import { AzioniPreferitiModal } from '../azioni-preferiti-modal/azioni-preferiti-modal.component';

@Component({
  selector: 'preferiti',
  templateUrl: './preferiti.component.html',
  styleUrls: ['./preferiti.component.scss']
})

export class PreferitiComponent {

  @ViewChild('favouriteModal') public favouriteModal: AzioniPreferitiModal

  favourites: any[];

  constructor( private menuService: MenuApiService,private ui: S1UIService,private router:Router) { }

  ngOnInit() {
    this.getFavourites()
  }

  goTo(favourite) {
    this.router.navigate([favourite.url]);
  }

  addFavourite() {
    this.favouriteModal.open();
  }

  askDelete(azione: any, event: Event) {
    event.stopPropagation();

    this.ui.showDialogPopup("favourites.messages.deleteFavourite").then(result => {
      if (result.value) {
        this.delete(azione);
      }
    })

  }

  private delete(azione:any){
    this.menuService.eliminaPreferito(azione.id).subscribe(response => {
      this.getFavourites();
    });
  }

  getFavourites() {
   
    this.menuService.preferitiUtente().subscribe(preferiti => {
      this.favourites = preferiti.azioni;
    })
  
  }

}


