import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 's1-text-area',
  templateUrl: './s1-text-area.component.html',
  styleUrls: ['./s1-text-area.component.scss']
})
export class S1TextArea implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() readonly: boolean = false;
  @Input() isValid: boolean = true;
  @Input() numRows: number = 3;
  @Input() tooltip: string = '';

  constructor() { }

  ngOnInit(): void { }

  checkIsValid(): boolean {

    return this.control?.valid || !this.control?.touched;

  }


}
