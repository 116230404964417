import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { S1ButtonType, S1DownloadService, S1Table, S1UIService } from '@app/s1';
import { IAllegato, ITipoAllegatoEntita } from '@app/shared/models/allegato';
import { AllegatoModalComponent } from '../allegato-modal/allegato-modal.component';
import { AllegatiService, IRicercaAllegatiParams } from '@app/shared/services/allegati.service';

@Component({
  selector: 'all-allegati-tipo-section',
  templateUrl: './allegati-tipo-section.component.html',
  styleUrls: ['./allegati-tipo-section.component.scss']
})
export class AllegatiTipoSectionComponent implements OnInit, AfterViewInit {

  @Input() set tipo(newValue: ITipoAllegatoEntita) {

    this._tipo = newValue;

    if (this.table) {
      this.searchAllegati(this._tipo);
    }

  }

  @Input() idOggetto: string;

  @ViewChild('table') table: S1Table;
  @ViewChild('allegatoModal') allegatoModal: AllegatoModalComponent;

  _tipo: ITipoAllegatoEntita;

  s1ButtonType = S1ButtonType;

  constructor(private allegatiService: AllegatiService, private s1DownloadService: S1DownloadService, private ui: S1UIService) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {

    if (this._tipo) {
      this.searchAllegati(this._tipo);
    }

  }

  searchAllegati(tipo: ITipoAllegatoEntita) {

    const params: IRicercaAllegatiParams = {
      page: this.table?.config.pagination.page.page,
      pageSize: +this.table?.config.pagination.page.itemsPerPage,
      idTipoAllegato: tipo.idTipoAllegato,
      idEntita: this.idOggetto
    }

    this.allegatiService.ricerca(params, tipo.entita).subscribe(allegatiPaginati => {
      this.table.updateData(allegatiPaginati.allegati, allegatiPaginati.paginationInfo);
    })

  }

  nuovo() {
    this.allegatoModal.open(this._tipo, this.idOggetto);
  }

  download(allegato: IAllegato, show: boolean) {

    this.allegatiService.download(allegato?.id, this._tipo.entita).subscribe(file => {

      if (show) {
        this.s1DownloadService.show(file, allegato.nomeFile);
      } else {
        this.s1DownloadService.download(file, allegato.nomeFile);
      }

    })

  }

  askDelete(allegato: IAllegato) {

    this.ui.showDialogPopup("allegati.ricerca.popup.delete").then(result => {
      if (result.value) {
        this.delete(allegato);
      }
    })

  }

  private delete(allegatoToDelete: IAllegato) {

    this.allegatiService.elimina(allegatoToDelete.id, this._tipo.entita).subscribe(deleted => {

      if (deleted) {
        this.ui.showSuccessToast("allegati.ricerca.popup.deleted");
        this.searchAllegati(this._tipo);
      }

    })

  }

}
