import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { LoggingService } from '../../../../app/core/services/log.service';
import { AuthService, ILogin } from "../../../../app/core/services/auth.service";
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { S1UIService } from '@app/s1';
import { UtenteService } from '@app/shared';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loginForm: UntypedFormGroup;

    // params from path
    paramId;

    // string for errorMessage service
    msgTranslation;
    errorTranslation;
    errorMessage;

    constructor(public settings: SettingsService, formBuilder: UntypedFormBuilder, private logger: LoggingService, 
                private router: Router, private route: ActivatedRoute, private authService: AuthService, private translate: TranslateService, private ui: S1UIService, private utenteService: UtenteService) {

        // get text message
        //this.errorTranslation =  this.translate.get('error');
        this.translate.get('error').subscribe( (text) => {
            this.errorTranslation = text;
        });

        //this.msgTranslation =  this.translate.get('msg');
        this.translate.get('msg').subscribe( (text) => {
            this.msgTranslation = text;
        });

        this.paramId = this.route.params.subscribe(params => {
            const id = params['id']; // (+) converts string 'id' to a number
            if (id != null ) { this.errorMessage = '' } //this.msgTranslation.logout_ok; }
        });

        this.loginForm = formBuilder.group({
            'username': [null, Validators.required],
            'password': [null, Validators.required]
        });

        this.utenteService.clearCurrentUser() // Mi assicuro di partire da una situazione pulita

    }



    onSubmit(): void {
        const lData: ILogin = { username: this.loginForm.value.username, password: this.loginForm.value.password };

        this.authService.login(lData).subscribe(isLogged => {

          if (isLogged) {
            this.goToDashboard();
          }

        })

    }

    ngOnInit() {

    }

    goToDashboard() {
      this.router.navigate(['/home']);
    }

}
