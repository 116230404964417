import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { S1ReadableDatePipe } from '../s1-readable-date.pipe';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale, enGbLocale } from 'ngx-bootstrap/locale';
defineLocale('it', itLocale);
defineLocale('en', enGbLocale);

@Component({
  selector: 's1-input-datepicker',
  templateUrl: './s1-input-datepicker.component.html',
  styleUrls: ['./s1-input-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class S1InputDatepicker {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() readonly: boolean = false;
  @Input() set maxDate(newValue: Date) {
    this.bsConfig.maxDate = newValue;
  };
  @Input() set minDate(newValue: Date) {
    this.bsConfig.minDate = newValue;
  };
  @Input() set withTime(newValue: boolean) {

    this.bsConfig.withTimepicker = newValue;
    this.bsConfig.keepDatepickerOpened = newValue;
    this.bsConfig.dateInputFormat = newValue ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY';

  }

  @Input() locale: string = 'it';

  bsConfig = {
    containerClass: 'theme-dark-blue',
    dateInputFormat: 'DD/MM/YYYY',
    maxDate: null,
    minDate: null,
    withTimepicker: false,
    keepDatepickerOpened: false
  }
  
  constructor(private localeService: BsLocaleService, private readableDatePipe: S1ReadableDatePipe) { 
    this.localeService.use(this.locale);
  }

  checkIsValid(): boolean {

    // controllo se è stato impostato il maxDate
    // if (this.maxDate) {
    //   this.bsConfig.maxDate = this.maxDate
    // }

    return this.control.valid || !this.control.touched;

  }

  readableDate() {
    return this.readableDatePipe.transform(this.control.value)
  }
  
}