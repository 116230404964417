import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class S1DownloadService {

  show(bytesArray: any, mimetype: string) {
    var byteArray = bytesArray;
    var blob = byteArray //this.base64toBlob(byteArray, mimetype);
    console.log("BLOB: ", blob)
    var win = window.open(URL.createObjectURL(blob), '_blank');
    win.focus();
  }

  showBlob(bytesArray: any, mimetype: string) {
    var byteArray = bytesArray;
    var blob =this.base64toBlob(byteArray, mimetype);
    console.log("BLOB: ", blob)
    var win = window.open(URL.createObjectURL(blob), '_blank');
    win.focus();
  }

  download(blob: Blob, filename) {

    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();

  }

showExcel(bytesArray: any) {
  this.showBlob(bytesArray, "application/vnd.ms-excel");
 //this.show(bytesArray, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
}
showXlsx(bytesArray: any) {
  //this.show(bytesArray, "application/vnd.ms-excel");
 this.showBlob(bytesArray, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
}

showPDF(bytesArray: any) {
  this.show(bytesArray, "application/pdf");
}

showZip(bytesArray: any) {
  this.show(bytesArray, "application/zip");
}

private base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
}
