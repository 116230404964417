import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { IS1PaginationInfo } from '../s1-http-client.service';
import { IS1Table, S1Pagination } from '../s1-pagination/s1-pagination.component';
import { IS1Sorting } from '../s1-sort.service';

@Component({
  selector: 's1-table',
  templateUrl: './s1-table.component.html',
  styleUrls: ['./s1-table.component.scss']
})
export class S1Table implements OnInit {

  @Input() onlinePagination: boolean = true;
  @Input() hoverStyle: boolean = true;
  @Output() pageChanged = new EventEmitter<any>();

  @ViewChild('pagination') pagination: S1Pagination;

  config: IS1Table;

  constructor() { 

    this.config = {
      data: [], // Tutte le righe
      totalData: 0, // Numero di elementi totale
      rows: [], // Righe per la pagina attuale
      selectedRow: null,
      pagination: {
        page: { // Stesso oggetto di <pagination>
            page: 1,
            itemsPerPage: 10
        },
        maxSize: 5,
        numPages: 0
      },
      sorting: {
        sortBy: '',
        isSortAscending: true
      }
    }

  }

  ngOnInit(): void { }

  setDefault() {
    this.config.pagination.page.page = 1;
    this.config.pagination.page.itemsPerPage = 10;
  }

  actualPage(): number {
    return +this.config.pagination.page.page
  }

  itemsPerPage(): number {
    return +this.config.pagination.page.itemsPerPage
  }

  updateData(data: any[], paginationInfo?: IS1PaginationInfo) {
    
    if (this.onlinePagination) {
      this.config.rows = data
      this.config.totalData = paginationInfo.totalItems
      this.config.pagination.numPages = paginationInfo.totalPages
      this.config.pagination.page.page = paginationInfo.actualPage
      this.config.pagination.page.itemsPerPage = paginationInfo.pageSize
    } else {

      this.config.data = data
      setTimeout(()=> { // https://github.com/angular/angular/issues/11007
        this.pagination.changePage()
      }, 1);

    }

  }

  private isLastPage(): boolean {
    return this.config.pagination.page.page === this.config.pagination.numPages
  }

  private isPageFull(): boolean {
    return this.config.rows.length === this.config.pagination.page.itemsPerPage
  }

  private isLastPageFull(): boolean {

    return this.config.totalData % this.config.pagination.page.itemsPerPage == 0

  }

  // Serve solo quando si crea un elemento con una chiamata server
  // Vado all'ultima pagina per vedere l'ultimo inserito, se la pagina è piena devo aumentare di uno
  refreshAfterCreate() { //FIXME: problemi di caambio valore in console

    if (this.onlinePagination) {

      console.log("Is fULL: ", this.isLastPageFull())

      let newConf = this.config

      console.log("N: ", newConf.pagination.numPages)

      if (this.isLastPageFull()) {
        newConf.pagination.page.page = newConf.pagination.numPages + 1
      } else {
        newConf.pagination.page.page = newConf.pagination.numPages
      }

      this.config = {...newConf}

      console.log("N: ", this.config.pagination.page.page)
      this.paginationUpdated()

    }

  }

  // Serve solo quando si elimina un elemento con una chiamata server, ma quest'ultimo è ancora all'interno della tabella
  // Se è l'ultimo di una pagina può creare problemi fare refresh della tabella da esterno
  refreshAfterDelete() {

    if (this.onlinePagination && this.config.pagination.page.page > 1 && this.config.rows.length == 1) { // E' stato rimosso l'ultimo elemento della pagina, devo quindi aggiornare quella corrente a meno che sia la prima
      this.config.pagination.page.page -= 1 // In automatico pagination emetterà l'evento pageChanged
    } else {
      this.paginationUpdated() // Emetto un evento pageChanged per avvisare di fare refresh tabella in modo che l'elemento eliminato non sarà più presente
    }

  }

  paginationUpdated() {
    console.log("UPD dentro table")
    this.pageChanged.emit()
  }

  sortingColumn(): string {
    return this.config.sorting.sortBy
  }

  sortingAscending(): boolean {
    return this.config.sorting.isSortAscending
  }

  sortBy(): string {

    let ret = this.config.sorting.sortBy;

    if (ret == null || ret == '') {
      return null
    }

    if (this.config.sorting.isSortAscending) {
      ret = ret + " asc";
    } else {
      ret = ret + " desc";
    }

    return ret;

  }
  
  columnSorted(sorting: IS1Sorting) {
    this.config.sorting = sorting;
    this.config.pagination.page.page = 1;
    this.config = {...this.config}
    this.paginationUpdated()
  }

}
