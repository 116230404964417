import { Injectable } from '@angular/core';
import { IS1PaginatedResult, IS1SearchParams, S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { S1State } from '../models/entita-base';

export interface IRuoloMenu {
  id?: string,
  codice: string,
  stato: S1State
}

export interface IRuoloRicercaParams extends IS1SearchParams { }

export interface IRuoliPaginati extends IS1PaginatedResult {
  ruoli: IRuoloMenu[];
}

export enum RuoloAPI {
  ricerca = "/ricerca"
}

@Injectable({
  providedIn: 'root'
})
export class RuoloService {

  private path = "/ruolo"

  constructor(private s1HttpClient: S1HttpClientService) { }

  ricerca(params: IRuoloRicercaParams): Observable<IRuoliPaginati> {

    return this.s1HttpClient.post(this.path + RuoloAPI.ricerca, params).pipe(
      map(response => {
        return  { 
          ruoli: response.results, //.map(r => { return { id: r.id, role: r.role } as IRuoloMenu }),
          paginationInfo: response.paginationInfo
        } as IRuoliPaginati
      })
    )

  }

  create(ruolo: IRuoloMenu): Observable<boolean> {

    return this.s1HttpClient.post(this.path, ruolo).pipe(
      map(response => {
        return response.outcome.success
      })
    )

  }

  update(ruolo: IRuoloMenu): Observable<boolean> {

    return this.s1HttpClient.put(`${this.path}/${ruolo?.id}`, ruolo).pipe(
      map(response => {
        return response.outcome.success
      })
    )

  }

  delete(ruolo: IRuoloMenu): Observable<boolean> {

    return this.s1HttpClient.delete(`${this.path}/${ruolo?.id}`).pipe(
      map(response => {
        return response.outcome.success
      })
    )

  }

}
