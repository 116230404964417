<s1-modal #modal [title]="'Confronto'">
  <div class="row">
    <div class="col">
      <s1-pdf-viewer [blob]="firstPdf"></s1-pdf-viewer>
    </div>
    <div class="col" *ngIf="secondPdf">
      <s1-pdf-viewer [blob]="secondPdf"></s1-pdf-viewer>
    </div>
  </div>
</s1-modal>

<app-modal #resizableModal class="modal-resizable" >
  <ng-container class="app-modal-header">{{ 'Confronto' }}</ng-container>
  <ng-container class="app-modal-body">
    <div class="row">
      <div class="col">
        <s1-pdf-viewer [blob]="firstPdf"></s1-pdf-viewer>
      </div>
      <div class="col" *ngIf="secondPdf">
        <s1-pdf-viewer [blob]="secondPdf"></s1-pdf-viewer>
      </div>
    </div>
  </ng-container>
</app-modal>