import { Component, Input } from '@angular/core';
import { ICampoExtraRendicontoValorizzato } from '@app/shared/models/campo-extra-rendiconto';
import { IFasciaOraria } from '@app/shared/models/fascia-oraria';
import { INegozio } from '@app/shared/models/negozio';
import { IRendiconto } from '@app/shared/models/rendiconto';

@Component({
  selector: 'iwr-report-rendiconti-cell-campo-extra',
  templateUrl: './report-rendiconti-cell-campo-extra.component.html',
  styleUrls: ['./report-rendiconti-cell-campo-extra.component.scss']
})
export class ReportRendicontiCellCampoExtraComponent {

  @Input() set rendiconti(newValue: IRendiconto[]) {
    this._rendiconti = newValue;
    this.mapValue();
  };

  @Input() set negozio(newValue: INegozio) {
    this._negozio = newValue;
    this.mapValue();
  }

  @Input() set fascia(newValue: IFasciaOraria) {
    this._fascia = newValue;
    this.mapValue();
  }

  @Input() set campoExtra(newValue: ICampoExtraRendicontoValorizzato) {
    this._campoExtra = newValue;
    this.mapValue();
  }

  valore: any = "-";

  _rendiconti: IRendiconto[] = [];
  _negozio: INegozio;
  _fascia: IFasciaOraria;
  _campoExtra: ICampoExtraRendicontoValorizzato;

  rendiconto: IRendiconto;

  mapValue() {

    if (!(this._rendiconti && this._negozio && this._fascia && this._campoExtra)) {
      return;
    }

    this.rendiconto = this._rendiconti.find(rendiconto => rendiconto.negozio?.id == this._negozio?.id && rendiconto.fasciaOraria?.id == this._fascia?.id);

    if (this.rendiconto) {

      const campiExOp = this.rendiconto.campiOperatore.map(campoOp => campoOp.campiExtra)
      const campiEx = campiExOp.reduce((accumulator, value) => accumulator.concat(value), []);
      const campExFil = campiEx.filter(campo => campo.codice == this._campoExtra.codice && campo.nome == this._campoExtra.nome)

      if (campExFil?.length) {
        this.valore = campExFil.map(campoEx => Number(String(campoEx.valore).replace(',','.'))).reduce((sum, val) => sum + val)
      }

    }
  }

}
