import { Component, EventEmitter, Input, Output } from '@angular/core';
import { S1DownloadService, S1UIService } from '@app/s1';
import { IAllegatoTask } from '@app/shared/models/allegato-task';
import { IAllegatoTaskNegozioParams, TaskNegozioService } from '@app/shared/services/task-negozio.service';
import { IAllegatoTaskParams, TaskService } from '@app/shared/services/task.service';

export enum AllegatoType {
  task,
  risposta
}

@Component({
  selector: 'iwr-allegato-tag',
  templateUrl: './allegato-tag.component.html',
  styleUrls: ['./allegato-tag.component.scss']
})
export class AllegatoTagComponent {

  @Input() allegato: IAllegatoTask;
  @Input() idTask: string;
  @Input() idRisposta: string;
  @Input() tipo: AllegatoType = AllegatoType.task;
  @Input() showDelete: boolean = false;

  @Output() elementDeleted = new EventEmitter<boolean>();

  constructor(private taskService: TaskService, private taskNegozioService: TaskNegozioService, private s1downloadService: S1DownloadService, private ui: S1UIService) { }

  open() {

    if (this.tipo == AllegatoType.task) {

      const params: IAllegatoTaskParams = {
        idTask: this.idTask,
        idAttachment: this.allegato?.id
      }
  
      this.taskService.getAllegato(params).subscribe(allegato => {
  
        if (allegato) {
          this.s1downloadService.show(allegato, '');
        }
  
      }) 

    } else {

      const params: IAllegatoTaskNegozioParams = {
        idTaskNegozio: this.idTask,
        idRisposta: this.idRisposta,
        idAttachment: this.allegato?.id
      }
  
      this.taskNegozioService.getAllegato(params).subscribe(allegato => {
  
        if (allegato) {
          this.s1downloadService.show(allegato, '');
        }
  
      }) 

    }

  }

  askDelete(allegato: IAllegatoTask) {

    this.ui.showDialogPopup("allegati.messages.delete").then(result => {
      if (result.value) {
        this.delete(allegato);
      }
    })

  }

  private delete(allegato: IAllegatoTask) {

    if (this.tipo == AllegatoType.task) {

      const params: IAllegatoTaskParams = {
        idTask: this.idTask,
        idAttachment: allegato?.id
      }
  
      this.taskService.rimuoviAllegato(params).subscribe(deleted => {
  
        if (deleted) {
          this.ui.showSuccessToast("allegati.messages.deleted");
          this.elementDeleted.emit(true);
        }
  
      }) 

    }

  }

}
