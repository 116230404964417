import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { IS1PaginatedResult, IS1SearchParams, S1HttpClientService, S1UIService } from '@app/s1';

import { IUtente } from '../models/utente';
import { Router } from '@angular/router';
import { MenuService } from '@app/core/menu/menu.service';
import { IOperatore } from '../models/operatore';
import { IRuoloMenu } from './ruolo.service';
import { S1State } from '../models/entita-base';

export interface IIdUtente {
  idUtente: string
}

export interface ICreaUtente extends IUtente {
  //password: string,
  //confermaPassword: string
}

export interface IAggiornaUtente extends IUtente { } // E' uguale a IUtente, ma per chiarezza creo interfaccia che si chiama come API
export interface IRicercaUtenti extends IS1SearchParams {
  nomeCompleto?: string,
  nomeRuolo?: string,
  username?: string
}

export interface IUtentiPaginati extends IS1PaginatedResult {
  utenti: IUtente[],
}

export interface IMenuItem {
  text: string,
  icon: string,
  link: string,
  submenu: IMenuItem[]
}

export interface IProfiloUtente {
  utente: IUtente,
  menu : IMenuItem[] | any[],
  profilo: any,
  abilitazioni: string[]
}

export interface IAssociaNegozioParams {
  idUtente: string,
  idNegozio: string
}

export interface IDisassociaNegozioParams extends IAssociaNegozioParams {}

// TODO controllare utente
export enum UtenteAPI {
  utente = "/utente",
  ricerca = "/utente/ricerca",
  getProfilo = "/utente/profilo",
  impostaCodiceOperatore = "/utente/impostaCodiceOperatore",
  operatore = "/utente/operatore",
  associaNegozio = "/utente/:idUtente/associaNegozio",
  disassociaNegozio = "/utente/:idUtente/disassociaNegozio"
}

@Injectable({
  providedIn: 'root'
})
export class UtenteService {

  private currentUser: IUtente;

  private _operatore: IOperatore;
  private operatore = new BehaviorSubject<IOperatore>(null);
  operatore$ = this.operatore.asObservable();

  constructor(private router: Router, private s1HttpClient: S1HttpClientService, public menuService: MenuService, private ui: S1UIService) { }

  getCurrentUser(): Observable<IUtente> {

    return new Observable((observer) => {

      if (this.currentUser) {
        observer.next(this.currentUser)
        observer.complete()
      } else {
        this.getProfilo().subscribe(utente => {
          this.currentUser = utente.utente ?? utente.profilo;
          localStorage.setItem("profilo", JSON.stringify(utente?.profilo));
          const menuStandarized = this.menuService.standardizeMenu((utente.menu as any)?.children);
          localStorage.setItem("menu", JSON.stringify(menuStandarized));
          this.menuService.replaceMenu(menuStandarized);
          localStorage.setItem("abilitazioni",JSON.stringify(utente?.abilitazioni))
          observer.next(this.currentUser);
          observer.complete();
        })
      }
  
    })

  }

  clearCurrentUser() {
    this._operatore = null;
    this.operatore.next(this._operatore);
    this.currentUser = null;
    localStorage.clear();
    sessionStorage.clear();
  }

  logout(logoutReason: number = 0) {
    this.clearCurrentUser();
    this.router.navigate(["/login/" + logoutReason]);
  }

  // TODO: IMPROVE
  isAdmin(): Observable<boolean> {
    return this.checkRuolo({ id: '', codice: 'ADMIN', stato: S1State.enabled})
  }

  private checkRuolo(ruolo: IRuoloMenu): Observable<boolean> {
    return this.getCurrentUser().pipe(
      map(utente => {
        return utente.ruoli.includes(ruolo)
      })
    )
  }

  // Utente
  creaUtente(newUtente: ICreaUtente): Observable<IUtente> {

    return this.s1HttpClient.post(UtenteAPI.utente, newUtente).pipe(
      map(response => {
        return response.item
      })
    )

  }

  aggiornaUtente(utenteToUpdate: IAggiornaUtente): Observable<boolean> {

    return this.s1HttpClient.put(`${UtenteAPI.utente}/${utenteToUpdate?.id}`, utenteToUpdate).pipe(
      map(response => {
        return response.outcome.success
      })
    )

  }

  eliminaUtente(utenteToDelete: IUtente): Observable<boolean> {

    return this.s1HttpClient.delete(`${UtenteAPI.utente}/${utenteToDelete?.id}`).pipe(
      map(response => {
        return response.outcome.success
      })
    )

  }

  ricercaUtenti(params: IRicercaUtenti): Observable<IUtentiPaginati> {

    return this.s1HttpClient.post(UtenteAPI.ricerca, params).pipe(
      map(response => {
        return  { 
          utenti: response.results,
          paginationInfo: response.paginationInfo
        }
      })
    )

  }

  private getProfilo(): Observable<IProfiloUtente> {

    return this.s1HttpClient.get(UtenteAPI.getProfilo, false).pipe(
      map(response => {
        return response.item
      })
    )

  }

  impostaCodiceOperatore(codice: string): Observable<string> {

    return this.s1HttpClient.put(`${UtenteAPI.impostaCodiceOperatore}/${codice}`, {}).pipe(
      map(response => {
        return response.item.token
      })
    )

  }

  getOperatore(): Observable<IOperatore> {

    return this.s1HttpClient.get(UtenteAPI.operatore).pipe(
      map(response => {
        return response.item
      })
    )

  }

  getDettaglio(idUtente: string): Observable<IUtente> {

    return this.s1HttpClient.get(`${UtenteAPI.utente}/${idUtente}`).pipe(
      map(response => {
        return response.item
      })
    )

  }

  associaNegozio(params: IAssociaNegozioParams): Observable<boolean> {

    return this.s1HttpClient.post(`${UtenteAPI.associaNegozio.replace(":idUtente", params.idUtente)}/${params.idNegozio}`, {}).pipe(
      map(response => {
        return response.outcome.success
      })
    )

  }

  disassociaNegozio(params: IDisassociaNegozioParams): Observable<boolean> {

    return this.s1HttpClient.post(`${UtenteAPI.disassociaNegozio.replace(":idUtente", params.idUtente)}/${params.idNegozio}`, {}).pipe(
      map(response => {
        return response.outcome.success
      })
    )

  }

  private checkOperatore() {

    this.getOperatore().subscribe(operatore => {

      if (operatore) {
        this._operatore = operatore;
        this.operatore.next(this._operatore);
        localStorage.setItem("operatore", JSON.stringify(operatore));
      } else {
        this.checkOpenPopupOperatore();
      }

    }, error => {
      this.checkOpenPopupOperatore();
    })

  }

  checkOpenPopupOperatore() {

    if (!this.currentUser.codiceOperatoreRichiesto) {
      return;
    }

    const codiceOperatore = localStorage.getItem("codiceOperatore");

    if (codiceOperatore) { 
      this.checkOperatore();
    } else {

      this.ui.showInputPopup("s1.swal.attention", "operatori.messages.askCode", "operatori.fields.codice").then(resp => {

        const codOperatore = resp.value;

        if (codOperatore && codOperatore != '') {

          this.impostaCodiceOperatore(codOperatore).subscribe(newToken => {
            localStorage.setItem("codiceOperatore", codOperatore);
            localStorage.setItem("token", newToken);
            this.checkOpenPopupOperatore();
          }, error => {
            this.checkOpenPopupOperatore();
          })

        } else {

          this.ui.showDialogPopup("operatori.messages.dialogCode").then(respDial => {

            if (respDial.value) {
              this.checkOpenPopupOperatore();
            } else {
              this.logout();
            }

          })
        }

      })
    }
  }

}