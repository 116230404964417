import { Component, Input } from '@angular/core';
import { IBudget } from '@app/shared/models/budget';
import { INegozio } from '@app/shared/models/negozio';

@Component({
  selector: 'iwr-report-rendiconti-cell-budget',
  templateUrl: './report-rendiconti-cell-budget.component.html',
  styleUrls: ['./report-rendiconti-cell-budget.component.scss']
})
export class ReportRendicontiCellBudgetComponent {

  @Input() set budgets(newValue: IBudget[]) {
    this._budgets = newValue;
    this.mapValue();
  };

  @Input() set negozi(newValue: INegozio[]) {
    this._negozi = newValue;
    this.mapValue();
  }

  @Input() set data(newValue: Date) {
    this._data = newValue;
    this.mapValue();
  }

  _budgets: IBudget[] = [];
  _negozi: INegozio[] = [];
  _data: Date;

  valore: any = 0;

  mapValue() {

    if (!(this._budgets?.length && this._negozi?.length)) {
      return;
    }

    let budgetFiltered = this._budgets.filter(budget => this._negozi.map(negozio => negozio.id).includes(budget.negozio?.id));

    if (this._data) {
      budgetFiltered = budgetFiltered.filter(rendiconto => new Date(rendiconto.giorno).toLocaleDateString() == this._data.toLocaleDateString())
    } 

    if (budgetFiltered) {

      this.valore = budgetFiltered.map(budget => budget.budget).reduce((sum, val) => sum + Number(val), 0);

    }

  }

}
