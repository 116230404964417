import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, IImpostaPassword } from '@app/core/services/auth.service';

@Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

    valForm: UntypedFormGroup;
    passwordForm: UntypedFormGroup;
    tokenParm: string;
    
    constructor(public settings: SettingsService, fb: UntypedFormBuilder,private router: Router, private route: ActivatedRoute, private authService: AuthService ) {

        let password = new UntypedFormControl('', [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#&()?\\[{}\\]:;',?\\/*~$^\\+=<>]).{8,20}$"), Validators.minLength(8), Validators.maxLength(20)]);
        let certainPassword = new UntypedFormControl('', [Validators.required, CustomValidators.equalTo(password)]);

        this.route.params.subscribe(params => {
            //this.tokenParm= atob(params['token']); 
            this.tokenParm= params['token']; 
            console.log(this.tokenParm);                        
        });

        this.passwordForm = fb.group({
            'password': password,
            'confirmPassword': certainPassword
        });

        this.valForm = fb.group({
            'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
            'login': [null, Validators.compose([Validators.required])],
            'passwordGroup': this.passwordForm
        });
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        for (let c in this.passwordForm.controls) {
            this.passwordForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            const impostaPasswordData: IImpostaPassword = {
                username: this.valForm.value.login,                                                             
                password: this.valForm.value.passwordGroup.password ,
                confermaPassword: this.valForm.value.passwordGroup.confirmPassword,
                email : this.valForm.value.email,
                token : this.tokenParm
            };

            this.authService.impostaPassword(impostaPasswordData).subscribe(result => {

                if (result) {                
                    this.settings.sessionExpired();
                    this.router.navigate(["/login/0"]);
                }      
            })
        }
    }

    ngOnInit() {
    }

}
