import { Component, OnInit, ViewChild } from '@angular/core';
import { S1ButtonType } from '../s1-button/s1-button.component';
import { S1IconModal } from '../s1-icon-modal/s1-icon-modal.component';
import { S1Modal, S1ModalSizes } from '../s1-modal/s1-modal.component';
import { S1Table } from '../s1-table/s1-table.component';

@Component({
  selector: 's1-policy-modal',
  templateUrl: './s1-policy-modal.component.html',
  styleUrls: ['./s1-policy-modal.component.scss']
})
export class S1PolicyModal implements OnInit {

  @ViewChild('modal') modal: S1Modal;
  @ViewChild('table') table: S1Table;
  @ViewChild('iconModal') public iconModal: S1IconModal;

  s1ButtonType = S1ButtonType;

  funzioni: [any];

  constructor() { }

  ngOnInit(): void { }

  load(funzioni: [any]) {

    this.funzioni = funzioni
    
    this.modal.open(S1ModalSizes.LG);
    this.table.updateData(this.funzioni);
    
  }

  close() {
    this.modal.close();
  }

}
