import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { S1ButtonType } from '../s1-button/s1-button.component';

@Component({
  selector: 's1-input-file',
  templateUrl: './s1-input-file.component.html',
  styleUrls: ['./s1-input-file.component.scss']
})
export class S1InputFile implements OnInit {

  @Output() newFiles = new EventEmitter<FileList>();
  @Input() disabled: boolean;

  s1ButtonType = S1ButtonType;

  constructor() { }

  ngOnInit(): void { }

  addFiles(files: FileList) {
    this.newFiles.emit(files);
  }

}
