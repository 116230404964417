import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { TipoNegozio } from '@app/shared/models/negozio';

@Component({
  selector: 'iwr-tipo-negozio-select',
  templateUrl: './tipo-negozio-select.component.html',
  styleUrls: ['./tipo-negozio-select.component.scss']
})
export class TipoNegozioSelectComponent {

  @Input() control: UntypedFormControl;
  @Input() readonly: boolean;

  tipi: IS1InputSelectItem[] = [];

  constructor() { }

  ngOnInit(): void { 
    this.loadItems();
  }

  loadItems() {

    this.tipi = [];

    Object.keys(TipoNegozio).forEach(tipo => {

      const option: IS1InputSelectItem = {
        code: tipo, label:TipoNegozio[tipo] 
      }

      this.tipi.push(option);

    })

    this.tipi = [... this.tipi];

  }

}
