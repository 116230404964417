import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoggingService } from './log.service';
import { throwError, Observable, of } from 'rxjs';

import { S1HttpClientService } from '@app/s1';
import { TranslatorService } from '../translator/translator.service';

enum AuthAPI {
  login = "/authentication/login",
  forgotPwd = "/authentication/passwordDimenticata",
  resetPwd = "/authentication/impostaPassword"
}

/* BASE STRUCT RESPONSE
*  {
      "outcome":
        { "success":true,
          "code":null,
          "message":null,
          "description":null
        },
      "data":
        {"token":"SeIkZJC0hIeBYdJYlbYxq4q0Us+gAPqrC/42W0SJ+HEMLLSsMn6TRnBozdS4vR89",
          "name":"SUPER",
          "surname":"ADMIN",
          "enabled":false,
          "role":"SUPERADMIN"}
    }
*
*/

export interface ILogin {
  username: string,
  password: string
}

export interface IImpostaPassword {
  username:string,
  password:string,
  confermaPassword:string,
  email:string,
  token:string
}

export interface IPasswordDimenticataUtente {
  login:string,
  email:string
}

@Injectable()
export class AuthService {

  pathInfoVersion = "/info";
  
  constructor (private http: HttpClient, private logger: LoggingService, private translator: TranslatorService, private s1HttpClient: S1HttpClientService) {}

  login(loginData: ILogin, ui: boolean = true): Observable<boolean> {

    return this.s1HttpClient.post(AuthAPI.login, loginData, ui).pipe(
      map(response => { 
        localStorage.setItem("token", response.item.token);
        localStorage.setItem("idUtente", response.item.idUtente)
        return true 
      }),
      catchError((err: HttpErrorResponse) => {
        return of(false);
      })
    )
    
  }

  forgotPwd(email: string): any {

    localStorage.clear();
    sessionStorage.clear();

    const mailObj: any = {
      email: email
    }

    return this.http
    .put<any[]>(environment.restBaseUrl + '/user/forgot', mailObj, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("Forgot pwd", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("Forgot pwd", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }
    ));

  }


  impostaPassword(impostaPasswordData: IImpostaPassword, ui: boolean = true): Observable<boolean> {

    return this.s1HttpClient.post(AuthAPI.resetPwd, impostaPasswordData, ui)
      .pipe(
        map(response => { 
         return true;
        }),
        catchError((err: HttpErrorResponse) => {
          return of(false);
        })
    )
  }

  passwordDimenticata(utenteResetPassword: IPasswordDimenticataUtente): Observable<boolean> {

    return this.s1HttpClient.post(AuthAPI.forgotPwd, utenteResetPassword).pipe(
      map(response => {
        return  true 
      }),
      catchError(err => { return of(false); })
    )

  }


  ///

  changePwd(passwords: Object): any {

    return this.http
    .put<any[]>(environment.restBaseUrl + '/user/changepwd', passwords, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("Change PWD", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("Change PWD", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }
    ));
  }

  activate(data: Object): any {

    localStorage.clear();
    sessionStorage.clear();

    return this.http
    .put<any[]>(environment.restBaseUrl + '/user/activate', data, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("Activate", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("Activate", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }
    ));

  }

  invite(idUser: number): any  {

    let invite: any = { };

    return this.http
    .put<any[]>(environment.restBaseUrl + '/admin/user/invite/' + idUser, invite, this.getRequestOptionArgs())
    .pipe(map(
    (response: HttpResponse<any>) => {
        const res: any = response;

        if (res.outcome.success === true) {
          this.logger.log("Forgot pwd", " SUCCESS", 200);
          return res;
        } else {
          this.logger.log("Forgot pwd", " FAILURE", 200);
          return res;
        }
      }
    ), catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }
    ));
  }

  // Rest Items Service: Read one element (detail)
  getVersion(path) {
    path = environment.restBaseUrl + path;
    return this.http
      .get<any[]>(path, this.getRequestOptionArgs())
      .pipe(map(
      (response: HttpResponse<any>) => {
        const res: any = response;
        this.logger.log("Infos:", res, 200);
        return res;
      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        return throwError(errorResponse.error);
      }
    ));
  }

  getRequestOptionArgs(): any {
    console.log("getRequestOptions");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.translator.getLanguageInUse() ?? ""
      })
    };
    return httpOptions;
  }
}
