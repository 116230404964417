import { Injectable } from '@angular/core';
import { IBudget } from '../models/budget';
import { IS1SearchParams, S1ApiCrudService, S1HttpClientService } from '@app/s1';

export interface IRicercaBudgetParams extends IS1SearchParams, Partial<IBudget> { 
  daGiorno?: Date,
  aGiorno?: Date
}

@Injectable({
  providedIn: 'root'
})
export class BudgetService extends S1ApiCrudService<IBudget, IRicercaBudgetParams> {

  constructor(s1HttpClient: S1HttpClientService) { 
    super(s1HttpClient, "/budget");
  }

}
