

<ng-container *ngIf="!audioBlob">
  <s1-button *ngIf="!recording" [type]="s1ButtonType.Delete" [icon]="'fas fa-microphone'" [label]="'s1.button.recordAudio'" (onClick)="record()"></s1-button>
  <i *ngIf="recording" class="fas fa-circle text-danger fa-beat p-2"></i>
  <s1-button *ngIf="recording" [type]="s1ButtonType.Add" [onlyIcon]="true" [icon]="'far fa-stop-circle'" (onClick)="stopRecord()"></s1-button>
</ng-container>
<ng-container *ngIf="audioBlob">
  <s1-button class="pr-2" *ngIf="!playing" [type]="s1ButtonType.Add" [onlyIcon]="true" [icon]="'far fa-play-circle'" (onClick)="play()"></s1-button>
  <s1-button class="pr-2" *ngIf="playing" [type]="s1ButtonType.Unlink" [onlyIcon]="true" [icon]="'far fa-stop-circle'" (onClick)="stopPlay()"></s1-button>
  <s1-button class="pr-2" [type]="s1ButtonType.Add" (onClick)="add()" [icon]="'fas fa-paperclip'" [label]="'s1.button.attach'"></s1-button>
  <s1-button class="pr-2" [type]="s1ButtonType.Delete" [onlyIcon]="true" (onClick)="delete()"></s1-button>
</ng-container>