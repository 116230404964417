<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.operatore" class="d-flex text-nowrap {{ this.valore == '-' ? 'justify-content-center' : 'justify-content-start' }}">
  {{ this.valore }}
  <a *ngIf="idRendiconto" routerLink="/rendiconto/{{ idRendiconto }}/standalone" routerLinkActive="active" target="_blank" rel="rendiconto">
    <i class="pl-1 fas fa-arrow-alt-circle-right"></i>
  </a>
</span>
<span class="d-flex {{ this.valore == '-' ? 'justify-content-center' : 'justify-content-end' }}" *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.storico">{{ this.valore == "-" ? this.valore : (this.valore | s1Currency) }}</span>
<span class="d-flex {{ this.valore == '-' ? 'justify-content-center' : 'justify-content-end' }}" *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.incasso">{{ this.valore == "-" ? this.valore : (this.valore | s1Currency) }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.ingressi">{{ this.valore == "-" ? this.valore : (this.valore | number: '1.0-2') }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.scontrini">{{ this.valore == "-" ? this.valore : (this.valore | number: '1.0-2') }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.tassoDiConversione">{{ this.valore == "-" ? this.valore : (this.valore | percent) }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.voucherDistribuiti10">{{ this.valore == "-" ? this.valore : (this.valore | number: '1.0-2') }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.voucherRicevuti10">{{ this.valore == "-" ? this.valore : (this.valore | number: '1.0-2') }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.tassoVoucher10">{{ this.valore == "-" ? this.valore : (this.valore | percent) }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.voucherDistribuiti15">{{ this.valore == "-" ? this.valore : (this.valore | number: '1.0-2') }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.voucherRicevuti15">{{ this.valore == "-" ? this.valore : (this.valore | number: '1.0-2') }}</span>
<span *ngIf="_tipoDato == tipoDatoCellaReportRendiconto.tassoVoucher15">{{ this.valore == "-" ? this.valore : (this.valore | percent) }}</span>