import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { LoggingService } from '@app/core/services/log.service';
import { S1UIService } from './s1-ui.service';
import { Router } from '@angular/router';
import { TranslatorService } from '@app/core/translator/translator.service';

// Call interfaces
export interface IS1SearchParams {
  page: number,
  pageSize: number,
  sort?: string,
  paginable?: boolean // If not passed by default true
}

// Response interfaces
export interface IS1PaginationInfo {
  actualPage: number
  next: boolean
  pageSize: number
  previous: boolean
  totalItems: number
  totalPages: number
}

export interface IS1PaginatedResult {
  paginationInfo: IS1PaginationInfo
}

export interface IS1TypedPaginatedResult<T> {
  paginationInfo: IS1PaginationInfo,
  elements: T[]
}

interface IS1Outcome {
  success: boolean,
  errorCode?: string,
  refIdError?: string,
  errorMessage: string
}

interface IS1Response extends HttpResponse<any> {
  outcome: IS1Outcome
  item?: any
  results?: any[]
  paginationInfo?: IS1PaginationInfo
  total?: number
}

@Injectable({
  providedIn: 'root'
})
export class S1HttpClientService {

  constructor(private http: HttpClient, private logger: LoggingService, private ui: S1UIService, private router: Router, private translator: TranslatorService) { }

  get token() {

    const _token = localStorage.getItem("token");

    if (_token) {

      sessionStorage.setItem("token", _token);
      return _token;
    } else {

      const _tokenS = sessionStorage.getItem("token");

      localStorage.setItem("token", _tokenS);
      return _tokenS ?? "";

    }

  }

  /**
   * Wrapper of `HTTPClient` post function that centralize the managment of base url, errors and UI feedback
   * 
   * @param path relative path of the API call.
   * @param body JSON body of the API call.
   * @param showUI determine to show or not UI feedback
   * @returns `Observable` of type `IS1Response`
   */

  post(path: string, body: any, showUI: boolean = true): Observable<IS1Response> {

    this.logger.log("POST: ", path, 200)
    this.logger.log("Par: ", body, 200)

    if (showUI) {
      this.ui.showSpinner()
    }

    return this.http.post<any>(environment.restBaseUrl + path, body, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      )
  }

  get(path: string, showUI: boolean = true): Observable<IS1Response> { 

    this.logger.log("GET: ", path, 200)

    if (showUI) {
      this.ui.showSpinner()
    }

    return this.http.get<any>(environment.restBaseUrl + path, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      )

  }

  put(path: string, body: any, showUI: boolean = true): Observable<IS1Response> {

    this.logger.log("PUT: ", path, 200)
    this.logger.log("Par: ", body, 200)

    if (showUI) {
      this.ui.showSpinner()
    }

    return this.http.put<any>(environment.restBaseUrl + path, body, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      )
  }

  delete(path: string, showUI: boolean = true): Observable<IS1Response> { 

    this.logger.log("DELETE: ", path, 200)

    if (showUI) {
      this.ui.showSpinner()
    }

    return this.http.delete<any>(environment.restBaseUrl + path, this.getRequestOptionArgs())
      .pipe(
        map((response: IS1Response) => this.handleResponse(response, showUI)),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      )

  }

  download(path: string, showUI: boolean = true): Observable<IS1Response> { 

    this.logger.log("DOWNLOAD: ", path, 200)

    if (showUI) {
      this.ui.showSpinner()
    }

    return this.http.get<any>(environment.restBaseUrl + path, this.getRequestOptionDownloadArgs())
      .pipe(
        map((response: IS1Response) => {

          this.logger.log("DOWNLOAD: ", response, 200)

          if (showUI) {
            this.ui.closeSpinner();
          }

          return response;

        }),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      )

  }

  downloadPost(path: string, body: any, showUI: boolean = true): Observable<IS1Response> {

    this.logger.log("DOWNLOAD: ", path, 200)
    this.logger.log("Par: ", body, 200)

    if (showUI) {
      this.ui.showSpinner()
    }

    return this.http.post<any>(environment.restBaseUrl + path, body, this.getRequestOptionDownloadArgs())
      .pipe(
        map((response: IS1Response) => {
          this.logger.log("DOWNLOAD: ", response, 200)

          if (showUI) {
            this.ui.closeSpinner();
          }

          return response;
        }),
        catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
      )
  }

 

  upload(path: string, formData: FormData, showUI: boolean = true): Observable<any> {

    /*if (showUI) {
      this.ui.showSpinner()
    }*/

    return this.http.post<any>(environment.restBaseUrl + path, formData, this.getRequestOptionUploadArgs())
    .pipe(
      //map((response: IS1Response) => this.handleResponse(response, showUI)),
      map((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          return (Math.round(100 * event.loaded / event.total));
        } else if (event.type === HttpEventType.Response) {
          return this.handleResponse(event?.body, false)
        }
      }),
      catchError((error: HttpErrorResponse) => this.handleError(error, showUI))
    )
  }

  private handleResponse(response: IS1Response, showUI: boolean = true) {

    const s1Response = response

    this.logger.log("Response: ", s1Response, 200)

    if (s1Response.outcome?.success) {

      if (showUI) {
        this.ui.closeSpinner()
      }

      return s1Response
      
    } else {

      throw new HttpErrorResponse({
        status: Number(s1Response.outcome.errorCode) ?? 499,
        statusText: s1Response.outcome.errorMessage,
        headers: s1Response.headers,
        url: s1Response.url
      })

    }

  }

  private handleError(error: HttpErrorResponse, showUI: boolean = true) {

    this.logger.log("HTTP Error: ", error, 200)

    if (showUI && error.status != 403) {
      this.ui.showHTTPErrorPopup(error)
    } else {
      this.ui.closeSpinner();
    }

    switch(error.status) {
      case 401: // Unauthorized
      //case 403: // Forbidden
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(["/login/1"]);
        break;
    }

    return throwError(error)

  }

  private getRequestOptionArgs(contentType: string = 'application/json'): any {

    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': contentType,
        'Accept-Language': this.translator.getLanguageInUse() ?? "",
        'Authorization': 'Bearer ' + this.token,
        //'X-Auth-Token': localStorage.getItem("token") != null ? localStorage.getItem("token") : ''
      })
    };

    if (contentType != null) {
      httpOptions.headers['Content-Type'] = contentType;
    }

    return httpOptions;
  }

  private getRequestOptionUploadArgs(): any { 

    const httpOptions = {
      headers: new HttpHeaders({
        'Accept-Language': this.translator.getLanguageInUse() ?? "",
        'Authorization': 'Bearer ' + this.token
      }),
      observe: 'events',
      reportProgress: true
    };

    return httpOptions;

  }

  private getRequestOptionDownloadArgs(): any {

    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': //'application/octet-stream',
        'Accept-Language': this.translator.getLanguageInUse() ?? "",
        'Authorization': 'Bearer ' + this.token,
      }),
      'responseType': 'blob'
    };

    return httpOptions;
  }

}

