<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center">
                <a href="#">
                    <img class="block-center rounded" src="assets/img/logo.png" style="width:70%;height:70%;" alt="Image" />
                </a>
            </div>
            <div class="card-body">
                <p class="text-center py-2">{{'recover.title' | translate}}</p>
                <form [formGroup]="valForm" class="form-validate" role="form" name="recoverForm" novalidate="" (submit)="submitForm($event, valForm.value)">
                    <p class="text-center">{{'recover.info' | translate}}</p>
                    <div class="form-group">
                        <label class="text-muted">{{'base.mail_address' | translate}}</label>
                        <div class="input-group with-focus">
                            <input class="form-control border-right-0" type="email" name="email" placeholder="{{'base.mail_address' | translate}}" autocomplete="off" formControlName="email" autocomplete="new-email"/>
                            <div class="input-group-append">
                                <span class="input-group-text text-muted bg-transparent border-left-0">
                                    <em class="fa fa-envelope"></em>
                                </span>
                            </div>
                        </div>
                        <div class="text-danger" *ngIf="valForm.controls['email'].hasError('required') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">{{'base.field_required' | translate}}</div>
                        <div class="text-danger" *ngIf="valForm.controls['email'].hasError('email') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">{{'base.field_mail_required' | translate}}</div>
                        <label class="text-muted">{{'base.username' | translate}}</label>
                        <div class="input-group with-focus">
                            <input class="form-control border-right-0" type="text" name="login" placeholder="{{'base.username' | translate}}" autocomplete="off" formControlName="login" autocomplete="new-login"/>
                            <div class="input-group-append">
                                <span class="input-group-text text-muted bg-transparent border-left-0">
                                    <em class="fa fa-envelope"></em>
                                </span>
                            </div>
                        </div>
                        <div class="text-danger" *ngIf="valForm.controls['login'].hasError('required') && (valForm.controls['login'].dirty || valForm.controls['email'].touched)">{{'base.field_required' | translate}}</div>                        


                        <div formGroupName="passwordGroup">
                            <div class="form-group">
                                <label class="text-muted mb-1">{{'base.pwd' | translate}}</label>
                                <div class="input-group with-focus">
                                    <input class="form-control border-right-0" id="id-password" type="password" name="password" formControlName="password" [formControl]="valForm.get('passwordGroup.password')" [tooltip]="'base.pwd_instructions' | translate" autocomplete="new-password" />
                                    <div class="input-group-append">
                                        <span class="input-group-text text-muted bg-transparent border-left-0">
                                            <em class="fa fa-lock"></em>
                                        </span>
                                    </div>
                                </div>
                                <div class="text-danger" *ngIf="valForm.get('passwordGroup.password').hasError('required') && (valForm.get('passwordGroup.password').dirty || valForm.get('passwordGroup.password').touched)">{{'base.field_required' | translate}}</div>
                                <div class="text-danger" *ngIf="valForm.get('passwordGroup.password').hasError('pattern') && (valForm.get('passwordGroup.password').dirty || valForm.get('passwordGroup.password').touched)">{{'base.pwd_instructions' | translate}}</div>
                            </div>
                            <div class="form-group">
                                <label class="text-muted mb-1">{{'base.retype_pwd' | translate}} </label>
                                <div class="input-group with-focus">
                                    <input class="form-control border-right-0" type="password" name="confirmPassword" formControlName="confirmPassword" [formControl]="valForm.get('passwordGroup.confirmPassword')" />
                                    <div class="input-group-append">
                                        <span class="input-group-text text-muted bg-transparent border-left-0">
                                            <em class="fa fa-lock"></em>
                                        </span>
                                    </div>
                                </div>
                                <div class="text-danger" *ngIf="valForm.get('passwordGroup.confirmPassword').hasError('required') && (valForm.get('passwordGroup.confirmPassword').dirty || valForm.get('passwordGroup.confirmPassword').touched)">{{'base.field_required' | translate}}</div>
                                <div class="text-danger" *ngIf="valForm.get('passwordGroup.confirmPassword').hasError('equalTo')">{{'base.pwd_not_match' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-danger btn-block" type="submit" [disabled]="valForm.invalid">{{'recover.reset' | translate}}</button>
                </form>
            </div>
        </div>
        <s1-language></s1-language>
        <!-- END card-->
        <div class="p-3 text-center">
            <span>&copy;</span>
            <span>{{ settings.getAppSetting('year') }}</span>
            <span class="mx-2">-</span>
            <span>{{ settings.getAppSetting('name') }}</span>
            <br/>
            <span>{{ settings.getAppSetting('description') }}</span>
        </div>
    </div>
</div>
