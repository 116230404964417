import { IAllegatoTask } from "./allegato-task";
import { IS1BasicEntity } from "./entita-base";
import { INegozio } from "./negozio";
import { IOperatore } from "./operatore";
import { ITask } from "./task";
import { IUtente } from "./utente";

export interface ITaskNegozio extends Omit<IS1BasicEntity, 'stato'> {
  task: Partial<ITask>,
  negozio: Partial<INegozio>,
  allegati: IAllegatoTask[];
  risposte: IRispostaTaskNegozio[],
  stato: StatoTaskNegozio,
  dataUltimaRisposta: Date,
  dataUltimaModifica: Date,
  utenteUltimaRisposta: IUtente
}

export enum StatoTaskNegozio {
  daFare = "DA_FARE",
  pubblicato = "PUBBLICATO",
  inLavorazione = "IN_LAVORAZIONE",
  chiuso = "CHIUSO",
  completato = "COMPLETATO",
  inRevisione = "IN_REVISIONE"
}

export interface IRispostaTaskNegozio extends IS1BasicEntity {
  descrizione: string,
  operatoreCreatoDa: IOperatore,
  operatoreModificatoDa: IOperatore,
  utenteCreatoDa: IUtente,
  utenteModificatoDa: IUtente,
  allegati: IAllegatoTask[];
  task: ITask
}

