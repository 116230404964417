import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 's1-pdf-viewer',
  templateUrl: './s1-pdf-viewer.component.html',
  styleUrls: ['./s1-pdf-viewer.component.scss']
})
export class S1PdfViewerComponent implements OnInit {

  @Input() set blob(newValue: Blob) {

    console.log("Blob:", newValue);

    if (newValue) {
      this.sanitizeBlob(newValue);
    }

  }

  safeUrl: SafeUrl;

  constructor(private sanitazer: DomSanitizer) { }

  ngOnInit(): void {
  }

  sanitizeBlob(blob: Blob) {
    this.safeUrl = this.sanitazer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
    console.log("Safe: ", this.safeUrl);
  }

}
