import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 's1-yesno-input-select',
  templateUrl: './s1-yesno-input-select.component.html',
  styleUrls: ['./s1-yesno-input-select.component.scss']
})
export class S1YesNoInputSelect {

  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() multiple:boolean = false;
  
  valori: IS1InputSelectItem[];

  constructor(private translate: TranslateService) {

    this.caricaValori();

  }

  private caricaValori(){
    var translatePrefix : string ='s1.yesno.'
    
    this.valori = [
      { code: null, label: this.translate.instant(translatePrefix + 'all') },      
      { code: true, label: this.translate.instant(translatePrefix +'yes') },
      { code: false, label: this.translate.instant(translatePrefix + 'no') }
    ]
  
  }

}
