import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';

import { S1Module  } from '../s1/s1.module';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule as ResizableModalModule } from 'ng-modal-lib';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { JqcloudDirective } from './directives/jqcloud/jqcloud.directive';
import { ScrollToBottomDirective } from './directives/scoll/scroll-to-bottom.directive';

import { 
    RuoliUtenteSelectComponent,
    InfoUtenteComponent,
    InputNumberComponent
} from './index';

import { PreferitiComponent } from './components/preferiti/preferiti.component';
import { AzioniPreferitiModal } from './components/azioni-preferiti-modal/azioni-preferiti-modal.component';
import { AzioniMenuRicercaComponent } from './components/azioni-menu-ricerca/azioni-menu-ricerca.component';
import { AzioneMenuModalComponent } from './components/azione-menu-modal/azione-menu-modal.component';
import { FunzioniAzioneMenuSectionComponent } from './components/funzioni-azione-menu-section/funzioni-azione-menu-section.component';
import { FunzioneAzioneMenuModalComponent } from './components/funzione-azione-menu-modal/funzione-azione-menu-modal.component';
import { TipoAllegatoSelectComponent } from './components/tipo-allegato-select/tipo-allegato-select.component';
import { EntitaAutocompleteComponent } from './components/entita-autocomplete/entita-autocomplete.component';
import { AllegatoModalComponent } from './components/allegato-modal/allegato-modal.component';
import { AllegatiSectionComponent } from './components/allegati-section/allegati-section.component';
import { AllegatiTipoSectionComponent } from './components/allegati-tipo-section/allegati-tipo-section.component';
import { PdfModalComponent } from './components/pdf-modal/pdf-modal.component';
import { NegoziSelectComponent } from './components/negozi-select/negozi-select.component';
import { TipoNegozioSelectComponent } from './components/tipo-negozio-select/tipo-negozio-select.component';
import { TurnoSelectComponent } from './components/turno-select/turno-select.component';
import { OperatoreSelectComponent } from './components/operatore-select/operatore-select.component';
import { StatoTagComponent } from './components/stato-tag/stato-tag.component';
import { ReportRendicontiSectionComponent } from './components/report-rendiconti-section/report-rendiconti-section.component';
import { ReportRendicontiCellComponent } from './components/report-rendiconti-cell/report-rendiconti-cell.component';
import { ReportRendicontiCellTotalComponent } from './components/report-rendiconti-cell-total/report-rendiconti-cell-total.component';
import { ReportTassoConversioneSectionComponent } from './components/report-tasso-conversione-section/report-tasso-conversione-section.component';
import { ReportTassoConversioneCellComponent } from './components/report-tasso-conversione-cell/report-tasso-conversione-cell.component';
import { ReportTassoConversioneCellTotalComponent } from './components/report-tasso-conversione-cell-total/report-tasso-conversione-cell-total.component';
import { ReportTassoConversioneCellTotalOperatoreComponent } from './components/report-tasso-conversione-cell-total-operatore/report-tasso-conversione-cell-total-operatore.component';
import { ReportTassoConversioneCellTotalTurnoComponent } from './components/report-tasso-conversione-cell-total-turno/report-tasso-conversione-cell-total-turno.component';
import { ReportRendicontiCellBudgetComponent } from './components/report-rendiconti-cell-budget/report-rendiconti-cell-budget.component';
import { FasciaOrariaSelectComponent } from './components/fascia-oraria-select/fascia-oraria-select.component';
import { ReportRendicontiNegozioSectionComponent } from './components/report-rendiconti-negozio-section/report-rendiconti-negozio-section.component';
import { ReportRendicontiCellStoricoComponent } from './components/report-rendiconti-cell-storico/report-rendiconti-cell-storico.component';
import { CategoriaSelectComponent } from './components/categoria-select/categoria-select.component';
import { PrioritaSelectComponent } from './components/priorita-select/priorita-select.component';
import { CategoriaTagComponent } from './components/categoria-tag/categoria-tag.component';
import { PrioritaTagComponent } from './components/priorita-tag/priorita-tag.component';
import { TabellaTaskNegoziComponent } from './components/tabella-task-negozi/tabella-task-negozi.component';
import { TabellaTaskComponent } from './components/tabella-task/tabella-task.component';
import { TaskModalComponent } from './components/task-modal/task-modal.component';
import { AllegatoTagComponent } from './components/allegato-tag/allegato-tag.component';
import { StatoTaskSelectComponent } from './components/stato-task-select/stato-task-select.component';
import { TabOrderDirective } from './directives/tab-order/tab-order.directive';
import { CampiOperatoreRendicontoSectionComponent } from './components/campi-operatore-rendiconto-section/campi-operatore-rendiconto-section.component';
import { InputCampoExtraRendicontoComponent } from './components/input-campo-extra-rendiconto/input-campo-extra-rendiconto.component';
import { ReportRendicontiCellCampoExtraComponent } from './components/report-rendiconti-cell-campo-extra/report-rendiconti-cell-campo-extra.component';
import { ReportRendicontiCellCampoExtraTotalComponent } from './components/report-rendiconti-cell-campo-extra-total/report-rendiconti-cell-campo-extra-total.component';

// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        ToastrModule.forRoot(),
        TooltipModule.forRoot(),
        S1Module.forRoot(),
        ResizableModalModule,
        NgxExtendedPdfViewerModule ,
        RouterModule
    ],
    providers: [
        TranslatePipe
    ],
    declarations: [
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        ScrollToBottomDirective,
        JqcloudDirective,
        RuoliUtenteSelectComponent,
        InfoUtenteComponent,
        InputNumberComponent,
        PreferitiComponent,
        AzioniPreferitiModal,
        AzioniMenuRicercaComponent,
        AzioneMenuModalComponent,
        FunzioniAzioneMenuSectionComponent,
        FunzioneAzioneMenuModalComponent,
        TipoAllegatoSelectComponent,
        EntitaAutocompleteComponent,
        AllegatoModalComponent,
        AllegatiSectionComponent,
        AllegatiTipoSectionComponent,
        PdfModalComponent,
        NegoziSelectComponent,
        TipoNegozioSelectComponent,
        TurnoSelectComponent,
        OperatoreSelectComponent,
        StatoTagComponent,
        ReportRendicontiSectionComponent,
        ReportRendicontiCellComponent,
        ReportRendicontiCellTotalComponent,
        ReportTassoConversioneSectionComponent,
        ReportTassoConversioneCellComponent,
        ReportTassoConversioneCellTotalComponent,
        ReportTassoConversioneCellTotalOperatoreComponent,
        ReportTassoConversioneCellTotalTurnoComponent,
        ReportRendicontiCellBudgetComponent,
        FasciaOrariaSelectComponent,
        ReportRendicontiNegozioSectionComponent,
        ReportRendicontiCellStoricoComponent,
        CategoriaSelectComponent,
        PrioritaSelectComponent,
        CategoriaTagComponent,
        PrioritaTagComponent,
        TabellaTaskNegoziComponent,
        TabellaTaskComponent,
        TaskModalComponent,
        AllegatoTagComponent,
        StatoTaskSelectComponent,
        TabOrderDirective,
        CampiOperatoreRendicontoSectionComponent,
        InputCampoExtraRendicontoComponent,
        ReportRendicontiCellCampoExtraComponent,
        ReportRendicontiCellCampoExtraTotalComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        AccordionModule,
        AlertModule,
        ButtonsModule,
        CarouselModule,
        CollapseModule,
        BsDatepickerModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        RatingModule,
        TabsModule,
        TimepickerModule,
        TooltipModule,
        PopoverModule,
        TypeaheadModule,
        ToastrModule,
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        ScrollToBottomDirective,
        JqcloudDirective,
        TooltipModule,
        S1Module,
        RuoliUtenteSelectComponent,
        InfoUtenteComponent,
        InputNumberComponent,
        TranslatePipe,
        PreferitiComponent,
        AzioniPreferitiModal,
        AzioniMenuRicercaComponent,
        AzioneMenuModalComponent,
        FunzioniAzioneMenuSectionComponent,
        FunzioneAzioneMenuModalComponent,
        TipoAllegatoSelectComponent,
        EntitaAutocompleteComponent,
        AllegatoModalComponent,
        AllegatiSectionComponent,
        AllegatiTipoSectionComponent,
        PdfModalComponent,
        NegoziSelectComponent,
        TipoNegozioSelectComponent,
        TurnoSelectComponent,
        OperatoreSelectComponent,
        StatoTagComponent,
        ReportRendicontiSectionComponent,
        ReportRendicontiCellComponent,
        ReportRendicontiCellTotalComponent,
        ReportTassoConversioneSectionComponent,
        ReportTassoConversioneCellComponent,
        ReportTassoConversioneCellTotalComponent,
        ReportTassoConversioneCellTotalOperatoreComponent,
        ReportTassoConversioneCellTotalTurnoComponent,
        ReportRendicontiCellBudgetComponent,
        FasciaOrariaSelectComponent,
        ReportRendicontiNegozioSectionComponent,
        ReportRendicontiCellStoricoComponent,
        CategoriaSelectComponent,
        PrioritaSelectComponent,
        CategoriaTagComponent,
        PrioritaTagComponent,
        TabellaTaskNegoziComponent,
        TabellaTaskComponent,
        TaskModalComponent,
        StatoTaskSelectComponent,
        AllegatoTagComponent,
        TabOrderDirective,
        CampiOperatoreRendicontoSectionComponent,
        InputCampoExtraRendicontoComponent,
        ReportRendicontiCellCampoExtraComponent,
        ReportRendicontiCellCampoExtraTotalComponent
    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule
        };
    }
}
