import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map, catchError } from 'rxjs/operators';
import { S1HttpClientService, IS1PaginatedResult, IS1SearchParams } from './s1-http-client.service';

export interface IS1RicercaStandardParams extends IS1SearchParams {
  codice?: string,
  id?: string, // compatibilità per alcune api
  descrizione?: string,
  codiceIniziaPer?:boolean
}

export interface IS1RicercaStandardResult {
  codice: string,
  id?: string, // compatibilità per alcune api
  descrizione: string
}

export interface IS1RicercaStandardResultsPaginati extends IS1PaginatedResult {
  results: IS1PaginatedResult[]
}

@Injectable({
  providedIn: 'root'
})
export class S1StandardSearchService {

  constructor(private s1HttpClient: S1HttpClientService) { 

  }

  ricerca(path: string, params: IS1RicercaStandardParams): Observable<IS1RicercaStandardResultsPaginati> {
  
    return this.s1HttpClient.post(path, params).pipe(
      map(response => {
        return {
          results: response.results,
          paginationInfo: response.paginationInfo
        }
      })/*,
      catchError((err: HttpErrorResponse) => {
        return of(false);
      })*/
    )
    
  }

}
