import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TreeModule } from '@ali-hm/angular-tree-component';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ToastrModule } from 'ngx-toastr';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgSelectModule } from '@ng-select/ng-select';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ColorPickerModule } from 'ngx-color-picker';

const maskConfigFunction: () => Partial<IConfig> = () => {
    return {
      validation: false, 
    };
  };

import { 
    S1Button,
    S1ButtonCheck,
    S1ButtonCheckAll,
    S1Header,
    S1Modal,
    S1ExpandableRow, S1ExpandableRowContent,
    S1ButtonExpand,
    S1InputLabel,
    S1InputDatepicker,
    S1InputText,
    S1InputAutocomplete,
    S1InputSelect,
    S1Table,
    S1Card,
    S1Pagination,
    S1TableGeneralIndexPipe,
    S1ServerDatePipe,
    S1ServerDateTimePipe,
    S1ReadableDatePipe,
    S1StandardSearchModal,
    S1StandardManageSection,
    S1Language,
    S1CurrencyPipe,
    S1TextArea,
    S1IconModal,
    S1PolicyModal,
    S1TreeEditor,
    S1InputMask,
    S1InputCurrency,
    S1InputFile,
    S1UploadSection,
    S1BytesPipe,
    S1SortableColumn,
    S1SortableTableDirective,
    S1InputSwitch,
    S1PdfViewerComponent,
    S1YesNoInputSelect,
    S1FormComponent,
    S1InputColorPickerComponent,
    S1AudioRecorderComponent,
    S1ItalianNumberPipe
} from './index';


// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        BsDatepickerModule,
        PaginationModule,
        ToastrModule,
        TypeaheadModule,
        NgSelectModule,
        TooltipModule.forRoot(),
        TreeModule,
        NgxMaskModule.forRoot(maskConfigFunction),
        ColorPickerModule
    ],
    declarations: [
        S1Button,
        S1ButtonCheck,
        S1ButtonCheckAll,
        S1Header,
        S1Modal,
        S1ExpandableRow,
        //S1ExpandableRowContent,
        S1ButtonExpand,
        S1InputLabel,
        S1InputDatepicker,
        S1InputText,
        S1InputAutocomplete,
        S1Table,
        S1Card,
        S1Pagination,
        S1TableGeneralIndexPipe,
        S1ServerDatePipe,
        S1ServerDateTimePipe,
        S1ReadableDatePipe,
        S1StandardSearchModal,
        S1StandardManageSection,
        S1Language,
        S1InputSelect,
        S1CurrencyPipe,
        S1TextArea,
        S1PolicyModal,
        S1IconModal,
        S1TreeEditor,
        S1InputMask,
        S1InputCurrency,
        S1InputFile,
        S1UploadSection,
        S1BytesPipe,
        S1SortableColumn,
        S1SortableTableDirective,
        S1InputSwitch,
        S1PdfViewerComponent,
        S1YesNoInputSelect,
        S1FormComponent,
        S1InputColorPickerComponent,
        S1AudioRecorderComponent,
        S1ItalianNumberPipe
    ],
    providers: [
        S1TableGeneralIndexPipe,
        S1ServerDatePipe,
        S1ServerDateTimePipe,
        S1ReadableDatePipe,
        S1CurrencyPipe,
        S1BytesPipe,
        DatePipe
    ],
    exports: [
        S1Button,
        S1ButtonCheck,
        S1ButtonCheckAll,
        S1Header,
        S1Modal,
        S1ExpandableRow,
        //S1ExpandableRowContent,
        S1ButtonExpand,
        S1InputLabel,
        S1InputDatepicker,
        S1InputText,
        S1InputAutocomplete,
        S1Table,
        S1Card,
        S1Pagination,
        S1TableGeneralIndexPipe,
        S1ServerDatePipe,
        S1ServerDateTimePipe,
        S1ReadableDatePipe,
        S1StandardSearchModal,
        S1StandardManageSection,
        S1Language,
        S1InputSelect,
        S1CurrencyPipe,
        S1TextArea,
        S1PolicyModal,
        S1IconModal,
        S1TreeEditor,
        S1InputMask,
        S1InputCurrency,
        S1InputFile,
        S1UploadSection,
        S1SortableColumn,
        S1SortableTableDirective,
        S1InputSwitch,
        S1PdfViewerComponent,
        S1YesNoInputSelect,
        S1FormComponent,
        S1InputColorPickerComponent,
        S1AudioRecorderComponent,
        S1ItalianNumberPipe
    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class S1Module {
    static forRoot(): ModuleWithProviders<S1Module> {
        return {
            ngModule: S1Module
        };
    }
}
