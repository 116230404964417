<div class="w-100 d-flex justify-content-center"><s1-input-datepicker [control]="dataControl" [label]="'Data'"></s1-input-datepicker></div>
<div class="table-container w-100"> 
  <table class="table td-vertical-center"> 
    <thead style="position: sticky;top: 0" > 
        <tr>
          <th class="py-0 sticky-top-left-first" scope="col"></th>
          <th class="py-0 header sticky-left-second"></th>
          <th class="py-0 header sticky-left-third"></th>
          <th class="py-0 header sticky-left-fourth"></th>

          <ng-container *ngFor="let fascia of fasce">
            <th [attr.colspan]="12 + campiExtra.length" class="text-center bg-secondary py-0 header" scope="col">{{ fascia.nome }}</th>
            <th class="separatore-turno py-0 header" scope="col"></th>
          </ng-container>
        </tr>
        <tr>
          <th class="py-0 header sticky-left-first bg-sticky" scope="col"></th>
          <th class="py-0 header sticky-left-second bg-sticky" scope="col"></th>
          <th class="py-0 header sticky-left-third bg-sticky" scope="col">Storico</th>
          <th class="py-0 header sticky-left-fourth bg-sticky" scope="col">Budget</th>
          <ng-container *ngFor="let fascia of fasce">
            <th class="py-0 bg-white">Sto</th>
            <th class="py-0 bg-white">Op</th>
            <th class="py-0 bg-white">Inc</th>
            <th class="py-0 bg-white">Ing</th>
            <th class="py-0 bg-white">Sc</th>
            <th class="py-0 bg-white">TDC</th>
            <th class="py-0 bg-white">BD/ING</th>
            <th class="py-0 bg-white">BR/ING</th>
            <th class="py-0 bg-white">TDC/ING</th>
            <th class="py-0 bg-white">BD/CNC</th>
            <th class="py-0 bg-white">BR/CNC</th>
            <th class="py-0 bg-white">TDC/CNC</th>
            <ng-container *ngFor="let campoExtra of campiExtra">
              <th class="py-0 bg-white">{{ campoExtra.nome }}</th>
            </ng-container>
            <th class="separatore-turno py-0"></th>
          </ng-container>
        </tr>
    </thead> 
    <tbody>
      <ng-container *ngFor="let gruppo of negoziRaggruppati; let i = index">
        <ng-container *ngFor="let negozio of gruppo.negozi let i = index;">
          <tr>
            <th *ngIf="i == 0" [attr.rowspan]="gruppo.negozi?.length" class="sticky-left-first bg-sticky">{{ gruppo.tipo }}</th>
            <th class="py-0 text-left sticky-left-second bg-sticky fix-width-150">{{ negozio.nome }}</th>
            <td class="py-0 text-right sticky-left-third bg-sticky">
              <iwr-report-rendiconti-cell-storico
                [negozi]="[negozio]"
                [storici]="storici"
              >
              </iwr-report-rendiconti-cell-storico>
            </td>
            <td class="py-0 text-right sticky-left-fourth bg-sticky">
              <iwr-report-rendiconti-cell-budget
                [negozi]="[negozio]"
                [budgets]="budgets"
              >
              </iwr-report-rendiconti-cell-budget>
            </td>
            <ng-container *ngFor="let fascia of fasce">  
              <ng-container *ngFor="let tipo of tipiDato">
                <td class="py-0">              
                  <iwr-report-rendiconti-cell
                  [rendiconti]="rendiconti"
                  [negozio]="negozio"
                  [fascia]="fascia"
                  [tipoDato]="tipo"
                  ></iwr-report-rendiconti-cell></td>
              </ng-container>
              <ng-container *ngFor="let campoExtra of campiExtra">
                <td class="py-0">
                  <iwr-report-rendiconti-cell-campo-extra
                  [rendiconti]="rendiconti"
                  [negozio]="negozio"
                  [fascia]="fascia"
                  [campoExtra]="campoExtra"
                  ></iwr-report-rendiconti-cell-campo-extra>
                </td>
              </ng-container>
              <td class="separatore-turno bg-dark"></td>
            </ng-container>
          </tr>
        </ng-container>
        <tr class="bg-warning" *ngIf="gruppo?.negozi?.length">
          <th class="bg-secondary sticky-left-first bg-warning-dark">Totale</th>
          <th class="bg-secondary d-flex justify-content-between align-items-center sticky-left-second bg-warning-dark fix-width-150">{{ gruppo.tipo }}</th>
          <td class="sticky-left-third bg-warning-dark">
            <iwr-report-rendiconti-cell-storico
            [negozi]="gruppo?.negozi"
            [storici]="storici"
          >
          </iwr-report-rendiconti-cell-storico>
          </td>
          <td class="sticky-left-fourth bg-warning-dark">
            <iwr-report-rendiconti-cell-budget
            [negozi]="gruppo?.negozi"
            [budgets]="budgets"
          >
          </iwr-report-rendiconti-cell-budget>
          </td>
          <ng-container *ngFor="let fascia of fasce">  
            <ng-container *ngFor="let tipo of tipiDato">
              <td>              
                <iwr-report-rendiconti-cell-total
                [rendiconti]="rendiconti"
                [negozi]="gruppo.negozi"
                [fascia]="fascia"
                [tipoDato]="tipo"
                ></iwr-report-rendiconti-cell-total></td>
            </ng-container>
            <ng-container *ngFor="let campoExtra of campiExtra">
              <td>
                <iwr-report-rendiconti-cell-campo-extra-total
                [rendiconti]="rendiconti"
                [negozi]="gruppo.negozi"
                [fascia]="fascia"
                [campoExtra]="campoExtra"
                ></iwr-report-rendiconti-cell-campo-extra-total>
              </td>
            </ng-container>
            <td class="separatore-turno"></td>
          </ng-container>
        </tr>
      </ng-container>
      <tr class="bg-info">
        <th class="bg-secondary sticky-left-first bg-info-dark">Totale</th>
        <th class="bg-secondary sticky-left-second bg-info-dark">Negozi</th>
        <td class="sticky-left-third bg-info-dark">
          <iwr-report-rendiconti-cell-storico
            [negozi]="negozi"
            [storici]="storici"
          >
          </iwr-report-rendiconti-cell-storico>
        </td>
        <td class="sticky-left-fourth bg-info-dark">
          <iwr-report-rendiconti-cell-budget
          [negozi]="negozi"
          [budgets]="budgets"
        >
        </iwr-report-rendiconti-cell-budget>
        </td>
        <ng-container *ngFor="let fascia of fasce">  
          <ng-container *ngFor="let tipo of tipiDato">
            <td>              
              <iwr-report-rendiconti-cell-total
              [rendiconti]="rendiconti"
              [negozi]="negozi"
              [fascia]="fascia"
              [tipoDato]="tipo"
              ></iwr-report-rendiconti-cell-total></td>
          </ng-container>
          <ng-container *ngFor="let campoExtra of campiExtra">
            <td>
              <iwr-report-rendiconti-cell-campo-extra-total
              [rendiconti]="rendiconti"
              [negozi]="negozi"
              [fascia]="fascia"
              [campoExtra]="campoExtra"
              ></iwr-report-rendiconti-cell-campo-extra-total>
            </td>
          </ng-container>
          <td class="separatore-turno"></td>
        </ng-container>
      </tr>
    </tbody> 
  </table>
</div>