<s1-header [title]="'funzioneAzioneMenu.title'">
  <ng-container right>
    <s1-button [type]="s1ButtonType.New" (onClick)="new()"></s1-button>
  </ng-container>
</s1-header>

<s1-card class="mt-2">
  <s1-table #table [onlinePagination]="true" (pageChanged)="search()">
    <thead header>
      <tr>
        <th> # </th>               
        <th>{{ 'funzioneAzioneMenu.table.columns.codice' | translate }}</th>
        <th>{{ 'funzioneAzioneMenu.table.columns.descrizione' | translate }}</th>            
        <th></th>                                     
      </tr>
    </thead>
    <tbody body>
      <tr *ngFor="let azione of table.config.rows; let i = index">
        <td>{{ i | s1TableGeneralIndex: table }}</td>
        <td> {{ azione.codice }} </td>
        <td> {{ azione.descrizione }} </td>
        <td class="text-right">
          <s1-button [type]="s1ButtonType.Edit" (onClick)="edit(azione)" class="mr-2"></s1-button>
          <s1-button [type]="s1ButtonType.Delete" (onClick)="askDelete(azione)" ></s1-button>
        </td>
      </tr>
    </tbody>
  </s1-table>
</s1-card>

<funzione-azione-menu-modal #funzioneModal (elementSaved)="search()"></funzione-azione-menu-modal>
