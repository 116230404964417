import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 's1-input-color-picker',
  templateUrl: './s1-input-color-picker.component.html',
  styleUrls: ['./s1-input-color-picker.component.scss']
})
export class S1InputColorPickerComponent {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() readonly: boolean = false;
  @Input() type: string = 'text';
  @Input() isValid: boolean = true;
  @Input() customClass: string = '';

  @ViewChild('input') input: ElementRef;

  public get color(): string {
    return this.control.value;
  }
  public set color(value: string) {
    this.control.setValue(value);
  }

  constructor() { }

  ngOnInit(): void { }

  checkIsValid(): boolean {

    return this.control.valid || !this.control.touched;

  }

  focus() {
    this.input.nativeElement.focus();
  }

}
