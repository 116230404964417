import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 's1Bytes'
})
export class S1BytesPipe implements PipeTransform {

  transform(value: number): any
  transform(value: number): string {
    const bytes = value;
    const decimals = 2;
    
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`

  }

}
