import { Injectable } from '@angular/core';
import { IS1SearchParams, S1ApiCrudService, S1HttpClientService } from '@app/s1';
import { IOperatore } from '../models/operatore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IRicercaOperatoreParams extends IS1SearchParams { 
  nome?: string,
  cognome?: string,
  codice?: string
}

enum OperatoreAPI {
  controllaCodice = "/controllaCodice"
}

@Injectable({
  providedIn: 'root'
})
export class OperatoreService extends S1ApiCrudService<IOperatore, IRicercaOperatoreParams> {

  constructor(s1HttpClient: S1HttpClientService) { 
    super(s1HttpClient, "/operatore");
  }

  controllaCodice(codice: string): Observable<IOperatore> {
    return this.s1HttpClient.get(`${this.path}${OperatoreAPI.controllaCodice}/${codice}`).pipe(
      map(response => response.item)
    )
  }

}