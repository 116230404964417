import { Component, OnInit, Input, Output, TemplateRef, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { S1AutocompleteService, IS1AutocompleteResult } from '../s1-autocomplete.service';
import { IS1StandardSearchModalConfig } from '../s1-standard-search-modal/s1-standard-search-modal.component';
import { IS1RicercaStandardResult } from '../s1-standard-search.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';

export interface IS1InputAutocompleteConfig {
  pathAutocomplete: string,
  standardSearch?: IS1StandardSearchModalConfig,
  withId?: boolean
}

@Component({
  selector: 's1-input-autocomplete',
  templateUrl: './s1-input-autocomplete.component.html',
  styleUrls: ['./s1-input-autocomplete.component.scss']
})
export class S1InputAutocomplete implements OnInit {

  @Input() config: IS1InputAutocompleteConfig;
  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() name: string = '';
  @Input() isValid: boolean = true;
  @Input() readonly: boolean = false;
  @Input() withButtonList: boolean = true;

  @Input() customTemplate: TemplateRef<string>;
  
  @Output() elementSelected = new EventEmitter<IS1AutocompleteResult>();

  @ViewChild('input') input: ElementRef;

  dataSource: Observable<any>;

  

  constructor(private utenteService: S1AutocompleteService) {
   
  }

  ngOnInit(): void {

    
    this.dataSource = new Observable(observer => { 
      this.utenteService.autocomplete(this.config.pathAutocomplete, ""+this.control?.value).subscribe(results => {

        results.forEach(r => { // compatibilità per alcune api

          if (r.codice == null) {
            r.codice = r.id;
          }
          
        });

        observer.next(results);
      })
    })
   
  }

  changeTypeaheadLoading(event) { 

  }
  
  changeTypeaheadNoResults(event) {
    this.elementSelected.emit(null);
   }
   
  typeaheadOnSelect(element: TypeaheadMatch) {

    this.elementSelected.emit(element.item)

  }

  elementSelectedOnStandardSearchModal(element: IS1RicercaStandardResult) {

    //this.control.patchValue(this.config.standardSearch.withId ? element.id : (element.codice ?? element.id))
    this.control.patchValue(element.codice ?? element.id);
    this.elementSelected.emit(element)

  }

  focus() {
    this.input.nativeElement.focus();
  }


}
