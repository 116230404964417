import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, IImpostaPassword, IPasswordDimenticataUtente } from '@app/core/services/auth.service';
import { UtenteService } from '@app/shared';

@Component({    
    templateUrl: './forgot.component.html',
    styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

    valForm: UntypedFormGroup;
    


    constructor(public settings: SettingsService, fb: UntypedFormBuilder,private router: Router, private route: ActivatedRoute, private authService: AuthService, private utenteService: UtenteService ) {

        this.valForm = fb.group({
            'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
            'login': [null, Validators.compose([Validators.required])],    
        });
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        

        if (this.valForm.valid) {
            console.log('Valid!');
            const passwordDimenticataData: IPasswordDimenticataUtente = {
                                                             login: this.valForm.value.login,                                                                                                                          
                                                             email : this.valForm.value.email
                                                            };

            this.authService.passwordDimenticata(passwordDimenticataData).subscribe(result => {

                if (result) {       
                    this.utenteService.logout()
                }      
            })
        }
    }

    ngOnInit() {
    }

}
