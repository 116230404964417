import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { IS1Sorting, S1SortService } from '@app/s1/s1-sort.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: '[s1-sortable-column]',
  templateUrl: './s1-sortable-column.component.html',
  styleUrls: ['./s1-sortable-column.component.scss']
})
export class S1SortableColumn implements OnInit, OnDestroy {

  @HostBinding('style') style: SafeStyle;

  @Input('sortable-column') columnName: string;
  @Input('sortable-ascending') isAscending: boolean = false;
  isActive: boolean = false;

  @HostListener('click')
  sort() {
    this.isAscending = ! this.isAscending;
    const newSort: IS1Sorting = {
      sortBy: this.columnName,
      isSortAscending: this.isAscending
    }
    this.s1SortService.sortByColumn(newSort);
  }

  private sortingSubscription: Subscription;

  constructor(private s1SortService: S1SortService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void { 
    this.sortingSubscription = this.s1SortService.columnSorted$.subscribe(s1Sorting => {

      if (s1Sorting.sortBy === this.columnName) {
        this.isActive = true;
        this.isAscending = s1Sorting.isSortAscending;
        this.style = this.sanitizer.bypassSecurityTrustStyle("background-color: rgba(155,155,155,0.3)");
      } else {
        this.isActive = false;
        this.style = this.sanitizer.bypassSecurityTrustStyle("background-color: none");
      }

    })
  }

  ngOnDestroy() {
    this.sortingSubscription?.unsubscribe();
  }

}
