import { Injectable } from '@angular/core';
import { ICampoExtraRendiconto } from '../models/campo-extra-rendiconto';
import { IS1SearchParams, S1ApiCrudService, S1HttpClientService } from '@app/s1';

export interface IRicercaCampoExtraRendicontoParams extends IS1SearchParams, Partial<ICampoExtraRendiconto> { 
  showAll?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class CampoExtraRendicontoService extends S1ApiCrudService<ICampoExtraRendiconto, IRicercaCampoExtraRendicontoParams> {

  constructor(s1HttpClient: S1HttpClientService) { 
    super(s1HttpClient, "/campoExtraRendiconto");
  }

}
