<div class="w-100 d-flex justify-content-center">
  <s1-input-datepicker class="mr-4" [control]="dataDaControl"></s1-input-datepicker>
  <s1-input-datepicker class="mr-4" [control]="dataAControl"></s1-input-datepicker>
  <iwr-negozi-select [control]="negozioControl" [label]="''"></iwr-negozi-select>
</div>
<div class="table-container w-100"> 
  <table class="table table-striped td-vertical-center">
    <thead>
      <tr >
        <th class="header py-0 sticky-left-first bg-sticky"></th>
        <th class="header py-0 sticky-left-second bg-sticky"></th>
        <th class="header py-0 sticky-left-third bg-sticky"></th>
        <ng-container *ngFor="let fascia of fasce">
          <th [attr.colspan]="12" class="text-center bg-secondary header py-0">{{ fascia.nome }}</th>
          <th class="separatore-turno header"></th>
        </ng-container>
      </tr>
      <tr class="header-second-line bg-white">
        <th class="py-0 sticky-left-first bg-sticky"></th>
        <th class="py-0 sticky-left-second bg-sticky">Storico</th>
        <th class="py-0 sticky-left-third bg-sticky">Budget</th>
        <ng-container *ngFor="let fascia of fasce">
          <th class="py-0 bg-white">Sto</th>
          <th class="py-0 bg-white">Op</th>
          <th class="py-0 bg-white">Inc</th>
          <th class="py-0 bg-white">Ing</th>
          <th class="py-0 bg-white">Sc</th>
          <th class="py-0 bg-white">TDC</th>
          <th class="py-0 bg-white">BD/ING</th>
          <th class="py-0 bg-white">BR/ING</th>
          <th class="py-0 bg-white">TDC/ING</th>
          <th class="py-0 bg-white">BD/CNC</th>
          <th class="py-0 bg-white">BR/CNC</th>
          <th class="py-0 bg-white">TDC/CNC</th>
          <th class="separatore-turno"></th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let gruppo of rendicontiReggruppatiGiorno let i = index;">
        <tr>
          <th class="sticky-left-first bg-sticky">{{ gruppo.giorno | s1ReadableDate }}</th>
          <td class="sticky-left-second bg-sticky">
            <iwr-report-rendiconti-cell-storico
            [negozi]="[negozio]"
            [storici]="storici"
            [data]="gruppo.giorno"
          >
          </iwr-report-rendiconti-cell-storico>
          </td>
          <td class="sticky-left-third bg-sticky">
            <iwr-report-rendiconti-cell-budget
              [negozi]="[negozio]"
              [budgets]="budgets"
              [data]="gruppo.giorno"
            >
            </iwr-report-rendiconti-cell-budget>
          </td>
          <ng-container *ngFor="let fascia of fasce">  
            <ng-container *ngFor="let tipo of tipiDato">
              <td>              
                <iwr-report-rendiconti-cell
                [rendiconti]="gruppo.rendiconti"
                [negozio]="negozio"
                [fascia]="fascia"
                [tipoDato]="tipo"
                ></iwr-report-rendiconti-cell></td>
            </ng-container>
            <td class="separatore-turno bg-dark"></td>
          </ng-container>
        </tr>
      </ng-container>
      <tr class="bg-info">
        <th class="bg-info sticky-left-first">Totale</th>
        <td class="bg-info-dark sticky-left-second">
          <iwr-report-rendiconti-cell-storico
            [negozi]="[negozio]"
            [storici]="storici"
          >
          </iwr-report-rendiconti-cell-storico>
          </td>
        <td class="bg-info-dark sticky-left-third">
          <iwr-report-rendiconti-cell-budget
          [negozi]="[negozio]"
          [budgets]="budgets"
        >
        </iwr-report-rendiconti-cell-budget>
        </td>
        <ng-container *ngFor="let fascia of fasce">  
          <ng-container *ngFor="let tipo of tipiDato">
            <td>              
              <iwr-report-rendiconti-cell-total
              [rendiconti]="rendiconti"
              [negozi]="[negozio]"
              [fascia]="fascia"
              [tipoDato]="tipo"
              ></iwr-report-rendiconti-cell-total></td>
          </ng-container>
          <td class="separatore-turno"></td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>