import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1SearchParams } from '@app/s1';
import { INegozio } from '@app/shared/models/negozio';
import { IRendiconto } from '@app/shared/models/rendiconto';
import { ITurno } from '@app/shared/models/turno';
import { RendicontoService, IRicercaRendicontoParams, IRicercaReportRendicontoParams, TipoCalcoloReportRendiconto, TipoRaggruppamentoReportRendiconto } from '@app/shared/services/rendiconto.service';
import { TurnoService } from '@app/shared/services/turno.service';
import { Subscription, forkJoin } from 'rxjs';
import { OperatoreService } from '@app/shared/services/operatore.service';
import { IOperatore } from '@app/shared/models/operatore';
import { TipoDatoCellaReportConversione } from '../report-tasso-conversione-cell/report-tasso-conversione-cell.component';
import { UtilityService } from '@app/shared/services/utility.service';

@Component({
  selector: 'iwr-report-tasso-conversione-section',
  templateUrl: './report-tasso-conversione-section.component.html',
  styleUrls: ['./report-tasso-conversione-section.component.scss']
})
export class ReportTassoConversioneSectionComponent {

  dataDaControl: UntypedFormControl;
  dataAControl: UntypedFormControl;
  negozioControl: UntypedFormControl;
  weekendControl: UntypedFormControl;

  //dataDa: Date = new Date();
  //dataA: Date = new Date();
  operatori: IOperatore[] = [];
  turni: ITurno[] = [];
  tipiDato: TipoDatoCellaReportConversione[] = [];
  rendiconti: IRendiconto[] = [];
  negozioSelected: Partial<INegozio>;

  dataDaSub: Subscription;
  dataASub: Subscription;
  weekendSub: Subscription;
  negozioSub: Subscription;

  constructor(private operatoreService: OperatoreService, private turnoService: TurnoService, private rendicontoService: RendicontoService, private utilityService: UtilityService) {
    this.dataDaControl = new UntypedFormControl(new Date());
    this.dataAControl = new UntypedFormControl(new Date());
    this.weekendControl = new UntypedFormControl(false);
    this.negozioControl = new UntypedFormControl();
    this.tipiDato =  Object.keys(TipoDatoCellaReportConversione).map(tipo => TipoDatoCellaReportConversione[tipo]);
    this.load();

    this.dataDaSub = this.dataDaControl.valueChanges.subscribe(changed => {
      this.load();
    });

    this.dataASub = this.dataAControl.valueChanges.subscribe(changed => {
      this.load();
    });

    this.negozioSub = this.negozioControl.valueChanges.subscribe(changed => {
      this.negozioSelected = { id: changed };
      this.load();
    });

    this.weekendSub = this.weekendControl.valueChanges.subscribe(changed => {
      this.load();
    });

  }

  load() {

    const searchParams: IS1SearchParams = {
      page: 1,
      pageSize: 999,
      paginable: false
    }

    const dataDa: Date = this.dataDaControl.value ? this.utilityService.UTCDateFromLocal(this.dataDaControl.value) : null;
    const dataA: Date = this.dataAControl.value ? this.utilityService.UTCDateFromLocal(this.dataAControl.value) : null;

    if (dataDa == null || dataA == null) {
      return;
    }

    //const negozio: Partial<INegozio> = { id: this.negozioControl.value };

    const rendicontoSearchParams: IRicercaRendicontoParams = {
      page: 1,
      pageSize: 999,
      paginable: false,
      mostraStoricoFasceSenzaRendiconto: true
      //giorno: data,
      //negozio: negozio
    }

    const searchParamsReport: IRicercaReportRendicontoParams = {
      page: 1,
      pageSize: 999,
      paginable: false,
      daGiorno: dataDa,
      aGiorno: dataA,
      escludiWeekend: !this.weekendControl.value,
      calcolaPer: TipoCalcoloReportRendiconto.TURNO,
      raggruppaPer: TipoRaggruppamentoReportRendiconto.OPERATORE,
      mostraStoricoFasceSenzaRendiconto: false
    }

    forkJoin([
      this.operatoreService.ricerca(searchParams),
      this.turnoService.ricerca(searchParams),
      this.rendicontoService.ricercaReport(searchParamsReport)
    ]).subscribe(([operatori, turni, rendiconti]) => {

      this.operatori = operatori?.elements ?? [];
      this.turni = turni?.elements ?? [];
      this.rendiconti = rendiconti?.elements ?? [];

      // Object.keys(TipoNegozio).forEach(tipo => {

      //   console.log("TIPO: ", tipo);
      //   console.log("TIPO N: ", TipoNegozio[tipo]);

      //   const gruppo: INegoziRaggruppati = {
      //     tipo: TipoNegozio[tipo],
      //     negozi: negozi.elements.filter(negozio => negozio.tipo == TipoNegozio[tipo])
      //   }

      //   this.negoziRaggruppati.push(gruppo);
      // })
      
    },
      (err) => { console.log("ERR fork: ", err)}
    );
  }

}
