import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

export interface IS1Sorting {
  sortBy: string,
  isSortAscending: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class S1SortService {

  constructor() { }

  private columnSortedSource = new Subject<IS1Sorting>();

  columnSorted$ = this.columnSortedSource.asObservable();

  sortByColumn(event: IS1Sorting) {
    this.columnSortedSource.next(event);
  }

}
