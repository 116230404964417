import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { TurnoService, IRicercaTurnoParams } from '@app/shared/services/turno.service';

@Component({
  selector: 'iwr-turno-select',
  templateUrl: './turno-select.component.html',
  styleUrls: ['./turno-select.component.scss']
})
export class TurnoSelectComponent {

  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() readonly: boolean;

  turni: IS1InputSelectItem[];

  constructor(private turnoService: TurnoService) {

    const params: IRicercaTurnoParams = {
      page: 1,
      pageSize: 999,
      paginable: false
    }
  
    this.turnoService.ricerca(params).subscribe(turniPaginati => {

      this.turni = turniPaginati?.elements?.map(turno => { 
        return { code: turno.id, label: turno.nome?.trim() }  
      }) ?? [];
  
      if (this.turni?.length == 1) {
        this.control?.patchValue(this.turni[0].code);
      }
  
    })

  }

}
