import { Component, Input } from '@angular/core';
import { INegozio } from '@app/shared/models/negozio';
import { IRendiconto } from '@app/shared/models/rendiconto';
import { ITurno } from '@app/shared/models/turno';
import { TipoDatoCellaReportRendiconto } from '../report-rendiconti-cell/report-rendiconti-cell.component';
import { IFasciaOraria } from '@app/shared/models/fascia-oraria';

@Component({
  selector: 'iwr-report-rendiconti-cell-total',
  templateUrl: './report-rendiconti-cell-total.component.html',
  styleUrls: ['./report-rendiconti-cell-total.component.scss']
})
export class ReportRendicontiCellTotalComponent {

  @Input() set rendiconti(newValue: IRendiconto[]) {
    this._rendiconti = newValue;
    this.mapValue();
  };

  @Input() set negozi(newValue: INegozio[]) {
    this._negozi = newValue;
    this.mapValue();
  }

  @Input() set fascia(newValue: IFasciaOraria) {
    this._fascia = newValue;
    this.mapValue();
  }

  @Input() set tipoDato(newValue: TipoDatoCellaReportRendiconto) {
    this._tipoDato = newValue;
    this.mapValue();
  }

  _rendiconti: IRendiconto[] = [];
  _negozi: INegozio[] = [];
  _fascia: IFasciaOraria;
  _tipoDato: TipoDatoCellaReportRendiconto;

  valore: any = "-";
  tipoDatoCellaReportRendiconto = TipoDatoCellaReportRendiconto;

  mapValue() {

    if (!(this._rendiconti && this._fascia && this._tipoDato)) {
      return;
    }

    const rendicontiFilteredStorico = this._rendiconti.filter(rendiconto => rendiconto.fasciaOraria?.id == this._fascia?.id && this._negozi.map(negozio => negozio.id).includes(rendiconto.negozio?.id));

    const rendicontiFiltered = this._rendiconti.filter(rendiconto => rendiconto.incasso != null && rendiconto.fasciaOraria?.id == this._fascia?.id && this._negozi.map(negozio => negozio.id).includes(rendiconto.negozio?.id));

    if (this._tipoDato == TipoDatoCellaReportRendiconto.storico && rendicontiFilteredStorico.length) {
      this.valore = rendicontiFilteredStorico.map(rendiconto => rendiconto.storico?.incasso ?? 0).reduce((sum, val) => sum + Number(val));
    } else if (rendicontiFiltered.length) {

      switch (this._tipoDato) {
        // case TipoDatoCellaReportRendiconto.storico:
        //   this.valore = rendicontiFiltered.map(rendiconto => rendiconto.storico?.incasso ?? 0).reduce((sum, val) => sum + Number(val), 0);
        //   break;
        case TipoDatoCellaReportRendiconto.incasso:
          this.valore = rendicontiFiltered.map(rendiconto => rendiconto.incasso ?? 0).reduce((sum, val) => sum + Number(val));
          break;
        case TipoDatoCellaReportRendiconto.ingressi:
          this.valore = rendicontiFiltered.map(rendiconto => rendiconto.ingressi ?? 0).reduce((sum, val) => sum + Number(val));
          break;
        case TipoDatoCellaReportRendiconto.scontrini:
          this.valore = rendicontiFiltered.map(rendiconto => rendiconto.numeroScontrini ?? 0).reduce((sum, val) => sum + Number(val));
          break;
        case TipoDatoCellaReportRendiconto.tassoDiConversione:
          this.valore = rendicontiFiltered.map(rendiconto => rendiconto.tassoDiConversione ?? 0).reduce((sum, val) => sum + Number(val), 0) / rendicontiFiltered.length;
          break;
        case TipoDatoCellaReportRendiconto.voucherDistribuiti10:
          this.valore = rendicontiFiltered.map(rendiconto => rendiconto.numeroBanconoteIngaggio10 ?? 0).reduce((sum, val) => sum + Number(val));
          break;
        case TipoDatoCellaReportRendiconto.voucherRicevuti10:
          this.valore = rendicontiFiltered.map(rendiconto => rendiconto.numeroBanconoteRitirate10 ?? 0).reduce((sum, val) => sum + Number(val));
          break;
        case TipoDatoCellaReportRendiconto.tassoVoucher10:
          this.valore = rendicontiFiltered.map(rendiconto => rendiconto.tassoDiConversioneVoucher10 ?? 0).reduce((sum, val) => sum + Number(val), 0) / rendicontiFiltered.length;
          break;
        case TipoDatoCellaReportRendiconto.voucherDistribuiti15:
          this.valore = rendicontiFiltered.map(rendiconto => rendiconto.numeroBanconoteIngaggio15 ?? 0).reduce((sum, val) => sum + Number(val));
          break;
        case TipoDatoCellaReportRendiconto.voucherRicevuti15:
          this.valore = rendicontiFiltered.map(rendiconto => rendiconto.numeroBanconoteRitirate15 ?? 0).reduce((sum, val) => sum + Number(val));
          break;
        case TipoDatoCellaReportRendiconto.tassoVoucher15:
          this.valore = rendicontiFiltered.map(rendiconto => rendiconto.tassoDiConversioneVoucher15 ?? 0).reduce((sum, val) => sum + Number(val), 0) / rendicontiFiltered.length;
          break;
        case TipoDatoCellaReportRendiconto.operatore:
          this.valore = "";
          break
      }

    }

  }
}
