import { Component, ViewEncapsulation, Output, EventEmitter, ViewChild, TemplateRef, Input } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

export interface IS1Modal {
  modal: S1Modal;
  close()
}

export enum S1ModalSizes {
  SM = 'modal-sm',
  MD = '',
  LG = 'modal-lg',
  XL = 'modal-xl'
}

@Component({
  selector: 's1-modal',
  templateUrl: './s1-modal.component.html',
  styleUrls: ['./s1-modal.component.scss']
})
export class S1Modal {

  @Input() title: string;
  @Output() onClose = new EventEmitter();

  @ViewChild('modalTemplate') public modalTemplate: TemplateRef<any>;
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) {}

  open(size: S1ModalSizes = S1ModalSizes.MD) {

    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: size
    };

    this.modalRef = this.modalService.show(this.modalTemplate, config);
  }

  close() {
    this.modalRef?.hide();
    this.onClose.emit();
  }

}