import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TreeNode } from '@ali-hm/angular-tree-component';
import { Guid } from "guid-typescript";

import { AzioneMenuAPI, IAzioneMenu } from '@app/shared/services/azione-menu.service';
import { S1IconModal } from '../s1-icon-modal/s1-icon-modal.component';
import { S1PolicyModal } from '../s1-policy-modal/s1-policy-modal.component';
import { IS1StandardSearchModalConfig, S1StandardSearchModal } from '../s1-standard-search-modal/s1-standard-search-modal.component';

@Component({
  selector: 's1-tree-editor',
  templateUrl: './s1-tree-editor.component.html',
  styleUrls: ['./s1-tree-editor.component.scss']
})
export class S1TreeEditor implements OnInit {

  @Input() nodes: any[];
  @Output() nodesChange = new EventEmitter(); //two-way data binding
  @Input() readOnly: boolean = false
  

  @ViewChild('searchActionModal') public searchActionModal: S1StandardSearchModal;
  @ViewChild('iconModal') public iconModal: S1IconModal;
  @ViewChild('policyModal') public policyModal: S1PolicyModal;

  nodeSelected: any;

  customTemplateStringOptions = {
      isExpandedField: 'expanded',
      idField: 'id',
      allowDrag: true
  };

  constructor() { }

  ngOnInit(): void { }

  newNode() {

    return {
        id: Guid.create().toString(),
        text: 'Nuovo nodo',
        icon: "fa fa-circle",
        children: []
    }

  }

  addFirstLevelNode(tree) {

    this.nodes.push(this.newNode())
    tree.treeModel.update();

  }

  addNode(tree, node: TreeNode) {

    node.data.children.push(this.newNode());
    tree.treeModel.update();

  }

  removeNode(tree, node: TreeNode) { 

    node.parent.data.children = node.parent.data.children.filter( child => { 
        return child.id != node.data.id
    });

    if (node.isRoot) { // Se first level node cambio direttamente datasource nodes
        this.nodes = node.parent.data.children
        this.nodesChange.emit(this.nodes)
    }

    tree.treeModel.update();

  }

  addAction(tree, node: TreeNode) {

    this.nodeSelected = node.data;

    const config: IS1StandardSearchModalConfig = {
      title: "menuAzioni",
      path: AzioneMenuAPI.ricerca
    }

    this.searchActionModal.open(config);

  }

  removeAction(tree, node: TreeNode) {

    let nodeData = node.data

    nodeData["idAzione"] = null
    nodeData["codiceAzione"] = null
    nodeData["descrizioneAzione"] = null
    
  }

  hasChildren(node: TreeNode): boolean {

    return node && node.children ? (node.children.length > 0) : false 

  }

  hasAction(node: TreeNode): boolean {

    return node && node.data.idAzione

  }

  showAddAction(node: TreeNode): boolean {

    return !this.hasChildren(node) && !this.hasAction(node)

  }

  showRemoveAction(node: TreeNode): boolean {

    return !this.hasChildren(node) && this.hasAction(node)

  }

  checkLink(node: TreeNode) {
    //TODO fix con path da nodo
    node.data.path = "home"
    let url = location.origin + "/" + node.data.path;
    window.open(url, "_blank");
  }

  actionSelectedOnSearchModal(azione: IAzioneMenu) {

    this.nodeSelected.idAzione = azione.id;
    this.nodeSelected.codiceAzione = azione.codice;
    this.nodeSelected.descrizioneAzione = azione.descrizione;
    this.nodeSelected.funzioni = azione.funzioni;
    this.nodeSelected.link = azione.url;
    this.nodeSelected.icon = azione.icona ? azione.icona : this.nodeSelected.icon;
    this.searchActionModal.close()

    this.nodeSelected = null;

  }

  setIcon(node: TreeNode) {

    this.nodeSelected = node.data

    this.iconModal.load()

  }

  iconSelected(icon) {

    this.nodeSelected.icon = icon;
    this.iconModal.close()

  }

  showPolicy(node: TreeNode): boolean {

    return node && node.data.funzioni && node.data.funzioni.length > 0

  }

  managePolicy(tree, node: TreeNode) {

    if (node.data.funzioni && node.data.funzioni.length > 0) {
        this.policyModal.load(node.data.funzioni)
    }

  }

}
