import { Injectable } from "@angular/core";
import { LoggingService } from "@app/core/services/log.service";
import { TranslateService } from "@ngx-translate/core";
import { S1LocalStorageService } from "./s1-local-storage-service";




@Injectable({
  providedIn: 'root'
})
export class S1PolicyService {

  private policiesStructure: any[]
  

  constructor(private translate: TranslateService,  private logger: LoggingService) {

    

  }

 
  isDisabled(type: string): boolean { 
    return !this.isEnabled(type)
  }

  isEnabled(type: string): boolean {
    this.policiesStructure = this.getPoliciesStructure()

    let item = this.policiesStructure.find(x=>x===type);
    if(item) 
        return true;
    else
        return false;
 
  }

  private getPoliciesStructure() {    
    return JSON.parse(localStorage.getItem('abilitazioni'))
  }



}
