import { Injectable } from '@angular/core';
import { IS1InputAutocompleteConfig, IS1StandardSearchModalConfig } from '@app/s1';


export enum EntitaAPI {
  ricerca = "/Entita/Ricerca",
  completamentoAutomatico = "/Entita/CompletamentoAutomatico"
}

interface IAutocompleteTypes {
  entita: IS1InputAutocompleteConfig
}

interface IStandardSearchTypes {
  entita: IS1StandardSearchModalConfig
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  standardSearchTypes: IStandardSearchTypes;
  autocompleteTypes: IAutocompleteTypes;

  constructor() {

    this.standardSearchTypes = {
      entita: { path: EntitaAPI.ricerca, title: 'standardSearch.title.entita' }
    }

    this.autocompleteTypes = {
      entita: { pathAutocomplete: EntitaAPI.completamentoAutomatico, standardSearch: this.standardSearchTypes.entita }
    }

  }

}