import { IS1BasicEntity } from "./entita-base";

export interface INegozio extends IS1BasicEntity {
  indirizzo: string,
  nome: string,
  tipo: TipoNegozio
}

export enum TipoNegozio {
  DIRETTI = "DIRETTI",
  AFFILIATI = "AFFILIATI",
  OUTLET = "OUTLET",
  DIRETTI_SENZA_STORICO = "DIRETTI SENZA STORICO",
  AFFILIATI_SENZA_STORICO = "AFFILIATI SENZA STORICO"
}

export interface INegoziRaggruppati { 
  tipo: TipoNegozio,
  negozi: INegozio[],
  expand?: boolean
}