<s1-modal #modal [title]="'funzioneAzioneMenu.title'">
  <form [formGroup]="funzioneForm" >
    <div class="row">
      <s1-input-text class="col-6" [control]="funzioneForm.controls.codice" [label]="'funzioneAzioneMenu.fields.codice'"></s1-input-text>
      <s1-input-text class="col-6" [control]="funzioneForm.controls.descrizione" [label]="'funzioneAzioneMenu.fields.descrizione'"></s1-input-text>
    </div>
    <div class="row justify-content-center">
      <s1-button [type]="s1ButtonType.Save" (onClick)="save()"></s1-button>
    </div>
  </form>
</s1-modal>